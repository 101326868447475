import React, {ChangeEvent, useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faMapSigns, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button, Form, FormCheck, ListGroup, Tab, Tabs} from "react-bootstrap";
import {Navigation} from "react-minimal-side-navigation";
import MapComponent from "../components/MapComponent";

type StationsDetailsProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const StationsDetails = (props:StationsDetailsProps) => {

  const {t} = useTranslation();
  const initRows: SideMenu[] = [];
  const initVehicleRows: DocumentData[] = [];
  const init : DocumentData  = {};
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(props.match.params.id);
  const [rows, setRows] = useState(initRows);
  const [vehicleRows, setVehicleRows] = useState(initVehicleRows);
  const [stationId, setStationId] = useState("");
  const [stationData, setStationData] = useState(init);
  const [key, setKey] = useState("vehicles");
  const history = useHistory();

  const loadStations = async () => {
    const db = getFirestore();
    const id = props.match.params.id;
    setRows(initRows)

    const queryStations = await query(collection(db, "stations"),
        where("projectId", "==", id), where("active", "==", true),
        orderBy("name", "asc"), limit(500));

    const querySnapshot = await getDocs(queryStations);

    for (const station of querySnapshot.docs) {
      const stationData = station.data();
      rows.push(
          {
            title: stationData.name,
            itemId: station.id,
            elemBefore: () => <div><FontAwesomeIcon icon={faMapSigns}/></div>,
          })
    }

    return 0;
  }

  const loadStationDetails = async (stationId : string) => {

    const db = getFirestore();
    const id = props.match.params.id;

    const station = await getDoc(doc(db, "stations", stationId));
    const stationData = station.data();
    if (stationData) {
      setStationData(stationData);

      const lockMap = new Map();

      const queryLocks = await query(collection(db, "locks"),
        where("projectId", "==", id),
        where("active", "==", true));

      const snapshot = await getDocs(queryLocks);
      for (const lock of snapshot.docs) {
        lockMap.set(lock.data().loraId, {'id': lock.id, ...lock.data()});
      }

      const queryVehicles = await query(collection(db, "vehicles"),
        where("projectId", "==", id),
        where("active", "==", true),
        where("station", "==", stationData.name));

      const vehicleSnapshot = await getDocs(queryVehicles);
      const localRows: DocumentData[] = [];


      for (const vehicle of vehicleSnapshot.docs) {
        if (lockMap.get(vehicle.data().lockId)) {
          localRows.push({'id': vehicle.id, lock: lockMap.get(vehicle.data().lockId).id, ...vehicle.data()})
        } else {
          localRows.push({'id': vehicle.id, lock: "", ...vehicle.data()})
        }
      }

      localRows.sort((a,b) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);
      let i = 0;
      for (const row of localRows) {
        i++;
        row.count = i;
      }

      setVehicleRows(localRows);
    }

    return 0;
  }

  const switchAvailability= (vehicleId:string, isAvailable:boolean) => {
    const db = getFirestore()
    const ref = doc(db, 'vehicles', vehicleId);
    setLoading(true);
    setDoc(ref, {available: !isAvailable}, {merge: true})
      .then(() => {
        loadStationDetails(stationId)
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  const onChangeBlockedReason = (event:ChangeEvent<HTMLSelectElement>, vehicleId: string) => {
    const db = getFirestore();
    const ref = doc(db, 'vehicles', vehicleId);
    const reason = event.target.value;
    let blockedUntil;
    setLoading(true);
    switch (reason) {
      case 'battery':
        blockedUntil = Date.now() + 3 * 24 * 60 * 60 * 1000;
        break;
      case 'maintenance':
        blockedUntil = Date.now() + 7 * 24 * 60 * 60 * 1000;
        break;
      default:
        blockedUntil = null;
    }
    setDoc(ref, {blockedReason: reason, blockedUntil: blockedUntil}, {merge: true})
      .then(() => {
        loadStationDetails(stationId)
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  const tableHead = <tr>
    <th>{"#"}</th>
    <th>{t("name")}</th>
    <th>{t("type")}</th>
    <th>{t("lock")}</th>
    <th>{t("station")}</th>
    <th>{t("available")}</th>
    <th>{t("reason")}</th>
    <th>{t("blocked-until")}</th>
  </tr>

  const tableBody = vehicleRows.map((value: DocumentData) => {
    const available =
      <div className="switch-button-item">
        <FormCheck type={"switch"} checked={value.available} onChange={() => switchAvailability(value.id, value.available)}/>
        {(value.available) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
      </div>
    const lockUrl = '/projects/'+props.match.params.id+'/locks/details?id='+value.lock
    const lockId = <Button variant={"link"} size={"sm"} onClick={() => history.push(lockUrl)}>{value.lockId}</Button>;
    const vehicleUrl = '/projects/'+props.match.params.id+'/vehicles/details?id='+value.id;
    const vehicleName = <Button variant={"link"} size={"sm"} onClick={() => {history.push(vehicleUrl)}}>{value.name}</Button>;
    const station = (value.station == 'on-the-road') ? <i>{t('on-the-road')}</i> : <b>{value.station}</b>;
    const blockedUntil = (value.blockedUntil) ? new Date(value.blockedUntil).toLocaleDateString() : "";

    return (
      <tr key={value.id} style={{alignItems:'center'}}>
        <td>{value.count}</td>
        <td>{vehicleName}</td>
        <td>{t(value.type)}</td>
        <td>{lockId}</td>
        <td>{station}</td>
        <td>{available}</td>
        <td>
          {!value.available &&
          <Form.Select size={"sm"} aria-label="reason" defaultValue={value.blockedReason}
                       onChange={(event) => onChangeBlockedReason(event, value.id)}>
            <option value="">{""}</option>
            <option value="broken">{t('broken')}</option>
            <option value="battery">{t('battery-3days')}</option>
            <option value="maintenance">{t('maintenance')}</option>
          </Form.Select>
          }
        </td>
        <td>{blockedUntil}</td>
      </tr>
    )
  });


  useEffect(() => {
    setLoading(true);
    const searchParam = "?id=";
    const str = props.location.search.toString();
    const stationId = str.substring(searchParam.length);

    loadStations()
        .then(() => {
          if (stationId != "") {
            loadStationDetails(stationId)
                .finally(() => {
                  setStationId(stationId)
                  setLoading(false);
                })
          } else {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        })
  }, [])

  return (
      <div className="Dashboard">
        <LoadingElement loading={loading}/>
        <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                            projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
        <div className="App-header">
          <div className="App-side-menu">
            <div className="App-side-menu-title">
              {t("stations")}
            </div>
            <div className="App-side-menu-body">
              <React.Fragment>
                <Navigation
                    // you can use your own router's api to get pathname
                    activeItemId={props.location.search.substr(4)}
                    onSelect={({itemId}) => {
                      if (projectId) {
                        setLoading(true)
                        setStationId(itemId)
                        const url = '/projects/' + projectId + '/stations/details?id=' + itemId;
                        history.push(url)
                        loadStationDetails(itemId)
                            .finally(() => {
                              setLoading(false);
                            })
                      }
                    }}
                    items={rows}
                />
              </React.Fragment>
            </div>
          </div>
          {stationId != "" && <div className="App-box-small">
            <div className="App-box-upper">
              <div className="App-list-group">
                <ListGroup>
                  <ListGroup.Item><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('name')}{": "}</div>
                    <div className="App-list-group-item-2">{stationData.name}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('nVehiclesTarget')}{": "}</div>
                    <div className="App-list-group-item-2">{stationData.nVehiclesTarget}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('nVehiclesCurrent')}{": "}</div>
                    <div className="App-list-group-item-2">{stationData.nVehiclesCurrent}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('geofencing')}{": "}</div>
                    <div className="App-list-group-item-2">{
                      stationData.geofencing ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> :
                      <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>
                    }</div></div></ListGroup.Item>
                </ListGroup>
              </div>
              <div className="App-map">
                <MapComponent markers={[{id: 1, type:'vehicle', position:{lat:stationData.position?.latitude, lng:stationData.position?.longitude}}]}/>
              </div>
            </div>
            <Tabs defaultActiveKey="current" id="uncontrolled-tab-example" className="mb-3" transition={true}
                  style={{marginLeft: '40px', marginTop: '10px'}}
                  activeKey={key}
                  onSelect={(k) => {return k ? setKey(k.toString()) : setKey("")}}>
              <Tab eventKey="vehicles" title={t('vehicles')}>
                <div className="App-table" style={{height: '40vh'}}>
                  <div className="table-responsive" style={{height: '40vh'}}>
                    <table className="table" id="project-table">
                      <thead>{tableHead}</thead>
                      <tbody>{tableBody}</tbody>
                    </table>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>}
          {stationId == "" && <div className="App-box-small-empty">
          </div>}
        </div>
      </div>
  )

}

export default StationsDetails;