import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import * as _ from "lodash";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const login = {
  en: {
    translation: {
      "login": "Login",
      "wrong-password-title": "Wrong password",
      "wrong-password-body": "You have entered the wrong password.",
      "no-account-title": "No Account",
      "no-account-body": "There is no account registered with this email address.",
      "login-title": "Mobility Platform",
      "email": "Email",
      "password": "Password",
      "forgot-password": "Forgot your password ?",
      "invalid-info": "The information you have entered are not valid.",
      "deactivated-account": "Your account has been deactivated.",
      "confirm-email-message": "Thank you for confirming your email address."
    }
  },
  de: {
    translation: {
      "login": "Anmelden",
      "wrong-password-title": "Falsches Passwort",
      "wrong-password-body": "Sie haben das falsche Passwort eingegeben.",
      "no-account-title": "Kein Konto",
      "no-account-body": "Es ist kein Konto mit dieser E-Mail-Adresse registriert.",
      "login-title": "Plattform für Mobilität",
      "email": "E-Mail",
      "password": "Passwort",
      "forgot-password": "Haben Sie Ihr Passwort vergessen ?",
      "invalid-info": "Die von Ihnen eingegebenen Informationen sind nicht gültig.",
      "deactivated-account": "Ihr Konto wurde deaktiviert.",
      "confirm-email-message": "Vielen Dank, dass Sie Ihre E-Mail-Adresse bestätigt haben."
    }
  },
  fr: {
    translation: {
      "login": "Se connecter",
      "wrong-password-title": "Mot de passe incorrect",
      "wrong-password-body": "Vous avez saisi un mauvais mot de passe.",
      "no-account-title": "Aucun compte",
      "no-account-body": "Il n'y a pas de compte enregistré avec cette adresse e-mail.",
      "login-title": "Plateforme de mobilité",
      "email": "Email",
      "password": "Mot de passe",
      "forgot-password": "Vous avez oublié votre mot de passe ?",
      "invalid-info": "Les informations que vous avez saisies ne sont pas valides.",
      "deactivated-account": "Votre compte a été désactivé.",
      "confirm-email-message": "Merci d'avoir confirmé votre adresse électronique."
    }
  }
};

const navbar = {
  en: {
    translation: {
      "title": "Mobility Platform",
      "overview": "Overview",
      "projects": "Projects",
      "create-project": "Create Project",
      "operators": "Operators",
      "create-operator": "Create Operator",
      "profile": "Profile",
      "settings": "Settings",
      "logout": "Logout",
      "create-project-title": "Creating a new project",
      "create-project-body": "Please enter the name of the new project below:",
      "create-operator-title": "Creating a new operator",
      "create-operator-body": "Please enter the name of the new operator below:",
      "super-admin-menu": "Admin",
      "user-profile-title": "User Profile",
      "user-profile-body": "Amend the information below:",
      "language": "Language",
      "german": "German",
      "english": "English",
      "french": "French"
    }
  },
  de: {
    translation: {
      "title": "Plattform für Mobilität",
      "overview": "Überblick",
      "projects": "Projekte",
      "create-project": "Projekt erstellen",
      "operators": "Betreiber",
      "create-operator": "Betreiber erstellen",
      "profile": "Profil",
      "settings": "Einstellungen",
      "logout": "Abmelden",
      "create-project-title": "Ein neues Projekt erstellen",
      "create-project-body": "Bitte geben Sie unten den Namen des neuen Projekts ein:",
      "create-operator-title": "Erstellen eines neuen Betreibers",
      "create-operator-body": "Bitte geben Sie unten den Namen des neuen Betreibers ein:",
      "super-admin-menu": "Admin",
      "user-profile-title": "Benutzerprofil",
      "user-profile-body": "Ändern Sie die nachstehenden Informationen:",
      "language": "Sprache",
      "german": "Deutsch",
      "english": "Englisch",
      "french": "Französisch"
    }
  },
  fr: {
    translation: {
      "title": "Plateforme de mobilité",
      "overview": "Aperçu",
      "projects": "Projets",
      "create-project": "Créer un projet",
      "operators": "Operateurs",
      "create-operator": "Créer un opérateur",
      "profile": "Profil",
      "settings": "Paramètres",
      "logout": "Déconnexion",
      "create-project-title": "Créer un nouveau projet",
      "create-project-body": "Veuillez saisir le nom du nouveau projet ci-dessous:",
      "create-operator-title": "Créer un nouvel opérateur",
      "create-operator-body": "Veuillez saisir le nom du nouvel opérateur ci-dessous:",
      "super-admin-menu": "Admin",
      "user-profile-title": "Profil utilisateur",
      "user-profile-body": "Modifiez les informations ci-dessous :",
      "language": "Langue",
      "german": "Allemand",
      "english": "Anglais",
      "french": "Français"
    }
  }
};

const register = {
  en: {
    translation: {
      "register": "Register",
      "invited-by": "You have been invited by ",
      "to-join": " to join the team ",
      "enter-info": ". Please create an account by entering the below information.",
      "first-name": "First Name",
      "last-name": "Last Name",
      "save-changes": "Save",
      "account-already-exists": "Account exists already",
      "account-already-exists-text": "An account with this email has already been created. " +
          "Would you like to go to the login page?",
      "deactivated-invitation": "Your invitation has been deactivated."
    }
  },
  de: {
    translation: {
      "register": "Registrieren",
      "invited-by": "Sie wurden von ",
      "to-join": " eingeladen, dem Team ",
      "enter-info": " beizutreten. Bitte erstellen Sie ein Konto, indem Sie die untenstehenden Informationen eingeben.",
      "first-name": "Vorname",
      "last-name": "Nachname",
      "save-changes": "Speichern",
      "account-already-exists": "Konto existiert bereits",
      "account-already-exists-text": "Es wurde bereits ein Konto mit dieser E-Mail-Adresse erstellt. " +
          "Möchten Sie zur Anmeldeseite gehen?",
      "deactivated-invitation": "Ihre Einladung wurde deaktiviert."
    }
  },
  fr: {
    translation: {
      "register": "S'inscrire",
      "invited-by": "Vous avez été invité par ",
      "to-join": " à rejoindre l'équipe ",
      "enter-info": ". Veuillez créer un compte en saisissant les informations ci-dessous.",
      "first-name": "Prénom",
      "last-name": "Nom",
      "save-changes": "Sauvegarder",
      "account-already-exists": "Un compte existe déjà",
      "account-already-exists-text": "Un compte avec cette email a déjà été créé. " +
          "Voulez-vous aller à la page de connexion ?",
      "deactivated-invitation": "Votre invitation a été désactivée."
    }
  },
}

const resetPassword = {
  en: {
    translation: {
      "reset-password-title": "Reset your password",
      "account-not-exist-title": "Account does not exist",
      "account-not-exist-body": "No account related to this email has been found in the user management system.",
      "email-sent-title": "Verification email sent!",
      "email-sent-body": "A verification email has been sent to your email account. " +
          "Please follow the instruction in the email. Would you like to go to the login page ?",
      "send-email": "Send verification email"
    }
  },
  de: {
    translation: {
      "reset-password-title": "Ihr Passwort zurücksetzen",
      "account-not-exist-title": "Das Konto existiert nicht",
      "account-not-exist-body": "Im Benutzerverwaltungssystem wurde kein Konto zu dieser E-Mail gefunden.",
      "email-sent-title": "Verifizierungs-E-Mail gesendet!",
      "email-sent-body": "Eine Bestätigungs-E-Mail wurde an Ihr E-Mail-Konto gesendet. " +
          "Bitte folgen Sie den Anweisungen in der E-Mail. Möchten Sie zur Anmeldeseite gehen ?",
      "send-email": "Bestätigungs-E-Mail senden"
    }
  },
  fr: {
    translation:{
      "reset-password-title": "Réinitialiser votre mot de passe",
      "account-not-exist-title": "Ce compte n'existe pas",
      "account-not-exist-body": "Aucun compte lié à cet email n'a été trouvé dans le système de gestion des utilisateurs.",
      "email-sent-title": "L'email de vérification a été envoyé!",
      "email-sent-body": "Un email de vérification a été envoyé à votre compte email. " +
          "Veuillez suivre les instructions contenues dans l'e-mail. Voulez-vous aller à la page de connexion ?",
      "send-email": "Envoyer un email de vérification"
    }
  }
}

const navsidebar = {
  en: {
    translation: {
      "trips": "Trips",
      "tasks": "Tasks",
      "reservations": "Reservations",
      "vehicles": "Vehicles",
      "stations": "Stations",
      "users": "Users",
      "locks": "Locks",
      "pricing": "Pricing",
      "discounts": "Discounts",
      "emails": "Emails",
      "settings": "Settings",
      "projects": "Projects",
      "members": "Members",
      'sub-overview': 'Overview',
      'sub-details': 'Details',
    }
  },
  de: {
    translation: {
      "trips": "Reisen",
      "tasks": "Aufgaben",
      "reservations": "Reservierungen",
      "vehicles": "Fahrzeuge",
      "stations": "Stationen",
      "users": "Benutzer",
      "locks": "Schlösser",
      "pricing": "Preisgestaltung",
      "discounts": "Rabatt",
      "emails": "E-Mail",
      "settings": "Einstellungen",
      "projects": "Projekte",
      "members": "Mitglieder",
      'sub-overview': 'Überblick',
      'sub-details': 'Einzelheiten',
    }
  },
  fr: {
    translation: {
      "trips": "Trajets",
      "tasks": "Missions",
      "reservations": "Réservations",
      "vehicles": "Véhicules",
      "stations": "Stations",
      "users": "Utilisateurs",
      "locks": "Cadenas",
      "pricing": "Tarifs",
      "discounts": "Rabais",
      "emails": "Emails",
      "settings": "Paramètres",
      "projects": "Projets",
      "members": "Membres",
      'sub-overview': 'Apérçu',
      'sub-details': 'Détails',
    }
  }
}

const modal = {
  en: {
    translation: {
      "cancel": "Cancel",
      "ok": "OK",
      "valid-email": "You must enter a valid email address.",
      "valid-name": "You must enter a valid name.",
      "must-select-role": "You must select one role for the new member.",
      "select-role": "Select the role of the new member:",
      "select-new-role": "Select the new role of the member:",
      "select-super-admin-role": "The role of the new member will be:",
      "admin": "Admin",
      "read-only": "Read-only",
      "operator": "Operator",
      "super-admin": "Super-Admin",
      "must-select-project": "You must select at least one project.",
      "select-project": "Select the projects you want to add to this operator:",
      "operator-placeholder": "operator name",
      "project-placeholder": "project name",
      "loading": "loading..."
    }
  },
  de: {
    translation: {
      "cancel": "Abbrechen",
      "ok": "OK",
      "valid-email": "Sie müssen eine gültige E-Mail-Adresse eingeben.",
      "valid-name": "Sie müssen einen gültigen Namen eingeben.",
      "must-select-role": "Sie müssen eine Rolle für das neue Mitglied auswählen.",
      "select-role": "Wählen Sie die Rolle des neuen Mitglieds:",
      "select-new-role": "Wählen Sie die neue Rolle des Mitglieds aus:",
      "select-super-admin-role": "Die Roller des neuen Mitglieds wird:",
      "admin": "Verwalter",
      "read-only": "Nur-Lesen",
      "operator": "Betrieber",
      "super-admin": "Super-Admin",
      "must-select-project": "Sie müssen mindestens ein Projekt auswählen.",
      "select-project": "Wählen Sie die Projekte aus, die Sie zu diesem Operator hinzufügen möchten:",
      "operator-placeholder": "Betreibername",
      "project-placeholder": "Projektname",
      "loading": "... wird geladen"
    }
  },
  fr: {
    translation: {
      "cancel": "Annuler",
      "ok": "OK",
      "valid-email": "Vous devez saisir une addresse email valide.",
      "valid-name": "Vous devez saisir un nom valide.",
      "must-select-role": "Vous devez sélectionner un rôle pour le nouveau membre.",
      "select-role": "Sélectionnez le rôle du nouveau membre:",
      "select-new-role": "Sélectionnez le nouveau rôle du membre:",
      "select-super-admin-role": "Le rôle du nouveau membre sera:",
      "admin": "Admin",
      "read-only": "En lecture seule",
      "operator": "Opérateur",
      "super-admin": "Super-Admin",
      "must-select-project": "Vous devez sélectionner au moins un projet.",
      "select-project": "Sélectionnez les projets que vous voulez ajouter à cet opérateur:",
      "operator-placeholder": "nom de l'opérateur",
      "project-placeholder": "nom du projet",
      "loading": "chargement..."
    }
  }
}


const operatorProjects = {
  en: {
    translation: {
      "remove-project-title": "Removing project",
      "remove-project-body": "Are you sure you would like to remove this project?",
      "add-project-title": "Adding project",
      "add-project-body": "Select the projects that you would like to add:",
      "add-project": "Add project",
      "name": "Name",
      "linked-on": "Linked on",
      "n-users": "# Users",
      "n-vehicles": "# Vehicles",
      "n-stations": "# Stations",
      "remove": "Remove",
      "corporate": "Corporate",
      "tourism": "Tourism",
      "real-estate": "Real Estate",
      "select-project-type": "Select the type of the project:",
      "must-select-project-type": "You must select at least one project type."
    }
  },
  de: {
    translation: {
      "remove-project-title": "Projekt entfernen",
      "remove-project-body": "Sind Sie sicher, dass Sie dieses Projekt entfernen möchten?",
      "add-project-title": "Projekt hinzufügen",
      "add-project-body": "Wählen Sie die Projekte aus, die Sie hinzufügen möchten:",
      "add-project": "Projekt hinzufügen",
      "name": "Name",
      "linked-on": "Verknüpft am",
      "n-users": "# Benutzer",
      "n-vehicles": "# Fahrzeuge",
      "n-stations": "# Stationen",
      "remove": "Entfernen",
      "corporate": "Gesellschaft",
      "tourism": "Tourismus",
      "real-estate": "Immobilien",
      "select-project-type": "Wählen Sie den Projekttyp aus:",
      "must-select-project-type": "Sie müssen mindestens einen Projekttyp auswählen."
    }
  },
  fr: {
    translation: {
      "remove-project-title": "Suppression du projet",
      "remove-project-body": "Êtes-vous sûr de vouloir enlever ce projet ?",
      "add-project-title": "Ajouter un projet",
      "add-project-body": "Sélectionnez les projets que vous souhaitez ajouter:",
      "add-project": "Ajouter un projet",
      "name": "Nom",
      "linked-on": "Lié le",
      "n-users": "# Utilisateurs",
      "n-vehicles": "# Véhicules",
      "n-stations": "# Stations",
      "remove": "Supprimer",
      "corporate": "Entreprise",
      "tourism": "Tourisme",
      "real-estate": "Immobilier",
      "select-project-type": "Sélectionnez le type de projet:",
      "must-select-project-type": "Vous devez sélectionner au moins un type de projet."
    }
  }
}

const operatorUsers = {
  en: {
    translation: {
      "new-member": "New member",
      "role": "Role",
      "last-login": "Last Login",
      "edit": "Edit",
      "delete": "Delete",
      "you": "You",
      "invitation-sent": "Invitation sent",
      "remove-user-title": "Removing user",
      "remove-user-body": "Are you sure that you want to remove this user?",
      "add-member-title": "Add Member",
      "add-member-body": "Enter the email address of the member you wish to invite:",
      "edit-member-title": "Edit member's role",
      "edit-member-body": "Select the new role of the member"
    }
  },
  de: {
    translation: {
      "new-member": "Neues Mitglied",
      "role": "Rolle",
      "last-login": "Letzte Anmeldung",
      "edit": "Bearbeiten",
      "delete": "Löschen",
      "you": "Sie",
      "invitation-sent": "Einladung verschickt",
      "remove-user-title": "Entfernen des Benutzers",
      "remove-user-body": "Sind Sie sicher, dass Sie diesen Benutzer entfernen möchten?",
      "add-member-title": "Mitglied hinzufügen",
      "add-member-body": "Geben Sie die E-Mail-Adresse des Mitglieds ein, das Sie einladen möchten:",
      "edit-member-title": "Rolle des Mitglieds bearbeiten",
      "edit-member-body": "Wählen Sie die neue Rolle des Mitglieds"
    }
  },
  fr: {
    translation: {
      "new-member": "Nouveau membre",
      "role": "Rôle",
      "last-login": "Dernière connexion",
      "edit": "Modifier",
      "delete": "Supprimer",
      "you": "Vous",
      "invitation-sent": "Invitation envoyée",
      "remove-user-title": "Suppression de l'utilisateur",
      "remove-user-body": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
      "add-member-title": "Ajouter un membre",
      "add-member-body": "Saisissez l'adresse email du membre que vous souhaitez inviter :",
      "edit-member-title": "Modifier le rôle du membre",
      "edit-member-body": "Sélectionnez le nouveau rôle du membre"
    }
  }
}

const trips = {
  en: {
    translation: {
      'date': 'Date',
      'project': 'Project',
      'user': 'User',
      'vehicle': 'Vehicle',
      'start': 'Start',
      'start-station': 'Start',
      'end': 'End',
      'stop-station': 'Arrival',
      'duration': 'Duration',
      'termination': 'Termination',
      'late-return': 'Late Return',
      'reservation': 'Reservation',
      'day': "Day",
      'terminate': 'Terminate',
      'reservation-info': 'Here are the details of the reservation:',
      'terminate-trip-title':'Terminating trip',
      'terminate-trip-body':'Select the terminating time of this trip:',
      'reservation-stop': 'End of the reservation',
      'selected-date': 'Selected date',
      'export-to-excel': 'Export to Excel'
    }
  },
  de: {
    translation: {
      'date': 'Datum',
      'project': 'Projekt',
      'user': 'Benutzer',
      'vehicle': 'Fahrzeug',
      'start-station': 'Start',
      'start': 'Beginn',
      'stop-station': 'Ankunft',
      'end': 'Ende',
      'duration': 'Dauer',
      'termination': 'Beendigung',
      'late-return': 'Verspätungen',
      'reservation': 'Reservierung',
      'day': "Tag",
      'terminate': 'Beenden',
      'reservation-info': 'Hier sind die Einzelheiten der Reservierung:',
      'terminate-trip-title':'Beenden der Reise',
      'terminate-trip-body':'Wählen Sie den Endzeitpunkt dieser Reise:',
      'reservation-stop': 'Ende der Reservierung',
      'selected-date': 'Ausgewähltes Datum',
      'export-to-excel': 'Exportieren nach Excel'
    }
  },
  fr: {
    translation: {
      'date': 'Date',
      'project': 'Projet',
      'user': 'Utilisateur',
      'vehicle': 'Véhicule',
      'start-station': 'Départ',
      'start': 'Début',
      'stop-station': 'Arrivée',
      'end': 'Fin',
      'duration': 'Durée',
      'termination': 'Finalisation',
      'late-return': 'Retard',
      'reservation': 'Réservation',
      'day': 'Jour',
      'terminate': 'Terminer',
      'reservation-info': 'Voici les détails de la réservation:',
      'terminate-trip-title':'Terminer le trajet',
      'terminate-trip-body':'Sélectionnez l\'heure de fin de ce voyage:',
      'reservation-stop': 'Fin de la réservation',
      'selected-date': 'Date sélectionnée',
      'export-to-excel': 'Exporter vers Excel'
    }
  }
}

const reservations = {
  en: {
    translation: {
      'station': 'Station',
      'confirmed': 'Confirmed',
      'discount': 'Discount',
      'change': 'Change',
      'delete': 'Delete',
      'current-reservations': 'Current reservations',
      'all-reservations': 'All reservations',
      'cancel-reservation': 'Cancel',
      'cancel-reservation-title': 'Canceling reservation',
      'cancel-reservation-body': 'Are you sure that you would like to cancel this reservation?',
      'changing-reservation-title': 'Changing the vehicle of the reservation',
      'changing-reservation-body': 'Choose a spare vehicle for this reservation.',
      'current-vehicle': 'Current Vehicle'
    }
  },
  'de': {
    translation: {
      'station': 'Station',
      'confirmed': 'Bestätigt',
      'discount': 'Guthaben',
      'change': 'Ändern',
      'delete': 'Löschen',
      'current-reservations': 'Aktuelle Reservierungen',
      'all-reservations': 'Alle Reservierungen',
      'cancel-reservation': 'Stornieren',
      'cancel-reservation-title': 'Reservierung stornieren',
      'cancel-reservation-body': 'Sind Sie sicher, dass Sie diese Reservierung stornieren möchten?',
      'changing-reservation-title': 'Ändern des Fahrzeugs der Reservierung',
      'changing-reservation-body': 'Wählen Sie ein Ersatzfahrzeug für diese Reservierung:',
      'current-vehicle': 'Aktuelles Fahrzeug'
    }
  },
  'fr': {
    translation: {
      'station': 'Station',
      'confirmed': 'Confirmé',
      'discount': 'Coupon',
      'change': 'Changer',
      'delete': 'Supprimer',
      'current-reservations': 'Réservations actuelles',
      'all-reservations': 'Toutes les réservations',
      'cancel-reservation': 'Annuler',
      'cancel-reservation-title': 'Annulation de la réservation',
      'cancel-reservation-body': 'Êtes-vous sûr de vouloir annuler cette réservation?',
      'changing-reservation-title': 'Changement du véhicule de la réservation',
      'changing-reservation-body': 'Choisissez un véhicule de remplacement pour cette réservation:',
      'current-vehicle': 'Véhicule actuel'
    }
  }
}

const vehicles = {
  'en': {
    translation: {
      'type': 'Type',
      'brand': 'Brand',
      'serial-number': 'Serial Number',
      'lock': 'Lock',
      'last-modified': 'Last Modified',
      'new-vehicle': 'New Vehicle',
      'services': 'Maintenance',
      'new-service':'New maintenance',
      'delete-vehicle-title': 'Deleting vehicle',
      'delete-vehicle-body': 'Are you sure that you would like to delete this vehicle ?',
      'add-vehicle-title': 'Adding vehicle',
      'add-vehicle-body': 'Enter the information below to create a new vehicle:',
      'edit-vehicle-title': 'Editing vehicle',
      'edit-vehicle-body': 'Modify the information below to edit this vehicle:',
      'select-vehicle-type': 'Select the vehicle type:',
      'must-select-vehicle-type': 'You must select a vehicle type.',
      'bike': 'Bicycle',
      'e-bike': 'E-Bike',
      'e-bike-S': 'E-Bike (Size: S)',
      'e-bike-M': 'E-Bike (Size: M)',
      'e-bike-L': 'E-Bike (Size: L)',
      'cargo-bike': 'Cargo-Bike',
      'stroller': 'Baby stroller',
      'remarks': 'Remarks',
      'add-service-title': 'Adding a maintenance service',
      'add-service-body': 'Enter the description of maintenance service:',
      'delete-service-title': 'Deleting service',
      'delete-service-body': 'Are you sure that you would like to delete this maintenance service?',
      'available': 'Available',
      'reason': 'Reason of blocking',
      'broken': 'Broken',
      'battery': 'Battery',
      'battery-3days': 'Battery (3 days)',
      'maintenance': 'Repair (7 days)',
      'blocked-until': 'Blocked until',
      'on-the-road': 'on the road',
    }
  },
  'de': {
    translation: {
      'type': 'Typ',
      'brand': 'Marke',
      'serial-number': 'Seriennummer',
      'lock': 'Schloss',
      'last-modified': 'Zuletzt modifiziert',
      'new-vehicle': 'Neues Fahrzeug',
      'services': 'Service',
      'new-service':'Neues Wartungsdienst',
      'delete-vehicle-title': 'Fahrzeug löschen',
      'delete-vehicle-body': 'Sind Sie sicher, dass Sie dieses Fahrzeug löschen möchten?',
      'add-vehicle-title': 'Fahrzeug hinzufügen',
      'add-vehicle-body': 'Geben Sie die nachstehenden Informationen ein, um ein neues Fahrzeug anzulegen:',
      'edit-vehicle-title': 'Fahrzeug bearbeiten',
      'edit-vehicle-body': 'Ändern Sie die nachstehenden Informationen, um dieses Fahrzeug zu bearbeiten:',
      'select-vehicle-type': 'Wählen Sie den Fahrzeugtyp aus:',
      'must-select-vehicle-type': 'Sie müssen einen Fahrzeugtyp auswählen.',
      'bike': 'Fahrrad',
      'e-bike': 'E-Bike',
      'e-bike-S': 'E-Bike (Grösse: S)',
      'e-bike-M': 'E-Bike (Grösse: M)',
      'e-bike-L': 'E-Bike (Grösse: L)',
      'cargo-bike': 'Lastenfahrrad',
      'stroller': 'Kinderwagen',
      'remarks': 'Bemerkungen',
      'add-service-title': 'Hinzufügen eines Wartungsdienstes',
      'add-service-body': 'Geben Sie die Beschreibung der Wartungsdienstleistung ein:',
      'delete-service-title': 'Wartungsdienst löschen',
      'delete-service-body': 'Sind Sie sicher, dass Sie diesen Wartungsdienst löschen möchten?',
      'available': 'Verfügbar',
      'reason': 'Grund der Sperrung',
      'broken': 'Defekt',
      'battery': 'Batterie',
      'battery-3days': 'Batterie (3 Tage)',
      'maintenance': 'Reparatur (7 Tage)',
      'blocked-until': 'Blockiert bis',
      'on-the-road': 'unterwegs',
    }
  },
  'fr': {
    translation: {
      'type': 'Type',
      'brand': 'Marque',
      'serial-number': 'Numéro de série',
      'lock': 'Cadenas',
      'last-modified': 'Dernière modification',
      'new-vehicle': 'Nouveau véhicule',
      'services': 'Services',
      'new-service':'Nouveau service',
      'delete-vehicle-title': 'Supprimer un véhicule',
      'delete-vehicle-body': 'Êtes-vous sûr de vouloir supprimer ce véhicule ?',
      'add-vehicle-title': 'Ajouter un véhicule',
      'add-vehicle-body': 'Saisissez les informations ci-dessous pour créer un nouveau véhicule:',
      'edit-vehicle-title': 'Modifier le véhicule',
      'edit-vehicle-body': 'Modifiez les informations ci-dessous pour modifier ce véhicule:',
      'select-vehicle-type': 'Sélectionnez le type de véhicule:',
      'must-select-vehicle-type': 'Vous devez sélectionner un type de véhicule.',
      'bike': 'Vélo',
      'e-bike': 'E-Bike',
      'e-bike-S': 'E-Bike (Taille: S)',
      'e-bike-M': 'E-Bike (Taille: M)',
      'e-bike-L': 'E-Bike (Taille: L)',
      'cargo-bike': 'Vélo-cargo',
      'stroller': 'Poussette',
      'remarks': 'Remarque',
      'add-service-title': 'Ajout d\'un service de maintenance',
      'add-service-body': 'Saisissez la description du service de maintenance :',
      'delete-service-title': 'Suppression d\'un service',
      'delete-service-body': 'Êtes-vous sûr de vouloir supprimer ce service de maintenance ?',
      'available': 'Libre',
      'reason': 'Motif du blocage',
      'broken': 'Defectueux',
      'battery': 'Batterie',
      'battery-3days': 'Batterie (3 jours)',
      'maintenance': 'Réparation (7 jours)',
      'blocked-until': 'Bloqué jusqu\'à',
      'on-the-road': 'sur la route'
    }
  }
}

const stations = {
  'en': {
    translation: {
      'new-station': 'Add Station',
      'n-vehicles-target': '# Vehicles (target)',
      'n-vehicles-current': '# Vehicles (current)',
      'n-reservations-today': '# Reservations (today)',
      'n-reservations-tomorrow': '# Reservations (tomorrow)',
      'n-reservations-day-after-tomorrow': '# Reservations (after-tomorrow)',
      'geofencing': 'Geofencing',
      'add-station-title': 'Adding a new station',
      'add-station-body': 'Fill in the information about this station:',
      'edit-station-title': 'Editing a new station',
      'edit-station-body': 'Modify the information below to edit this station:',
      'position-of-the-station': 'Click on the map at the exact location of the station:',
      'activate-geofencing': 'Do you want to activate the geofencing?',
      'number-of-vehicles-per-category': 'How many vehicles will be at this station?',
      'radius-in-meters': 'Radius [m]:',
      'number-of-vehicle': 'Quantity:',
      'delete-station-title': 'Deleting station',
      'delete-station-body': 'Are you sure that you would like to delete this station ?',
    }
  },
  'de': {
    translation: {
      'new-station': 'Neue Station',
      'n-vehicles-target': '# Fahrzeuge (Ziel)',
      'n-vehicles-current': '# Fahrzeuge (aktuell)',
      'n-reservations-today': '# Reservierungen (heute)',
      'n-reservations-tomorrow': '# Reservierungen (morgen)',
      'n-reservations-day-after-tomorrow': '# Reservierungen (übermorgen)',
      'geofencing': 'Geofencing',
      'add-station-title': 'Eine neue Station hinzufügen.',
      'add-station-body': 'Füllen Sie die Informationen zu dieser Station aus:',
      'edit-station-title': 'Station bearbeiten',
      'edit-station-body': 'Ändern Sie die nachstehenden Informationen, um dieses Station zu bearbeiten:',
      'position-of-the-station': 'Klicken Sie auf die Karte, um den genauen Standort des Bahnhofs zu finden:',
      'activate-geofencing': 'Möchten Sie das Geofencing aktivieren?',
      'number-of-vehicles-per-category': 'Wie viele Fahrzeuge werden sich an dieser Station befinden?',
      'radius-in-meters': 'Radius [m]:',
      'number-of-vehicle': 'Menge:',
      'delete-station-title': 'Station löschen',
      'delete-station-body': 'Sind Sie sicher, dass Sie diesen Station löschen möchten?',
    }
  },
  'fr': {
    translation: {
      'new-station': 'Ajouter une station',
      'n-vehicles-target': '# Véhicules (cible)',
      'n-vehicles-current': '# Véhicules (actuel)',
      'n-reservations-today': '# Réservations (aujourd\'hui)',
      'n-reservations-tomorrow': '# Réservations (demain)',
      'n-reservations-day-after-tomorrow': '# Réservations (après-demain)',
      'geofencing': 'Geofencing',
      'add-station-title': 'Ajout d\'une nouvelle station',
      'add-station-body': 'Complétez les informations sur cette station :',
      'edit-station-title': 'Modifier la station',
      'edit-station-body': 'Modifiez les informations ci-dessous pour modifier cette station:',
      'position-of-the-station': 'Cliquez sur la carte à l\'emplacement exact de la station :',
      'activate-geofencing': 'Voulez-vous activer le geofencing ?',
      'number-of-vehicles-per-category': 'Combien de véhicules y aura-t-il dans cette station ?',
      'radius-in-meters': 'Rayon [m] :',
      'number-of-vehicle': 'Quantité:',
      'delete-station-title': 'Suppression d\'une station',
      'delete-station-body': 'Êtes-vous sûr de vouloir supprimer cette station ?',
    }
  }
}

const locks = {
  'en': {
    translation: {
      'new-lock': 'Add a new lock',
      'temp.': 'Temp.',
      'hum.':'Hum.',
      'messages': 'LoRa Messages',
      'open': 'Open',
      'closed': 'Closed',
      'add-lock-title': 'Adding a new lock',
      'add-lock-body': 'Fill in the information about the new lock:',
      'edit-lock-title': 'Editing lock',
      'edit-lock-body': 'Modify the information below to edit this lock',
      'delete-lock-title': 'Deleting lock',
      'delete-lock-body': 'Are you sure that you would like to delete this lock ?',
      'request-gps': 'GPS',
      'request-gps-title': 'Request GPS location',
      'request-gps-body': 'Are you sure that you would like to request the gps location ?',
      'request-gps-success-title': 'Request successful',
      'request-gps-success-body': 'The GPS request has been successful. Next time the lock will send a LoRa message, the GPS request will be sent. This can take up to one hour or more depending on the signal quality where the lock is located.',
    }
  },
  'de': {
    translation: {
      'new-lock': "Neues Schloss hinzufügen",
      'temp.': 'Temp.',
      'hum.':'Feu.',
      'messages': 'LoRa Nachrichten',
      'open': 'Offen',
      'closed': 'Geschlossen',
      'add-lock-title': 'Eines neuen Schlosses hinzufügen',
      'add-lock-body': 'Geben Sie die Informationen über das neue Schloss ein:',
      'edit-lock-title': 'Schloss Bearbeiten',
      'edit-lock-body': 'Ändern Sie die folgenden Informationen, um dieses Schloss zu bearbeiten',
      'delete-lock-title': 'Schloss löschen',
      'delete-lock-body': 'Sind Sie sicher, dass Sie dieses Schloss löschen möchten?',
      'request-gps': 'GPS',
      'request-gps-title': 'GPS-Standort abfragen',
      'request-gps-body': 'Sind Sie sicher, dass Sie den GPS-Standort abfragen möchten?',
      'request-gps-success-title': 'Anfrage erfolgreich',
      'request-gps-success-body': 'Die GPS-Anfrage war erfolgreich. Wenn das Schloss das nächste Mal eine LoRa-Nachricht sendet, wird die GPS-Anfrage gesendet. Dies kann bis zu einer Stunde oder länger dauern, abhängig von der Signalqualität am Standort des Schlosses.',
    }
  },
  'fr': {
    translation: {
      'new-lock': 'Ajouter un nouveau cadenas',
      'temp.': 'Temp.',
      'hum.':'Hum.',
      'messages': 'Messages LoRa',
      'open': 'Ouvert',
      'closed': 'Fermé',
      'add-lock-title': 'Ajouter un nouveau cadenas',
      'add-lock-body': 'Remplissez les informations relatives au nouveau cadenas:',
      'edit-lock-title': 'Modifier le cadenas',
      'edit-lock-body': 'Modifiez les informations ci-dessous pour modifier ce cadenas:',
      'delete-lock-title': 'Suppression d\'un cadenas',
      'delete-lock-body': 'Êtes-vous sûr de vouloir supprimer ce cadenas ?',
      'request-gps': 'GPS',
      'request-gps-title': 'Demande de localisation GPS',
      'request-gps-body': 'Êtes-vous sûr de vouloir demander la position GPS ?',
      'request-gps-success-title': 'Requête réussie',
      'request-gps-success-body': 'La demande GPS a réussi. La prochaine fois que le cadenas enverra un message LoRa, la demande GPS sera envoyée. Cela peut prendre jusqu\'à une heure ou plus selon la qualité du signal à l\'endroit où se trouve le cadenas.',
    }
  }
}

const users = {
  'en': {
    translation: {
      'id': 'ID',
      'phone': 'Phone',
      'first-name': 'First Name',
      'last-name': 'Second Name',
      'registration-date': 'Registration',
      'payment': 'Autorized',
      'admin': 'Admin',
      'action': 'Action',
      'address': 'Address',
      'city': 'City',
    }
  },
  'de': {
    translation: {
      'id': 'ID',
      'phone': 'Telefon',
      'first-name': 'Vorname',
      'last-name': 'Nachname',
      'registration-date': 'Anmeldung',
      'payment': 'Erlaubt',
      'admin': 'Verwalter',
      'action': 'Aktion',
      'address': 'Adresse',
      'city': 'Stadt',
    }
  },
  'fr': {
    translation: {
      'id': 'ID',
      'phone': 'Téléphone',
      'first-name': 'Prénom',
      'last-name': 'Nom de famille',
      'registration-date': 'Inscription',
      'payment': 'Autorisé',
      'admin': 'Admin',
      'action': 'Action',
      'address': 'Adresse',
      'city': 'Ville',
    }
  }
}

const discounts = {
  'en': {
    translation: {
      'code': 'Code',
      'from': 'From',
      'to': 'To',
      'number-of-users': '# Users',
      'validity': 'Validity',
      'new-discount': 'Add a discount',
      'delete-discount-title': 'Deleting discount',
      'delete-discount-body': 'Are you sure you would like to delete this discount?',
      'add-discount-title': 'Adding discount',
      'add-discount-body': 'Please enter the details of the new discount below:',
      'valid-code': 'You must enter a valid code.',
      'valid-discount': 'You must enter a valid discount. It must be a number.',
      'select-discount-type': 'Select a type of discount below:',
      'percent': 'Percentage',
      'absolute': 'Absolute',
      'hours': 'Hours'
    }
  },
  'de': {
    translation: {
      'code': 'Code',
      'from': 'Ab',
      'to': 'Bis',
      'number-of-users': '# Benutzer',
      'validity': 'Gültigkeit',
      'new-discount': 'Rabatt hinzufügen',
      'delete-discount-title': 'Rabatt löschen',
      'delete-discount-body': 'Sind Sie sicher, dass Sie diesen Rabatt löschen möchten?',
      'add-discount-title': 'Rabatt hinzufügen',
      'add-discount-body': 'Bitte geben Sie die Details des neuen Rabatts unten ein:',
      'valid-code': 'Sie müssen einen gültigen Code eingeben.',
      'valid-discount': 'Sie müssen einen gültigen Rabatt eingeben. Es muss eine Zahl sein.',
      'select-discount-type': 'Wählen Sie unten eine Art von Rabatt aus:',
      'percent': 'Prozentsatz',
      'absolute': 'Absolut',
      'hours': 'Stunden'
    }
  },
  'fr': {
    translation: {
      'code': 'Code',
      'from': 'Depuis',
      'to': 'Jusqu\'à',
      'number-of-users': '# Utilisateurs',
      'validity': 'Validité',
      'new-discount': 'Ajouter une promotion',
      'delete-discount-title': 'Supprimer la promotion',
      'delete-discount-body': 'Êtes-vous sûr de vouloir supprimer cette promotion?',
      'add-discount-title': 'Ajout d\'une promotion',
      'add-discount-body': 'Veuillez entrer les détails de la nouvelle promotion ci-dessous:',
      'valid-code': 'Vous devez saisir un code valide.',
      'valid-discount': 'Vous devez saisir une promotion valide. Ce doit être un nombre.',
      'select-discount-type': 'Sélectionnez un type de rabais ci-dessous :',
      'percent': 'Pourcentage',
      'absolute': 'Absolu',
      'hours': 'Heures'
    }
  }
}

const settings = {
  'en': {
    translation: {
      'project-name': 'Name of the project',
      'project-code': 'Code of the project',
      'save-project-settings': 'Save settings',
      'delete-project-title': 'Deletion of the project',
      'delete-project-body': 'You can delete this project by clicking on the button bellow:',
      'delete-project': 'Delete this project',
      'save-project-settings-body': 'Are you sure that you would like to save those new settings?',
      'email-domains': 'Domains of the users emails',
      'delete-project-confirmation-body': 'Are you sure that you would like to delete this project?',
      'add-domain': 'Add a domain',
      'add-domain-title': 'Adding a new domain',
      'add-domain-body': 'Please enter the email domain of your user without the @',
      'email-domain': 'Email domain name',
      'email-domain-example': 'e.g. airbie.io',
      'code-placeholder': 'Project code',
      'operator-name': 'Name of the operator',
      'save-operator-settings': 'Save settings',
      'save-operator-settings-body': 'Are you sure that you would like to save those new settings?',
      'delete-operator-title': 'Deletion of the operator',
      'delete-operator-body': 'You can delete this operator by clicking on the button bellow:',
      'delete-operator': 'Delete this operator',
      'delete-settings-confirmation-body': 'Are you sure that you would like to delete this operator?',
      'project-time-window': 'Time window of the reservations [day]',
      'project-max-reservation-in-time-window': 'Max # of reservation in the time window',
      'project-max-vehicles': 'Maximum number of vehicles per reservation',
      'project-max-duration': 'Maximum duration',
      'project-max-duration-week': 'In week [h]',
      'project-max-duration-friday': 'Friday [h]',
      'project-max-duration-saturday': 'Saturday [h]',
      'time-window-placeholder': '...',
      'max-vehicles-placeholder': '...',
      'project-max-reservation-in-time-window-placeholder': '...',
      'project-max-duration-week-placeholder': '...',
      'project-max-duration-friday-placeholder': '...',
      'project-max-duration-saturday-placeholder': '...',
    }
  },
  'de': {
    translation: {
      'project-name': 'Name des Projekts',
      'project-code': 'Code des Projekts',
      'save-project-settings': 'Einstellungen speichern',
      'delete-project-title': 'Löschung des Projekts',
      'delete-project-body': 'Sie können dieses Projekt löschen, indem Sie auf die Schaltfläche unten klicken:',
      'delete-project': 'Dieses Projekt löschen',
      'save-project-settings-body': 'Sind Sie sicher, dass Sie diese neuen Einstellungen speichern möchten?',
      'email-domains': 'Domänen der Benutzer-E-Mails',
      'delete-project-confirmation-body': 'Sind Sie sicher, dass Sie dieses Projekt löschen möchten?',
      'add-domain': 'Eine Domäne hinzufügen',
      'add-domain-title': 'Hinzufügen einer neuen Domäne',
      'add-domain-body': 'Bitte geben Sie die E-Mail-Domäne Ihres Benutzers ohne das @ ein.',
      'email-domain': 'E-Mail-Domainname',
      'email-domain-example': 'e.g. airbie.io',
      'code-placeholder': 'Projektcode',
      'operator-name': 'Name des Betreibers',
      'save-operator-settings': 'Einstellungen speichern',
      'save-operator-settings-body': 'Sind Sie sicher, dass Sie diese neuen Einstellungen speichern möchten?',
      'delete-operator-title': 'Löschung des Betreibers',
      'delete-operator-body': 'Sie können diesen Betreiber löschen, indem Sie auf die Schaltfläche unten klicken:',
      'delete-operator': 'Diesen Betreiber löschen',
      'delete-settings-confirmation-body': 'Sind Sie sicher, dass Sie diesen Operator löschen möchten?',
      'project-time-window': 'Zeitfenster der Reservierungen [Tag]',
      'project-max-reservation-in-time-window': 'Max # von Reservierungen im Zeitfenster',
      'project-max-vehicles': 'Maximale Anzahl von Fahrzeugen pro Reservierung',
      'project-max-duration': 'Maximale Dauer',
      'project-max-duration-week': 'am Woche [Std]',
      'project-max-duration-friday': 'Freitag [Std]',
      'project-max-duration-saturday': 'Samstag [Std]',
      'time-window-placeholder': '...',
      'max-vehicles-placeholder': '...',
      'project-max-reservation-in-time-window-placeholder': '...',
      'project-max-duration-week-placeholder': '...',
      'project-max-duration-friday-placeholder': '...',
      'project-max-duration-saturday-placeholder': '...',
    }
  },
  'fr': {
    translation: {
      'project-name': 'Nom du projet',
      'project-code': 'Code du projet',
      'save-project-settings': 'Sauvegarder les paramètres',
      'delete-project-title': 'Suppression du projet',
      'delete-project-body': 'Vous pouvez supprimer ce projet en cliquant sur le bouton ci-dessous :',
      'delete-project': 'Supprimer ce projet',
      'save-project-settings-body': 'Êtes-vous sûr de vouloir enregistrer ces nouveaux paramètres ?',
      'email-domains': 'Domaines des e-mails des utilisateurs',
      'delete-project-confirmation-body': 'Êtes-vous sûr de vouloir supprimer ce projet ?',
      'add-domain': 'Ajouter un domaine',
      'add-domain-title': 'Ajouter un nouveau domaine',
      'add-domain-body': 'Veuillez saisir le domaine de messagerie de votre utilisateur sans le @.',
      'email-domain': 'Nom de domaine de l\'e-mail',
      'email-domain-example': 'e.g. airbie.io',
      'code-placeholder': 'Code du projet',
      'operator-name': 'Nom de l\'opérateur',
      'save-operator-settings': 'Sauvegarder les paramètres',
      'save-operator-settings-body': 'Êtes-vous sûr de vouloir enregistrer ces nouveaux paramètres ?',
      'delete-operator-title': 'Suppression de l\'opérateur',
      'delete-operator-body': 'Vous pouvez supprimer cet opérateur en cliquant sur le bouton ci-dessous :',
      'delete-operator': 'Supprimer cet opérateur',
      'delete-settings-confirmation-body': 'Êtes-vous sûr de vouloir supprimer cet opérateur ?',
      'project-time-window': 'Fenêtre temporelle des réservations [jour]',
      'project-max-reservation-in-time-window': 'Max # de réservations pour la fenêtre temporelle',
      'project-max-vehicles': 'Nombre maximum de véhicules par réservation',
      'project-max-duration': 'Durée maximale',
      'project-max-duration-week': 'en semaine [h]',
      'project-max-duration-friday': 'vendredi [h]',
      'project-max-duration-saturday': 'samedi [h]',
      'time-window-placeholder': '...',
      'max-vehicles-placeholder': '...',
      'project-max-reservation-in-time-window-placeholder': '...',
      'project-max-duration-week-placeholder': '...',
      'project-max-duration-friday-placeholder': '...',
      'project-max-duration-saturday-placeholder': '...',
    }
  }
}

const pricing = {
  'en': {
    translation: {
      'e-bike-25': 'E-Bike (25km/h)',
      'e-bike-45': 'E-Bike (45km/h)',
      'price-without-abo': 'Price without subscription',
      'price-with-abo': 'Price with subscription',
      'add-price-level': 'Add Price Level',
      'add-pricing-level-title': 'Adding a Pricing Level',
      'add-pricing-level-body': 'Fill in the information below about this new pricing level.',
      'extra-hour-cost-without-abo': 'Additional hour without subscription [CHF]',
      'extra-hour-cost-with-abo': 'Additional hour with subscription [CHF]',
      'subscription-price': 'Subscription Price [CHF]',
      'saving-confirmation-title': 'Saved',
      'saving-confirmation-body': 'The values have been saved in the database.'
    }
  },
  'de': {
    translation: {
      'e-bike-25': 'E-Bike (25km/h)',
      'e-bike-45': 'E-Bike (45km/h)',
      'price-without-abo': 'Preis ohne Abo',
      'price-with-abo': 'Preis mit Abo',
      'add-price-level': 'Preisstufe hinzufügen',
      'add-pricing-level-title': 'Eine Preisstufe hinzufügen',
      'add-pricing-level-body': 'Füllen Sie die nachstehenden Informationen über diese neue Preisstufe aus.',
      'extra-hour-cost-without-abo': 'Weitere Stunde ohne Abonnement [CHF]',
      'extra-hour-cost-with-abo': 'Weitere Stunde mit Abonnement [CHF]',
      'subscription-price': 'Abonnementpreis [CHF]',
      'saving-confirmation-title': 'Gespeichert',
      'saving-confirmation-body': 'Die Werte wurden in der Datenbank gespeichert.'
    }
  },
  'fr': {
    translation: {
      'e-bike-25': 'E-Bike (25km/h)',
      'e-bike-45': 'E-Bike (45km/h)',
      'price-without-abo': 'Prix sans abonnement',
      'price-with-abo': 'Prix avec abonnement',
      'add-price-level': 'Ajouter un niveau de prix',
      'add-pricing-level-title': 'Ajout d\'un niveau de prix',
      'add-pricing-level-body': 'Remplissez les informations ci-dessous sur ce nouveau niveau de prix.',
      'extra-hour-cost-without-abo': 'Heure supplémentaire sans abonnement [CHF]',
      'extra-hour-cost-with-abo': 'Heure supplémentaire avec abonnement [CHF]',
      'subscription-price': 'Prix de l\'abonnement [CHF]',
      'saving-confirmation-title': 'Sauvegardé',
      'saving-confirmation-body': 'Les valeurs ont été enregistrées dans la base de données.'
    }
  }
}

const extra = {
  'en': {
    translation: {
      'under-construction': 'Under construction',
      'lora-id': 'Lora ID',
      'display-name': 'Name',
      'ble-name': 'BLE',
      'status': 'Status',
      'theft': 'Theft',
      'temperature': 'Temperature',
      'humidity': 'Humidity',
      'last-message': 'Time',
      'hidden': '***',
    }
  },
  'de': {
    translation: {
      'under-construction': 'Im Aufbau',
      'lora-id': 'Lora ID',
      'display-name': 'Name',
      'ble-name': 'BLE',
      'status': 'Zustand',
      'theft': 'Diebstahl',
      'temperature': 'Temperatur',
      'humidity': 'Feuchtigkeit',
      'last-message': 'Zeit',
      'hidden': '***',
    }
  },
  'fr': {
    translation: {
      'under-construction': 'En construction',
      'lora-id': 'Lora ID',
      'display-name': 'Name',
      'ble-name': 'BLE',
      'status': 'Statut',
      'theft': 'Vol',
      'temperature': 'Température',
      'humidity': 'Humidité',
      'last-message': 'Heure',
      'hidden': '***',
    }
  }
}

const resources = _.merge(login, navbar, register, resetPassword, navsidebar, modal, operatorProjects,
    operatorUsers, trips, reservations, vehicles, stations, locks, users, discounts,  extra, settings, pricing);

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      //lng: "de", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      fallbackLng: 'de',
      supportedLngs: ['de', 'en', 'fr'],
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

export default i18n;