import React from "react";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type DomainElementProps = {
  name: string,
  onDelete: () => void;
}

export default function DomainElement(props:DomainElementProps) {

  return (
    <div
      style={{width: '60%', padding: '2px', border: '1px grey solid', borderRadius: '5px', margin:'5px',
        display:'grid', gridTemplateColumns:'4fr 1fr', alignItems: 'center'}}>
      <div style={{color:'black', fontSize: 'medium'}}>
        {props.name}
      </div>
      <div style={{cursor:'pointer'}} onClick={props.onDelete}>
        <FontAwesomeIcon icon={faTrashAlt} size={"sm"}/>
      </div>
    </div>
  )
}