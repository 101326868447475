import React from 'react'
import {Modal} from "react-bootstrap";
import {WindMillLoading} from "react-loadingg";
import {useTranslation} from "react-i18next";

type LoadingElementProps = {
  loading: boolean
}

export default function LoadingElement(props:LoadingElementProps) {

  const { t } = useTranslation();

  return (
    <Modal
      size="sm"
      show={props.loading}
      keyboard={false}
      animation={false}
      centered={true}
      backdrop={'static'}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>{t("loading")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"loading-square"}>
          <WindMillLoading color={'black'} size={'large'} speed={2}/>
        </div>
      </Modal.Body>
    </Modal>
  )
}