import React, {useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {addDoc, collection, getFirestore} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type AddServiceModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
  projectId: string,
  vehicleId: string
}

export default function AddServiceModal(props:AddServiceModalProps) {

  const { t } = useTranslation();
  const [remarks, setRemarks] = useState("");
  const [displayRemarksError, setDisplayRemarksError] = useState(false);
  const [remarksError, setRemarksError] = useState("");

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (remarks == "" ) {
      setRemarksError(t("valid-remarks"));
      setDisplayRemarksError(true)
      setTimeout(() => setDisplayRemarksError(false), 5000)
    }

    if (remarks != "") {
      const timestamp = (new Date()).getTime();
      const projectId = props.projectId;
      const vehicleId = props.vehicleId;
      const userEmail = sessionStorage.getItem("userEmail");

      await addDoc(collection(db, "services"), {
        active: true,
        createdBy: userEmail,
        timestamp: timestamp,
        remarks: remarks,
        projectId: projectId,
        vehicleId: vehicleId,
      });

      handleConfirm()
    }
  }

  const cleanUp = () => {
    setRemarks("")
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  return (
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={validate}>
          <Modal.Body>
            <Form.Group>
              <div>
                <Form.Label>{props.body}</Form.Label>
              </div>
              <div>
                <Form.Label>{t('remarks')}</Form.Label>
                <Form.Control autoFocus type="name" onChange={(e) => setRemarks(e.target.value)} value={remarks}
                              placeholder={t("remarks")}/>
              </div>
              <div style={{color: displayRemarksError ? "red": "transparent", marginBottom:'10px'}}>
                {remarksError}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {props.showCancel && <Button variant="dark" onClick={handleClose}>
              {t("cancel")}
            </Button>}
            <Button variant="danger" type={'submit'}>
              {t("ok")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )

}


