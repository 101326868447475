import {RouteComponentProps, useHistory} from "react-router-dom";
import React from "react";
import {Navigation} from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGlobeEurope, faRoute} from '@fortawesome/free-solid-svg-icons'
import '../App.css';
import {useTranslation} from "react-i18next";

export const NavSidebarDashboard = (props: RouteComponentProps<Empty>) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="App-sidebar">
      <div className="navsidebar-title">
        <div className="navsidebar-title-icon">
          <FontAwesomeIcon icon={faGlobeEurope} style={{marginRight:'15px'}}/>
          {t("overview")}
        </div>
      </div>
      <div
        className={`fixed inset-y-0 left-0 z-30 w-10 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
          "ease-in -translate-x-full"
        }`}
      >
        <React.Fragment>
          <Navigation
            // you can use your own router's api to get pathname
            activeItemId={props.match.url}
            onSelect={({itemId}) => {
              history.push(itemId)
            }}
            items={[
              {
                title: t('trips'),
                itemId: '/dashboard/trips',
                elemBefore: () => <div>
                  <FontAwesomeIcon icon={faRoute}/>
                </div>,
              },
              /*{
                title: t('reservations'),
                itemId: '/dashboard/reservations',
                elemBefore: () => <div>
                  <FontAwesomeIcon icon={faCalendarAlt}/>
                </div>
              },
              {
                title: t('vehicles'),
                itemId: '/dashboard/vehicles',
                elemBefore: () => <div>
                  <FontAwesomeIcon icon={faBicycle}/>
                </div>
              },
              {
                title: t('stations'),
                itemId: '/dashboard/stations',
                elemBefore: () => <div>
                  <FontAwesomeIcon icon={faMapSigns}/>
                </div>
              },
              {
                title: t('users'),
                itemId: '/dashboard/users',
                elemBefore: () => <div>
                  <FontAwesomeIcon icon={faUser}/>
                </div>
              },
              {
                title: t('locks'),
                itemId: '/dashboard/locks',
                elemBefore: () => <div>
                  <FontAwesomeIcon icon={faLockOpen}/>
                </div>
              },*/
            ]}
          />
        </React.Fragment>
      </div>
    </div>
  )
}