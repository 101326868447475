import React, {useEffect, useState} from 'react'
import {Button, Form, FormCheck, Modal} from "react-bootstrap";
import {collection, doc, GeoPoint, getDocs, getFirestore, query, setDoc, where} from "@firebase/firestore";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import MapForAddStation from "../components/MapForAddStation";
import LatLng = google.maps.LatLng;


type EditStationModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  station: StationDetails | undefined,
}

export default function EditStationModal(props:EditStationModalProps) {

  const { t } = useTranslation();
  const [name, setName] = useState(props.station?.name);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [displayPositionError, setDisplayPositionError] = useState(false);
  const [positionError, setPositionError] = useState("");
  const [bikeAvailable, setBikeAvailable] = useState(props.station?.bikeAvailable);
  const [numberOfBikes, setNumberOfBikes] = useState(props.station?.numberOfBikes ?? 0);
  const [ebikeAvailable, setEBikeAvailable] = useState(props.station?.ebikeAvailable);
  const [numberOfEBikes, setNumberOfEBikes] = useState(props.station?.numberOfEBikes ?? 0);
  const [ebikeSAvailable, setEBikeSAvailable] = useState(props.station?.ebikeSAvailable);
  const [numberOfEBikesS, setNumberOfEBikesS] = useState(props.station?.numberOfEBikesS ?? 0);
  const [ebikeMAvailable, setEBikeMAvailable] = useState(props.station?.ebikeMAvailable);
  const [numberOfEBikesM, setNumberOfEBikesM] = useState(props.station?.numberOfEBikesM ?? 0);
  const [ebikeLAvailable, setEBikeLAvailable] = useState(props.station?.ebikeLAvailable);
  const [numberOfEBikesL, setNumberOfEBikesL] = useState(props.station?.numberOfEBikesL ?? 0);
  const [cargoAvailable, setCargoAvailable] = useState(props.station?.cargoAvailable);
  const [numberOfCargos, setNumberOfCargos] = useState(props.station?.numberOfCargos ?? 0);
  const [geofencing, setGeofencing] = useState(props.station?.geofencing);
  const [geofencingRadius, setGeofencingRadius] = useState(props.station?.geofencingRadius ?? 0);
  const [latLng, setLatLng] = useState<LatLng>(props.station?.latLng ?? new LatLng(0,0));


  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    console.log("validate")
    event.preventDefault();
    const db = getFirestore();

    if (name === "") {
      setNameError(t("valid-name"))
      setDisplayNameError(true)
      setTimeout(() => setDisplayNameError(false), 5000)
    }
    if (!(latLng)) {
      setPositionError(t("need-to-set-position"))
      setDisplayPositionError(true)
      setTimeout(() => setDisplayPositionError(false), 5000)
    }
    if (name !== "" && latLng) {

      const nVehicle = numberOfBikes + numberOfEBikes + numberOfCargos;
      const vehiclesTarget = new Map();
      if (bikeAvailable) {
        vehiclesTarget.set("bike", numberOfBikes);
      }
      if (ebikeAvailable) {
        vehiclesTarget.set("e-bike", numberOfEBikes);
      }
      if (ebikeSAvailable) {
        vehiclesTarget.set("e-bike-S", numberOfEBikesS);
      }
      if (ebikeMAvailable) {
        vehiclesTarget.set("e-bike-M", numberOfEBikesM);
      }
      if (ebikeLAvailable) {
        vehiclesTarget.set("e-bike-L", numberOfEBikesL);
      }
      if (cargoAvailable) {
        vehiclesTarget.set("cargo", numberOfCargos);
      }

      const station = {
        geofencing: geofencing,
        radius: geofencingRadius,
        nVehiclesTarget: nVehicle,
        name: name,
        position: new GeoPoint(latLng.lat(), latLng.lng()),
        vehiclesTarget: Object.fromEntries(vehiclesTarget.entries())
      }

      if (props.station) {
        const stationId = props.station.id

        await setDoc(doc(db, "stations", stationId),
          station, {merge: true});

        const path = "stations/" + stationId + "/edges";

        const result = await getDocs(query(collection(db, path),
          where("key", "==", 1)));

        for (const snap of result.docs) {
          await setDoc(doc(db, path, snap.id), {
            position: new GeoPoint(latLng.lat(), latLng.lng())
          }, {merge: true})
        }
      }

      handleConfirm()
    }
  }

  const cleanUp = () => {
    setDisplayNameError(false);
    setName("");
    setBikeAvailable(false);
    setEBikeAvailable(false);
    setEBikeSAvailable(false);
    setEBikeMAvailable(false);
    setEBikeLAvailable(false);
    setCargoAvailable(false);
    setNumberOfBikes(0);
    setNumberOfEBikes(0);
    setNumberOfEBikesS(0);
    setNumberOfEBikesM(0);
    setNumberOfEBikesL(0);
    setNumberOfCargos(0);
    setGeofencing(false);
    setGeofencingRadius(0);
  }

  const handleClose = () => {
    props.handleClose();
    cleanUp();
  }

  const handleConfirm = () => {
    props.handleConfirm();
    cleanUp();
  }

  useEffect(() => {
    setName(props.station?.name)
    setBikeAvailable(props.station?.bikeAvailable);
    setNumberOfBikes(props.station?.numberOfBikes ?? 0);
    setEBikeAvailable(props.station?.ebikeAvailable);
    setNumberOfEBikes(props.station?.numberOfEBikes ?? 0);
    setEBikeSAvailable(props.station?.ebikeSAvailable);
    setNumberOfEBikesS(props.station?.numberOfEBikesS ?? 0);
    setEBikeMAvailable(props.station?.ebikeMAvailable);
    setNumberOfEBikesM(props.station?.numberOfEBikesM ?? 0);
    setEBikeLAvailable(props.station?.ebikeLAvailable);
    setNumberOfEBikesL(props.station?.numberOfEBikesL ?? 0);
    setCargoAvailable(props.station?.cargoAvailable);
    setNumberOfCargos(props.station?.numberOfCargos ?? 0);
    setGeofencing(props.station?.geofencing);
    setGeofencingRadius(props.station?.geofencingRadius ?? 0);
    setLatLng(props.station?.latLng ?? new LatLng(0,0));

  }, [props.station])

  return (
    <Modal show={props.show} onHide={handleClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={validate}>
        <Modal.Body>
          <Form.Group>
            <div>
              <Form.Label>{props.body}</Form.Label>
            </div>
            <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
              <Form.Label style={{fontWeight: 'bold'}}>{t("name")}{": "}</Form.Label>
              <Form.Control autoFocus type="name" onChange={(e) => setName(e.target.value)}
                            value={name ?? ""} placeholder={t("name")} style={{width:'40%', marginLeft: '40px', marginRight: '20px'}} />
              <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px'}}>
                {nameError}
              </div>
            </div>
            <div style={{marginBottom: '10px', marginTop: '10px'}}>
              <Form.Label>{t("position-of-the-station")}</Form.Label>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
              <MapForAddStation setLatLng={setLatLng} latLng={latLng}/>
            </div>
            <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px', display:'flex',justifyContent:'center'}}>
              {positionError}
            </div>
            <div style={{marginBottom: '10px', marginTop: '20px'}}>
              <Form.Label>{t("activate-geofencing")}</Form.Label>
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('geofencing')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={geofencing} onChange={() => setGeofencing(!geofencing)}/>
                {(geofencing) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(geofencing) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('radius-in-meters')}</div>}
              {(geofencing) && <Form.Control datatype={"number"} type="number" onChange={(e) => setGeofencingRadius(parseInt(e.target.value))}
                                             value={geofencingRadius ?? 0} placeholder={"100 m"}/>}
            </div>
            <div style={{marginBottom: '10px', marginTop: '10px'}}>
              <Form.Label>{t("number-of-vehicles-per-category")}</Form.Label>
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('bike')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={bikeAvailable} onChange={() => setBikeAvailable(!bikeAvailable)}/>
                {(bikeAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(bikeAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(bikeAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfBikes(parseInt(e.target.value))}
                                                value={numberOfBikes ?? 0} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('e-bike')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={ebikeAvailable} onChange={() => setEBikeAvailable(!ebikeAvailable)}/>
                {(ebikeAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(ebikeAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(ebikeAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfEBikes(parseInt(e.target.value))}
                                                 value={numberOfEBikes ?? 0} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('e-bike-S')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={ebikeSAvailable} onChange={() => setEBikeSAvailable(!ebikeSAvailable)}/>
                {(ebikeSAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(ebikeSAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(ebikeSAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfEBikesS(parseInt(e.target.value))}
                                                 value={numberOfEBikesS ?? 0} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('e-bike-M')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={ebikeMAvailable} onChange={() => setEBikeMAvailable(!ebikeMAvailable)}/>
                {(ebikeMAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(ebikeMAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(ebikeMAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfEBikesM(parseInt(e.target.value))}
                                                  value={numberOfEBikesM ?? 0} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('e-bike-L')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={ebikeLAvailable} onChange={() => setEBikeLAvailable(!ebikeLAvailable)}/>
                {(ebikeLAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(ebikeLAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(ebikeLAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfEBikesL(parseInt(e.target.value))}
                                                  value={numberOfEBikesL ?? 0} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('cargo-bike')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={cargoAvailable} onChange={() => setCargoAvailable(!cargoAvailable)}/>
                {(cargoAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(cargoAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(cargoAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfCargos(parseInt(e.target.value))}
                                                 value={numberOfCargos ?? 0} placeholder={"#"}/>}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button variant="danger" type={'submit'}>
            {t("ok")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )

}