import React, {ChangeEvent, useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {useTranslation} from "react-i18next";
import {getDurationFormat} from "../utils/DateTimeUtil";
import {Button, Form, Tab, Tabs} from "react-bootstrap";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BasicModal from "../elements/BasicModal";
import ChangeReservationVehicleModal from "../elements/ChangeReservationVehicleModal";
import DateTimePicker from "react-datetime-picker";
import i18next from "i18next";


type ReservationsProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Reservations = (props:ReservationsProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = []
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState("current");
  const [rows, setRows] = useState(initRows);
  const [showCancel, setShowCancel] = useState(false);
  const [reservationId, setReservationId] = useState("");
  const [showChangeReservation, setShowChangeReservation] = useState(false);
  const [selectedStation, setSelectedStation] = useState("");
  const [vehicleToChange, setVehicleToChange] = useState("");
  const [vehicles, setVehicles] = useState(initRows);
  const [stations, setStations] = useState<DocumentData[]>([]);
  const [boxChecked, setBoxChecked] = useState(new Set());
  const [from, setFrom] = useState(null);
  const history = useHistory();

  const loadReservations = async (key: string) => {
    const db = getFirestore();
    const id = props.match.params.id;
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    yesterday.setUTCHours(23,59,59,999);
    const yesterdayAtMidnight = yesterday.getTime();
    let queryOperators;

    setLoading(true);
    setRows(initRows);

    const vehicleMap = new Map();
    const queryVehicles = await query(collection(db, "vehicles"),
      where("projectId", "==", id));
    const snapshotVehicles = await getDocs(queryVehicles);

    for (const vehicle of snapshotVehicles.docs) {
      vehicleMap.set(vehicle.id, {id:vehicle.id, ...vehicle.data()});
    }
    setVehicles(Array.from(vehicleMap.values()));

    if (key == 'current') {
      queryOperators = await query(collection(db, "reservations"),
        where("projectId", "==", id),
        where("active", "==", true),
        where("stop", ">=", yesterdayAtMidnight),
        orderBy("stop", "desc"),
        limit(500));
    } else {
      queryOperators = await query(collection(db, "reservations"),
        where("projectId", "==", id),
        orderBy("start", "desc"),
        limit(500));
    }

    const querySnapshot = await getDocs(queryOperators);
    const localRows : DocumentData[] = [];

    for (const reservation of querySnapshot.docs) {
      const vehicleData = vehicleMap.get(reservation.data().vehicleId);
      if (vehicleData) {
        localRows.push({'id': reservation.id, 'vehicleName': vehicleData.name, ...reservation.data()})
      }
    }

    if (key == 'current') {
      localRows.sort((a,b) => a.start - b.start);
    }

    setRows(localRows);

    return 0;
  }

  const handleCancelReservation = (reservationId: string) => {
    setReservationId(reservationId);
    setShowCancel(true);
  }

  const confirmReservation = () => {
    const db = getFirestore();
    const ref = doc(db, 'reservations', reservationId);
    setShowCancel(false);
    setDoc(ref, {active: false}, {merge: true})
      .then(() => {
        loadReservations(key)
          .finally(() => {
            setReservationId("");
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  const handleChangeReservation = (reservationId: string, vehicleName:string, station:string) => {
    setReservationId(reservationId);
    setVehicleToChange(vehicleName);
    setSelectedStation(station);
    setShowChangeReservation(true);
  }

  const handleConfirmChangeReservation = async (vehicleId: string) => {
    const db = getFirestore();
    const ref = doc(db, 'reservations', reservationId);
    const userEmail = sessionStorage.getItem("userEmail");

    setShowChangeReservation(false);
    setLoading(true);

    await addDoc(collection(db, "reservationChange"), {
      reservationId: reservationId,
      vehicleId: vehicleId,
      createdBy: userEmail,
      timestamp: Date.now()
    });

    setDoc(ref, {vehicleId: vehicleId}, {merge: true})
      .then(() => {
        loadReservations(key)
          .finally(() => {
            setReservationId("");
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
        setLoading(false);
      })
  }

  const loadStations = async () => {
    const db = getFirestore();
    const id = props.match.params.id;

    const queryStations = await query(collection(db, "stations"),
        where("projectId", "==", id), where("active", "==", true),
        orderBy("name", "asc"), limit(500));

    const querySnapshot = await getDocs(queryStations);
    const tempStations = [];
    for (const station of querySnapshot.docs) {
      tempStations.push({id: station.id, ...station.data()})
      setBoxChecked(boxChecked.add(station.get("name")))
    }
    setStations(tempStations)
  }

  const handleFilterStation = (e : ChangeEvent<HTMLInputElement>) => {
    const temp = new Set(boxChecked)
    if (e.target.checked) {
      temp.add(e.target.id)
    } else {
      temp.delete(e.target.id)
    }
    setBoxChecked(new Set(temp));
  }

  const tableHead = <tr>
    <th>{t("date")}</th>
    <th>{t("user")}</th>
    <th>{t("vehicle")}</th>
    <th>{t("station")}</th>
    <th>{t('confirmed')}</th>
    <th>{t("start")}</th>
    <th>{t("end")}</th>
    <th>{t("duration")}</th>
    <th>{t('discount')}</th>
    <th>{t('change')}</th>
    <th>{t('cancel-reservation')}</th>
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    const station = value.station;
    const startDate = new Date(value.start).toLocaleDateString();
    if (boxChecked.has(station) && (!from || (from && startDate === new Date(from).toLocaleDateString()))) {
      const duration = (value.stop) ? getDurationFormat(value.stop - value.start, t('day')) : ""
      const user = (value.userId).substring(0, 5);
      const userUrl = '/projects/' + props.match.params.id + '/users/details?id=' + value.userId;
      const confirmed = (value.confirmed && value.active) ?
          <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> :
          (value.confirmed && !value.active) ?
              <FontAwesomeIcon icon={faTimesCircle} color={"red"}/> : ""
      const startTime = new Date(value.start).toLocaleTimeString();
      const stopDate = (value.stop) ? new Date(value.stop).toLocaleDateString() : "-";
      const stopTime = (value.stop) ? new Date(value.stop).toLocaleTimeString() : "";
      const discount = value.discount;
      const change = (value.stop > Date.now() && value.active && value.confirmed) ?
          <Button variant="secondary" size={'sm'} onClick={() =>
              handleChangeReservation(value.id, value.vehicleName, value.station)}>{t('change')}</Button> : "";
      const cancel = (value.stop > Date.now() && value.active && value.confirmed) ?
          <Button variant="secondary" size={'sm'}
                  onClick={() => handleCancelReservation(value.id)}>{t('cancel-reservation')}</Button> : "";
      const vehicleUrl = '/projects/' + props.match.params.id + '/vehicles/details?id=' + value.vehicleId;
      const vehicleName =
          <Button variant={"link"} size={"sm"} onClick={() => {
            history.push(vehicleUrl)
          }}>{value.vehicleName}</Button>;

      return (
          <tr key={value.id} style={{alignItems: 'center'}}>
            <td>{startDate}</td>
            <td><Button variant="link" size={'sm'}
                        onClick={() => history.push(userUrl)}>{user}</Button>
            </td>
            <td>{vehicleName}</td>
            <td>{station}</td>
            <td>{confirmed}</td>
            <td>{startDate}{<br/>}{startTime}</td>
            <td>{stopDate}{<br/>}{stopTime}</td>
            <td>{duration}</td>
            <td>{discount}</td>
            <td>{change}</td>
            <td>{cancel}</td>
          </tr>)
    }
  })

  useEffect(() => {
    setLoading(true)
    loadReservations(key)
        .finally(() => {
          loadStations()
              .finally(() => {
                setLoading(false)
              })
        })
  }, [key])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                          projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
      <ChangeReservationVehicleModal show={showChangeReservation}
                                     station={selectedStation}
                                     vehicle={vehicleToChange}
                                     otherVehicles={vehicles}
                                     handleClose={() => setShowChangeReservation(false)}
                                     handleConfirm={handleConfirmChangeReservation}/>
      <BasicModal show={showCancel} title={t('cancel-reservation-title')}
                  body={t('cancel-reservation-body')} handleClose={() => setShowCancel(false)}
                  handleConfirm={confirmReservation} showCancel={true}/>
      <div className="App-header">
        <div className="App-box">
          <Tabs defaultActiveKey="current" id="uncontrolled-tab-example" className="mb-3" transition={true}
                style={{marginLeft: '40px', marginTop: '10px'}}
                activeKey={key}
                onSelect={(k) => {return k ? setKey(k.toString()) : setKey("")}}>
            <Tab eventKey="current" title={t("current-reservations")}>
              <div style={{display: 'flex', flexDirection:'row', margin: 'auto', justifyContent:'flex-start'}}>
                <div style={{marginRight: 10, marginLeft: 30, color:'black'}}>{t("stations")}{":"}</div>
                {stations.map((station) => {
                  return <Form.Check
                      style={{fontWeight: 'bold', fontSize:16, marginRight: 10, marginTop: 6, marginLeft: 10, color:'black'}}
                      type={"checkbox"}
                      key={station.id}
                      id={station.name}
                      label={station.name}
                      onChange={(e) => handleFilterStation(e)}
                      checked={boxChecked.has(station.name)}
                  />
                })}
              </div>
              <div style={{marginBottom: '10px', display: 'flex', flexDirection:'row', color:'black', marginLeft: 30}}>
                <Form.Label>{t("date")}{":"}</Form.Label>
                <div style={{display:'flex', justifyContent:'center', marginLeft: 30,}}>
                  <DateTimePicker locale={i18next.language} onChange={setFrom} value={from} minDate={new Date()}
                                  disableClock={true} format={"yyyy-MM-dd"} disableCalendar={true}/>
                </div>
              </div>
              <div className="App-table">
                <div className="table-responsive">
                  <table className="table" id="project-table">
                    <thead>{tableHead}</thead>
                    <tbody>{tableBody}</tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="all" title={t("all-reservations")}>
              <div style={{display: 'flex', flexDirection:'row', margin: 'auto', justifyContent:'flex-start'}}>
                <div style={{marginRight: 10, marginLeft: 30, color:'black'}}>{t("stations")}{":"}</div>
                {stations.map((station) => {
                  return <Form.Check
                      style={{fontWeight: 'bold', fontSize:16, marginRight: 10, marginTop: 6, marginLeft: 10, color:'black'}}
                      type={"checkbox"}
                      key={station.id}
                      id={station.name}
                      label={station.name}
                      onChange={(e) => handleFilterStation(e)}
                      checked={boxChecked.has(station.name)}
                  />
                })}
              </div>
              <div style={{marginBottom: '10px', display: 'flex', flexDirection:'row', color:'black', marginLeft: 30}}>
                <Form.Label>{t("date")}{":"}</Form.Label>
                <div style={{display:'flex', justifyContent:'center', marginLeft: 30,}}>
                  <DateTimePicker locale={i18next.language} onChange={setFrom} value={from}
                                  disableClock={true} format={"yyyy-MM-dd"} disableCalendar={true}/>
                </div>
              </div>
              <div className="App-table">
                <div className="table-responsive">
                  <table className="table" id="project-table">
                    <thead>{tableHead}</thead>
                    <tbody>{tableBody}</tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default Reservations;