import React, {useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where
} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faLockOpen,
  faTemperatureHigh,
  faTemperatureLow,
  faWater
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {FormCheck, ListGroup, Tab, Tabs} from "react-bootstrap";
import {Navigation} from "react-minimal-side-navigation";
import MapComponent from "../components/MapComponent";
import GaugeChart from "react-gauge-chart";
import openLock from "../resources/lock-open-blinking.gif";
import closedLock from "../resources/lock-closed.png";
import TimeseriesChart from "../charts/TimeseriesChart";

type LocksDetailsProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const LocksDetails = (props:LocksDetailsProps) => {

  const {t} = useTranslation();
  const initRows: SideMenu[] = [];
  const initMessageRows: DocumentData[] = [];
  const init : DocumentData  = {};
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(props.match.params.id);
  const [rows, setRows] = useState(initRows);
  const [messageRows, setMessageRows] = useState(initMessageRows);
  const [lockId, setLockId] = useState("");
  const [lockData, setLockData] = useState(init);
  const [key, setKey] = useState("messages");
  const [showChart, setShowChart] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const history = useHistory();

  const loadLocks = async () => {
    const db = getFirestore();
    const id = props.match.params.id;
    setRows(initRows)

    const queryLocks = await query(collection(db, "locks"),
        where("projectId", "==", id), where("active", "==", true),
        orderBy("name", "asc"), limit(500));

    const querySnapshot = await getDocs(queryLocks);

    for (const lock of querySnapshot.docs) {
      const lockData = lock.data();
      rows.push(
          {
            title: lockData.displayName,
            itemId: lock.id,
            elemBefore: () => <div><FontAwesomeIcon icon={faLockOpen}/></div>,
          })
    }
    rows.sort((a,b) => a.title === b.title ? 0 : a.title < b.title ? -1 : 1);
    return 0;
  }

  const loadLockDetails = async (lockId : string) => {

    const db = getFirestore();
    const id = props.match.params.id;

    const lockRef = doc(db, "locks", lockId);
    const lock = await getDoc(lockRef);
    const lockData = lock.data();
    if (lockData) {
      setLockData(lockData);

      const queryMessages = await query(collection(lockRef, "history"),
        orderBy("timestamp", "desc"),
        limit(20));

      const messageSnapshot = await getDocs(queryMessages);
      const localRows: DocumentData[] = [];

      let i = 0;
      for (const message of messageSnapshot.docs) {
        i++;
        localRows.push({'id': message.id,  ...message.data()})
      }

      setMessageRows(localRows);
    }

    return 0;
  }

  const tableHead = <tr>
    <th>{t("timestamp")}</th>
    <th>{t("battery")}</th>
    <th>{t("status")}</th>
    <th>{t('theft')}</th>
    <th>{t("temperature")}</th>
    <th>{t("humidity")}</th>
    <th>{t("GPS")}</th>
    <th>{t("Antenna")}</th>
  </tr>

  const tableBody = messageRows.map((value: DocumentData) => {
    return (
      <tr key={value.id} style={{alignItems:'center'}}>
        <td>{new Date(value.timestamp).toLocaleDateString()}<br/>
          {new Date(value.timestamp).toLocaleTimeString()}</td>
        <td>{value.battery}</td>
        <td>{(value.isOpen) ? t('open') : t('closed')}</td>
        <td>{(value.theft) ?
          <FontAwesomeIcon icon={faExclamationTriangle} color={'red'} size={'lg'}/> :
          <FontAwesomeIcon icon={faCheckCircle} color={"green"}/>}</td>
        <td>{value.temperature}{"°"}</td>
        <td>{value.humidity}{"%"}</td>
        <td><FormCheck type={"switch"} checked={false} onChange={() => console.log("change")}/></td>
        <td><FormCheck type={"switch"} checked={false} onChange={() => console.log("change")}/></td>
      </tr>
    )
  });

  const showBatteryChart = () => {
    setSelectedField("battery");
    setShowChart(true);
  }

  const showStatusChart = () => {
    setSelectedField("isOpen");
    setShowChart(true);
  }

  const showTheftChart = () => {
    setSelectedField("theft");
    setShowChart(true);
  }

  const showTemperatureChart = () => {
    setSelectedField("temperature");
    setShowChart(true);
  }

  const showHumidityChart = () => {
    setSelectedField("humidity");
    setShowChart(true);
  }

  const gauge =
    <div className="device-data-button-row" id={"gauge-"+lockData.id} onClick={() => showBatteryChart()}>
      <GaugeChart id={"gauge-chart-"+lockData.id} nrOfLevels={3} percent={Math.round(lockData.battery)/100} animate={false}
                  colors={['red','yellow','green']} style={{width:60}}/> {Math.round(lockData.battery)}{"%"}
    </div>

  const lockStatus =
    <div className="device-data-button" id="lockStatus" onClick={() => showStatusChart()}>
      <img
        src={lockData.isOpen ? openLock : closedLock}
        height="50"
        className="d-inline-block align-top"
        alt="Locked Closed"/>
    </div>

  const theftDetection = <div className="device-data-button" id="theft" onClick={() => showTheftChart()}>
    {(lockData.theft) ?
      <FontAwesomeIcon icon={faExclamationTriangle} color={'red'} size={'lg'}/> :
      <FontAwesomeIcon icon={faCheckCircle} color={"green"}/>}
  </div>;

  const temperature = (lockData.temperature) ?
    <div onClick={() => showTemperatureChart()}>{(lockData.temperature < 40) ?
      <div className="device-data-button-row" id="temperature">
        {Math.round(lockData.temperature)}{"° "}
        <FontAwesomeIcon icon={faTemperatureLow} size={'lg'} style={{marginLeft: 10, marginRight:20}}/></div> :
      <div className="device-data-button-row" style={{color:'red'}} id="temperature">
        {Math.round(lockData.temperature)}{"° "}
        <FontAwesomeIcon icon={faTemperatureHigh} color={'red'} size={'lg'} style={{marginLeft: 10, marginRight:20}}/></div>}</div> : "";

  const humidity = (lockData.humidity) ?
    <div onClick={() => showHumidityChart()}>{(lockData.humidity < 95) ?
      <div className="device-data-button-row" id="humidity">
        {lockData.humidity}{"%"} <FontAwesomeIcon icon={faWater} size={'lg'} style={{marginLeft: 10}}/> </div> :
      <div className="device-data-button-row" style={{color: 'red'}} id="humidity">
        {lockData.humidity}{"%"} <FontAwesomeIcon icon={faWater} color={'red'} size={'lg'} style={{marginLeft: 10}}/></div>}</div>
    : "";

  const lastMessageDate = (lockData.lastMessage) ? new Date(lockData.lastMessage).toLocaleDateString() : "";
  const lastMessageTime = (lockData.lastMessage) ? new Date(lockData.lastMessage).toLocaleTimeString() : "";

  useEffect(() => {
    setLoading(true);
    const searchParam = "?id=";
    const str = props.location.search.toString();
    const lockId = str.substring(searchParam.length);

    loadLocks()
      .finally(() => {
        setLoading(false);
        loadLockDetails(lockId)
          .finally(() => {
            setLockId(lockId)
            setLoading(false);
          })
      })
  }, [])

  return (
      <div className="Dashboard">
        <LoadingElement loading={loading}/>
        <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                            projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
        <div className="App-header">
          <TimeseriesChart show={showChart} lockId={lockId}
                           type={selectedField} handleClose={() => {setShowChart(false)}}/>
          <div className="App-side-menu">
            <div className="App-side-menu-title">
              {t("locks")}
            </div>
            <div className="App-side-menu-body">
              <React.Fragment>
                <Navigation
                    // you can use your own router's api to get pathname
                    activeItemId={props.location.search.substr(4)}
                    onSelect={({itemId}) => {
                      if (projectId) {
                        setLoading(true)
                        setLockId(itemId)
                        const url = '/projects/' + projectId + '/locks/details?id=' + itemId;
                        history.push(url)
                        loadLockDetails(itemId)
                          .finally(() => {
                            setLoading(false);
                          })
                      }
                    }}
                    items={rows}
                />
              </React.Fragment>
            </div>
          </div>
          {lockId != "" && <div className="App-box-small">
            <div className="App-box-upper">
              <div className="App-list-group">
                <ListGroup>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('display-name')}{": "}</div>
                    <div className="App-list-group-item-2">{lockData.displayName}</div></div></ListGroup.Item>
                  <ListGroup.Item><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('lora-id')}{": "}</div>
                    <div className="App-list-group-item-2">{lockData.loraId}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('battery')}{": "}</div>
                    <div className="App-list-group-item-2">{gauge}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1">
                      {t('status')}{"/"}{t('theft')}{": "}</div>
                    <div className="App-list-group-item-2-row">
                      {lockStatus}{theftDetection}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1">
                      {t('temp.')}{"/"}{t('hum.')}{": "}</div>
                    <div className="App-list-group-item-2-row">
                      {temperature}{humidity}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('last-message')}{": "}</div>
                    <div className="App-list-group-item-2-row">
                      {lastMessageDate}{" - "}{lastMessageTime}</div></div></ListGroup.Item>
                </ListGroup>
              </div>
              <div className="App-map">
                <MapComponent
                  markers={[
                  {id: 1, type:'vehicle', position:{lat:lockData.position?.latitude, lng:lockData.position?.longitude}},
                  {id: 2, type:'antenna', position:{lat:lockData.antennaPosition?.latitude, lng:lockData.antennaPosition?.longitude}}
                  ]}/>
              </div>
            </div>
            <Tabs defaultActiveKey="current" id="uncontrolled-tab-example" className="mb-3" transition={true}
                  style={{marginLeft: '40px', marginTop: '10px'}}
                  activeKey={key}
                  onSelect={(k) => {return k ? setKey(k.toString()) : setKey("")}}>
              <Tab eventKey="messages" title={t('messages')}>
                <div className="App-table" style={{height: '40vh'}}>
                  <div className="table-responsive" style={{height: '40vh'}}>
                    <table className="table" id="project-table">
                      <thead>{tableHead}</thead>
                      <tbody>{tableBody}</tbody>
                    </table>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>}
          {lockId == "" && <div className="App-box-small-empty">
          </div>}
        </div>
      </div>
  )

}

export default LocksDetails;