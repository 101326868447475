import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {collection, doc, DocumentData, getDocs, getFirestore, query, setDoc, where} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type EditLockModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  projectId: string,
  lockId: string,
  lock: Lock | undefined,
  projectMap: Map<string,DocumentData>
}

export default function EditLockModal(props:EditLockModalProps) {

  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState(props.lock?.displayName);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState(props.projectId);

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (displayName == "" ) {
      setNameError(t("valid-name"));
      setDisplayNameError(true)
      setTimeout(() => setDisplayNameError(false), 5000)
    }

    if (displayName !== "") {
      const isGlobal = (props.projectMap?.get(selectedProjectId)?.type === 'tourism');

      await setDoc(doc(db, "locks", props.lockId), {
        displayName: displayName,
        isGlobal: isGlobal,
        projectId: selectedProjectId,
      }, {merge: true});

      if (props.lock && selectedProjectId !== props.projectId) {
        const vehicleRef = query(collection(db, "vehicles"),
          where("lockId", "==", props.lock.loraId));
        const vehicleData = await getDocs(vehicleRef);
        for (const vehicle of vehicleData.docs) {
          await setDoc(doc(db, "vehicles", vehicle.id), {
            lockId: ""
          }, {merge: true});
        }
      }

      handleConfirm()
    }
  }

  const onSelectNewProject = (event: ChangeEvent<HTMLSelectElement>) => {
    const projectId = event.target.options[event.target.selectedIndex].id;
    setSelectedProjectId(projectId);
  }

  const cleanUp = () => {
    setDisplayName("");
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  useEffect(() => {
    setDisplayName(props.lock?.displayName);
    setSelectedProjectId(props.projectId);
  }, [props.lock, props.projectId])

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={validate}>
        <Modal.Body>
          <Form.Group>
            <div>
              <Form.Label>{props.body}</Form.Label>
            </div>
            <div>
              <Form.Label>{t('display-name')}</Form.Label>
              <Form.Control type="name" onChange={(e) => setDisplayName(e.target.value)} value={displayName ?? ""}
                            placeholder={t("display-name")}/>
            </div>
            <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px'}}>
              {nameError}
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label>{t('project')}</Form.Label>
              <Form.Select size={"sm"} aria-label="station" defaultValue={props.projectMap?.get(props.projectId)?.name}
                           onChange={(event) => onSelectNewProject(event)}>
                {Array.from(props.projectMap?.keys())
                  .filter((projectId) => props.projectMap?.get(projectId)?.clientId === sessionStorage.getItem("clientId"))
                  .map((projectId) => {
                    const projectName = props.projectMap?.get(projectId)?.name;
                    return <option key={projectId} id={projectId} value={projectName}>
                      {projectName}</option>
                })}
              </Form.Select>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button variant="danger" type={'submit'}>
            {t("ok")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )

}