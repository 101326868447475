import React, {useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  addDoc,
  collection, deleteDoc,
  doc,
  DocumentData, getDoc, getDocs,
  getFirestore, orderBy,
  query,
  setDoc, where
} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faPlusCircle, faSave,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button, Form, Tab, Tabs} from "react-bootstrap";
import BasicModal from "../elements/BasicModal";
import AddPricingLevelModal from "../elements/AddPricingLevelModal";

type PricingProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Pricing = (props:PricingProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = [];
  const [rows, setRows] = useState(initRows);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("e-bike-25");
  const [subscriptionPrice, setSubscriptionPrice] = useState("");
  const [extraHourCostWithSubscription, setExtraHourCostWithSubscription] = useState("");
  const [extraHourCostWithoutSubscription, setExtraHourCostWithoutSubscription] = useState("");
  const [showAddPriceLevel, setShowAddPriceLevel] = useState(false);
  const [showSavingConfirmation, setShowSavingConfirmation] = useState(false);

  const loadPricing = async (key: string) => {
    const db = getFirestore();
    const id = props.match.params.id;

    const queryPricing = await query(collection(db, "pricing"),
        where("projectId", "==", id));
    const snapshotPricing = await getDocs(queryPricing);

    for (const pricing of snapshotPricing.docs) {
      if (pricing.get("vehicleType") == key) {
        setExtraHourCostWithSubscription(pricing.get("extraHourPriceWithSubscription"))
        setExtraHourCostWithoutSubscription(pricing.get("extraHourPriceWithoutSubscription"))
        setSubscriptionPrice(pricing.get("subscriptionPrice"))
        const queryLevels = await query(collection(db, "pricing/" + pricing.id + "/levels"),
            orderBy("start"))
        const snapshotQueryLevels = await getDocs(queryLevels);

        const localRows : DocumentData[] = [];

        for (const level of snapshotQueryLevels.docs) {
          localRows.push({'id': level.id, ...level.data()})
        }

        setRows(localRows)
      }
    }
  }

  const tableHead = <tr>
    <th>{t("start")}</th>
    <th>{t("end")}</th>
    <th>{t("price-without-abo")}</th>
    <th>{t("price-with-abo")}</th>
    <th>{t("delete")}</th>
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    return (
        <tr key={value.id} style={{alignItems:'center'}}>
          <td>{value.start}{" "}{t("hours")}</td>
          <td>{value.end}{" "}{t("hours")}</td>
          <td>{value.priceWithoutSubscription}{" "}{("CHF")}</td>
          <td>{value.priceWithSubscription}{" "}{("CHF")}</td>
          <td><Button variant="secondary" size={'sm'}
                      onClick={() => deleteRow(value.id)}>{t('delete')}
          </Button></td>
        </tr>
    )
  })

  const confirmPricingLevel = async (start:string,
                               end:string,
                               priceWithoutSubscription:string,
                               priceWithSubscription:string) => {
    const db = getFirestore();
    const id = props.match.params.id;
    let path = "";
    setShowAddPriceLevel(false);
    setLoading(true);

    const queryPricing = await query(collection(db, "pricing"),
        where("projectId", "==", id),
        where("vehicleType", "==", key));
    const querySnapshot = await getDocs(queryPricing);

    for (const doc of querySnapshot.docs) {
      path = "pricing/" + doc.id + "/levels"
    }

    await addDoc(collection(db, path), {
      start: parseInt(start),
      end:parseInt(end),
      priceWithoutSubscription: parseInt(priceWithoutSubscription),
      priceWithSubscription: parseInt(priceWithSubscription),
    })

    loadPricing(key)
        .finally(() => {
          setLoading(false)
        })
  }

  const cleanUp = () => {
    setSubscriptionPrice("")
    setExtraHourCostWithSubscription("")
    setRows([])
  }

  const save = async () => {
    const db = getFirestore();
    const id = props.match.params.id;
    setLoading(true);

    const queryPricing = await query(collection(db, "pricing"),
        where("projectId", "==", id),
        where("vehicleType", "==", key));
    const querySnapshot = await getDocs(queryPricing);

    for (const document of querySnapshot.docs) {
      const ref = doc(db, "pricing", document.id);

      setDoc(ref, {
        subscriptionPrice: parseInt(subscriptionPrice),
        extraHourPriceWithSubscription: parseInt(extraHourCostWithSubscription),
        extraHourPriceWithoutSubscription: parseInt(extraHourCostWithoutSubscription),
      }, {merge: true})
          .then(() => {
            loadPricing(key)
                .finally(() => {
                  setLoading(false)
                  setShowSavingConfirmation(true)
                })
          })
          .catch((err) => {
            console.log(err)
            setLoading(false);
          })
    }
  }

  const deleteRow = async (valueId: string) => {
    const db = getFirestore();
    const id = props.match.params.id;
    setLoading(true)
    const queryPricing = await query(collection(db, "pricing"),
        where("projectId", "==", id));
    const snapshotPricing = await getDocs(queryPricing);

    for (const pricing of snapshotPricing.docs) {
      if (pricing.get("vehicleType") == key) {
        const ref = doc(db, "pricing/" + pricing.id + "/levels" , valueId);
        await deleteDoc(ref)
        loadPricing(key)
            .finally(() => {
              setLoading(false)
            })
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    cleanUp()
    loadPricing(key)
        .finally(() => {
          setLoading(false)
        })
  }, [key])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                          projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
      <AddPricingLevelModal show={showAddPriceLevel} title={t('add-pricing-level-title')}
                  body={t('add-pricing-level-body')} handleClose={() => setShowAddPriceLevel(false)}
                  handleConfirm={confirmPricingLevel} showCancel={true} projectId={props.match.params.id}/>
      <BasicModal show={showSavingConfirmation} title={t('saving-confirmation-title')}
                  body={t('saving-confirmation-body')} handleClose={() => setShowSavingConfirmation(false)}
                  handleConfirm={() => setShowSavingConfirmation(false)} showCancel={false}/>
      <div className="App-header">
        <div className="App-box">
          <Tabs defaultActiveKey="e-bike-25" id="uncontrolled-tab-example" className="mb-3" transition={true}
                style={{marginLeft: '40px', marginTop: '10px'}}
                activeKey={key}
                onSelect={(k) => {return k ? setKey(k.toString()) : setKey("")}}>
            <Tab eventKey="e-bike-25" title={t("e-bike-25")}>
              <div className="App-button">
                <Button variant="danger" onClick={() => setShowAddPriceLevel(true)}>
                  <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
                  {t("add-price-level")}
                </Button>
              </div>
              <div className="App-table" style={{height: '30vh'}}>
                <div className="table-responsive">
                  <table className="table" id="project-table">
                    <thead>{tableHead}</thead>
                    <tbody>{tableBody}</tbody>
                  </table>
                </div>
              </div>
              <Form style={{display:"flex", flexDirection:'column', marginBottom: '10px'}}>
                <div style={{display:"flex", flexDirection:'row'}}>
                  <div className="Subscription-cost-input">
                    <Form.Label style={{fontSize:16}}>{t("extra-hour-cost-without-abo")}</Form.Label>
                    <Form.Control type="number" onChange={(e) => setExtraHourCostWithoutSubscription(e.target.value)}
                                  value={extraHourCostWithoutSubscription} placeholder={t("extra-hour-cost-without-abo")}/>
                  </div>
                  <div className="Subscription-cost-input">
                    <Form.Label style={{fontSize:16}}>{t("extra-hour-cost-with-abo")}</Form.Label>
                    <Form.Control type="number" onChange={(e) => setExtraHourCostWithSubscription(e.target.value)}
                                  value={extraHourCostWithSubscription} placeholder={t("extra-hour-cost-with-abo")}/>
                  </div>
                </div>
                <div className="Subscription-cost-input">
                  <Form.Label style={{fontSize:16}}>{t("subscription-price")}</Form.Label>
                  <Form.Control type="number" onChange={(e) => setSubscriptionPrice(e.target.value)}
                                value={subscriptionPrice} placeholder={t("subscription-pricer")}/>
                </div>
              </Form>
              <div className="App-button" style={{justifyContent:'flex-start', marginLeft: '20px'}}>
                <Button variant="danger" onClick={() => save()}>
                  <FontAwesomeIcon icon={faSave} style={{marginRight: '10px'}}/>
                  {t("save")}
                </Button>
              </div>
            </Tab>
            <Tab eventKey="e-bike-45" title={t("e-bike-45")}>
              <div className="App-button">
                <Button variant="danger" onClick={() => setShowAddPriceLevel(true)}>
                  <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
                  {t("add-price-level")}
                </Button>
              </div>
              <div className="App-table" style={{height: '30vh'}}>
                <div className="table-responsive">
                  <table className="table" id="project-table">
                    <thead>{tableHead}</thead>
                    <tbody>{tableBody}</tbody>
                  </table>
                </div>
              </div>
              <Form style={{display:"flex", flexDirection:'column', marginBottom: '10px'}}>
                <div style={{display:"flex", flexDirection:'row'}}>
                  <div className="Subscription-cost-input">
                    <Form.Label style={{fontSize:16}}>{t("extra-hour-cost-without-abo")}</Form.Label>
                    <Form.Control type="number" onChange={(e) => setExtraHourCostWithoutSubscription(e.target.value)}
                                  value={extraHourCostWithoutSubscription} placeholder={t("extra-hour-cost-without-abo")}/>
                  </div>
                  <div className="Subscription-cost-input">
                    <Form.Label style={{fontSize:16}}>{t("extra-hour-cost-with-abo")}</Form.Label>
                    <Form.Control type="number" onChange={(e) => setExtraHourCostWithSubscription(e.target.value)}
                                  value={extraHourCostWithSubscription} placeholder={t("extra-hour-cost-with-abo")}/>
                  </div>
                </div>
                <div className="Subscription-cost-input">
                  <Form.Label style={{fontSize:16}}>{t("subscription-price")}</Form.Label>
                  <Form.Control type="number" onChange={(e) => setSubscriptionPrice(e.target.value)}
                                value={subscriptionPrice} placeholder={t("subscription-price")}/>
                </div>
              </Form>
              <div className="App-button" style={{justifyContent:'flex-start', marginLeft: '20px'}}>
                <Button variant="danger" onClick={() => save()}>
                  <FontAwesomeIcon icon={faSave} style={{marginRight: '10px'}}/>
                  {t("save")}
                </Button>
              </div>
            </Tab>
            <Tab eventKey="cargo-bike" title={t("cargo-bike")}>
              <div className="App-button">
                <Button variant="danger" onClick={() => setShowAddPriceLevel(true)}>
                  <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
                  {t("add-price-level")}
                </Button>
              </div>
              <div className="App-table" style={{height: '30vh'}}>
                <div className="table-responsive">
                  <table className="table" id="project-table">
                    <thead>{tableHead}</thead>
                    <tbody>{tableBody}</tbody>
                  </table>
                </div>
              </div>
              <Form style={{display:"flex", flexDirection:'column', marginBottom: '10px'}}>
                <div style={{display:"flex", flexDirection:'row'}}>
                  <div className="Subscription-cost-input">
                    <Form.Label style={{fontSize:16}}>{t("extra-hour-cost-without-abo")}</Form.Label>
                    <Form.Control type="number" onChange={(e) => setExtraHourCostWithoutSubscription(e.target.value)}
                                  value={extraHourCostWithoutSubscription} placeholder={t("extra-hour-cost-without-abo")}/>
                  </div>
                  <div className="Subscription-cost-input">
                    <Form.Label style={{fontSize:16}}>{t("extra-hour-cost-with-abo")}</Form.Label>
                    <Form.Control type="number" onChange={(e) => setExtraHourCostWithSubscription(e.target.value)}
                                  value={extraHourCostWithSubscription} placeholder={t("extra-hour-cost-with-abo")}/>
                  </div>
                </div>
                <div className="Subscription-cost-input">
                  <Form.Label style={{fontSize:16}}>{t("subscription-price")}</Form.Label>
                  <Form.Control type="number" onChange={(e) => setSubscriptionPrice(e.target.value)}
                                value={subscriptionPrice} placeholder={t("subscription-price")}/>
                </div>
              </Form>
              <div className="App-button" style={{justifyContent:'flex-start', marginLeft: '20px'}}>
                <Button variant="danger" onClick={() => save()}>
                  <FontAwesomeIcon icon={faSave} style={{marginRight: '10px'}}/>
                  {t("save")}
                </Button>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default Pricing;