import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import "../App.css";
import TouchableOpacity from "../elements/TouchableOpacity";
import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut
} from "@firebase/auth";
import {collection, doc, getDoc, getDocs, getFirestore, query, setDoc, where} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {RouteComponentProps, useHistory} from "react-router";
import BasicModal from "../elements/BasicModal";
import {useTranslation} from "react-i18next";
import {getMainLogo} from "../utils/MainLogo";

type RegisterProps = {
  setLoggedIn: (args: boolean) => void;
  setLoading: (args: boolean) => void;
} & RouteComponentProps<Empty>

export default function Register(props:RegisterProps) {

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [sender, setSender] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showAccountExistsAlready, setShowAccountExistsAlready] = useState(false)
  const history = useHistory();

  const isUserActive = async (userEmail: string) => {
    const db = getFirestore();
    const timestamp = (new Date()).getTime();
    let operatorUserId;

    const queryOperators = await query(collection(db, "operatorUser"),
      where("email", "==", userEmail));
    const querySnapshot = await getDocs(queryOperators);

    const result = await querySnapshot.forEach( (res) => {
      if (res.data().active) {
        operatorUserId = res.id
      }
    });

    if (operatorUserId) {
      const ref = doc(db, 'operatorUser', operatorUserId);
      const snap = await getDoc(ref);
      const operatorUserData = snap.data();

      if (operatorUserData && operatorUserData.active) {
        await setDoc(ref, {firstName: firstName, lastName: lastName, lastLogin: timestamp}, {merge: true})
        return  true;
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    const isValid = validateForm();
    const auth = getAuth();
    event.preventDefault();

    if (!isValid) {
      alert(t("invalid-info"))
    } else {
      setPersistence(auth, browserSessionPersistence)
        .then(() => {

          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              props.setLoading(true)

              if (user.email) {
                isUserActive(user.email)
                  .then((isActive) => {
                    if (isActive) {
                      props.setLoggedIn(true)
                      history.push('/dashboard/trips')
                      props.setLoading(false)
                      if (user.email) {
                        sessionStorage.setItem("userEmail", user.email);
                      }
                      window.location.reload();
                    } else {
                      signOut(auth).then(() => {
                        props.setLoggedIn(false)
                        props.setLoading(false)
                        setTimeout(() => alert(t("deactivated-invitation")),300)

                      }).catch((error) => {
                        console.log(error)
                      });
                    }
                  });
              }
              props.setLoading(false)

            })
            .catch((error) => {
              const errorCode = error.code;

              switch (errorCode) {
                case 'auth/email-already-in-use': {
                  signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    props.setLoading(true)

                    if (user.email) {
                      isUserActive(user.email)
                        .then((isActive) => {
                          if (isActive) {
                            props.setLoggedIn(true)
                            history.push('/dashboard/trips')
                            props.setLoading(false)
                            if (user.email) {
                              sessionStorage.setItem("userEmail", user.email);
                            }
                            window.location.reload();
                          } else {
                            signOut(auth).then(() => {
                              props.setLoggedIn(false)
                              props.setLoading(false)
                              setTimeout(() => alert(t("deactivated-invitation")),300)

                            }).catch((error) => {
                              console.log(error)
                            });
                          }
                        });
                    }
                    props.setLoading(false)
                  });
                }
                  break;
                default: {
                  setShowAccountExistsAlready(true)
                }
              }
            });
        })
    }
  }

  const loadAccountInvite = async (id: string) => {
    const db = getFirestore();
    const ref = doc(db, 'accountInvite', id);
    const snap = await getDoc(ref);
    const accountInvite = snap.data();

    setEmail(accountInvite?.recipient);
    setSender(accountInvite?.sender);
    setOperatorName(accountInvite?.operatorName);
  }

  useEffect(() => {
    const searchParam = "?account_invite=";
    const str = props.location.search.toString();
    const id = str.substring(searchParam.length);

    loadAccountInvite(id)
      .then(() => {
        console.log("done")
      })
  }, [])

  const handleModalClose = () => {
    setShowAccountExistsAlready(false);
  }

  const handleModalConfirm = () => {
    setShowAccountExistsAlready(false)
    history.push('/login');
  }

  return (
    <div className="register">
      <BasicModal show={showAccountExistsAlready} title={t("account-already-exists")} body={t("account-already-exists-text")}
                  handleClose={handleModalClose} handleConfirm={handleModalConfirm} showCancel={true}/>
      <div className="login-box">
        <div className="login-logo">
          <img
            src={getMainLogo()}
            height="40"
            className="d-inline-block align-top"
            alt="logo"
          />
        </div>
        <div className="login-title">
          {t("register")}
        </div>
        <div className="register-text">
          {t("invited-by")}<b>{sender}</b>{t("to-join")}<b>{operatorName}</b>
          {t("enter-info")}
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="firstName">
            <Form.Label>{t("first-name")}</Form.Label>
            <Form.Control
              autoFocus
              type="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
            <Form.Group className="mb-3" controlId="lastName">
              <Form.Label>{t("last-name")}</Form.Label>
              <Form.Control
                type="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>{t("email")}</Form.Label>
            <Form.Control
              disabled
              value={email}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>{t("password")}
              <TouchableOpacity className="table-button" onClick={() => setShowPassword(!showPassword)}>
                <FontAwesomeIcon icon={faEye}/>
              </TouchableOpacity>
            </Form.Label>
            <Form.Control
              type={showPassword ? "text": "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <TouchableOpacity className="login-button" type="submit">
            {t("register")}
          </TouchableOpacity>
        </Form>
      </div>
    </div>
  );
}