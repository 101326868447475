import React, {useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {getFirestore} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type AddDomainModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: (domain: string) => void;
  showCancel: boolean;
  projectId: string,
}

export default function AddDomainModal(props:AddDomainModalProps) {

  const { t } = useTranslation();
  const [domain, setDomain] = useState("");
  const [displayDomainError, setDisplayDomainError] = useState(false);
  const [domainError, setDomainError] = useState("");

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (domain === "" ) {
      setDomainError(t("valid-domain"));
      setDisplayDomainError(true)
      setTimeout(() => setDisplayDomainError(false), 5000)
    }

    if (domain !== "") {
      props.handleConfirm(domain);
      cleanUp()
    }
  }

  const cleanUp = () => {
    setDomain("")
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={validate}>
        <Modal.Body>
          <Form.Group>
            <div>
              <Form.Label>{props.body}</Form.Label>
            </div>
            <div>
              <Form.Label>{t('email-domain')}</Form.Label>
              <Form.Control autoFocus type="name" onChange={(e) => setDomain(e.target.value)} value={domain}
                            placeholder={t("email-domain-example")}/>
            </div>
            <div style={{color: displayDomainError ? "red": "transparent", marginBottom:'10px'}}>
              {domainError}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {props.showCancel && <Button variant="dark" onClick={handleClose}>
            {t("cancel")}
          </Button>}
          <Button variant="danger" type={'submit'}>
            {t("ok")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )

}


