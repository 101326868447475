import React, {useEffect, useState} from 'react'
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import {collection, doc, getDocs, getFirestore, orderBy, query,} from "@firebase/firestore";
import ApexOptions = ApexCharts.ApexOptions;

type TimeseriesChartProps = {
  show: boolean,
  lockId: string,
  type: string,
  handleClose: () => void;
}

export default function TimeseriesChart(props:TimeseriesChartProps) {

  const initSeries : ApexAxisChartSeries = [];
  const initOptions: ApexOptions = {};
  const [series, setSeries] = useState(initSeries);
  const [options, setOptions] = useState(initOptions);
  const [maxValue, setMaxValue] = useState(100);
  const { t } = useTranslation();

  const loadData = async () => {
    const db = getFirestore();

    const ref = doc(db, "locks", props.lockId);
    const querySnapshot = await getDocs(query(collection(ref,"history"),
      orderBy("timestamp", "asc")))

    const timeseries = [];
    for (const dataPoint of querySnapshot.docs) {
      let value = dataPoint.get(props.type);
      if (value !== undefined) {
        if (typeof value == "boolean") {
          value = (value) ? 1 : 0;
        }

        timeseries.push({
          x: dataPoint.get('timestamp'),
          y: value,
        })
      }
    }

    setSeries([{name:props.type, data: timeseries}])
  }

  useEffect(() => {
    const options: ApexOptions = {
      annotations: {},
      chart: {
        type: "area",
      },
      dataLabels: {
        enabled: false
      },
      series: [{data: [],},],
      markers: {},
      xaxis: {
        type: 'datetime',
        min: Date.now()-6*30*24*60*60*1000, // 6 months in the past
        tickAmount: 6,
        labels: {
          datetimeUTC:false
        }
      },
      yaxis: {
        min: 0,
        max: maxValue,
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy hh:mm'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.2,
          opacityTo: 0.5,
          stops: [0, maxValue]
        }
      },
    };

    setOptions(options);
  }, [maxValue])

  useEffect(() => {
    loadData();

    switch (props.type) {
      case "battery":
      case "humidity":
        setMaxValue(100);
        break;
      case "temperature":
        setMaxValue(50);
        break;
      case "isOpen":
      case "theft":
        setMaxValue(2);
        break;
      default:
        setMaxValue(100);
        break;
    }
  }, [props.lockId, props.type])


  return (
    <Modal show={props.show} onHide={props.handleClose} size={"lg"} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t(props.type).toUpperCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="app">
          <div className="row">
            <div className="mixed-chart">
              <Chart
                options={options}
                series={series}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}