import React, {useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {doc, getDoc, getFirestore} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

type ReservationDetailsModalProps = {
  show: boolean,
  reservationId: string,
  handleClose: () => void;
}

export default function ReservationDetailsModal(props:ReservationDetailsModalProps) {

  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [active, setActive] = useState(true);
  const [user, setUser] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [start, setStart] = useState(0);
  const [stop, setStop] = useState(0);
  const [station, setStation] = useState("");
  const [discount, setDiscount] = useState(0);
  const { t } = useTranslation();

  const loadReservationData = (reservationId:string) => {
    const db = getFirestore();
    setLoading(true);
    getDoc(doc(db, 'reservations', reservationId))
      .then(async (documentData) => {
        const data = documentData.data();
        if (data) {
          const vehicle = await getDoc(doc(db, "vehicles", data.vehicleId));
          setUser(data.userId.substring(0,5));
          setVehicle(vehicle.get('name'));
          setConfirmed(data.confirmed);
          setActive(data.active);
          setStart(data.start);
          setStop(data.stop);
          setStation(data.station);
          setDiscount(data.discount ? data.discount : 0);
        }
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (props.show) {
      loadReservationData(props.reservationId);
    }
  },[props.reservationId])

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("reservation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("reservation-info")}</Modal.Body>
      {!loading && <Modal.Body>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('user')}{":"}</div>
          <div className={"modal-item left"}>{user}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('vehicle')}{":"}</div>
          <div className={"modal-item left"}>{vehicle}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('station')}{":"}</div>
          <div className={"modal-item left"}>{station}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('start')}{":"}</div>
          <div className={"modal-item left"}>{(new Date(start)).toLocaleDateString()}{" - "}{(new Date(start)).toLocaleTimeString()}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('end')}{":"}</div>
          <div className={"modal-item left"}>{(new Date(stop)).toLocaleDateString()}{" - "}{(new Date(stop)).toLocaleTimeString()}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('confirmed')}{":"}</div>
          <div className={"modal-item left"}>
            {(confirmed && active) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> :
            <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('discount')}{":"}</div>
          <div className={"modal-item left"}>{discount}{"%"}</div>
        </div>
      </Modal.Body>}
      <Modal.Footer>
        <Button variant="danger" onClick={props.handleClose}>
          {t("ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  )

}