import React, {useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle, faUser} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button, FormCheck, ListGroup, Tab, Tabs} from "react-bootstrap";
import {Navigation} from "react-minimal-side-navigation";
import MapComponent from "../components/MapComponent";
import {getDurationFormat} from "../utils/DateTimeUtil";

type UsersDetailsProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const UsersDetails = (props:UsersDetailsProps) => {

  const {t} = useTranslation();
  const initRows: SideMenu[] = [];
  const initTripRows: DocumentData[] = [];
  const init : DocumentData  = {};
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(props.match.params.id);
  const [rows, setRows] = useState(initRows);
  const [tripRows, setTripRows] = useState(initTripRows);
  const [reservationRows, setReservationRows] = useState(initTripRows);
  const [userData, setUserData] = useState(init);
  const [key, setKey] = useState("trips");
  const [userId, setUserId] = useState("");
  const [vehicleMap, setVehicleMap] = useState(new Map());
  const [position, setPosition] = useState({latitude:0,longitude:0});
  const history = useHistory();

  const isSuperAdmin = (props.userData.role === 'super-admin' || props.userData.role === 'staff');
  const isAdmin = (props.userData.role === 'admin') || isSuperAdmin;

  const loadUsers = async () => {
    const db = getFirestore();
    const id = props.match.params.id;
    const projectType = props.projectMap.get(id)?.type;
    const clientId = sessionStorage.getItem("clientId");

    let queryUsers;
    setRows(initRows);

    const queryVehicles = await query(collection(db, "vehicles"),
      where("projectId", "==", id), where("active", "==", true));

    const vehicleSnapshot = await getDocs(queryVehicles);
    const vehicleMapTemp = new Map();
    for (const vehicle of vehicleSnapshot.docs) {
      vehicleMapTemp.set(vehicle.id, {id: vehicle.id, ...vehicle.data()});
    }
    setVehicleMap(vehicleMapTemp);

    if (projectType == 'corporate') {
      queryUsers = await query(collection(db, "users"),
          where("projectId", "==", id),
          orderBy("registrationDate", "desc"), limit(500));
    } else { // tourism project
      queryUsers = await query(collection(db, "users"),
          where("isGlobal", "==", true),
          where("clientId", "==", clientId),
          orderBy("registrationDate", "desc"), limit(1000));
    }

    const querySnapshot = await getDocs(queryUsers);

    for (const user of querySnapshot.docs) {
      const userData = user.data();
      rows.push(
          {
            title: user.id.substring(0,5),
            itemId: user.id,
            elemBefore: () => <div><FontAwesomeIcon icon={faUser}/></div>,
          })
    }
    return 0;
  }

  const loadUserTrips = async (userId: string) => {

    const db = getFirestore();
    const id = props.match.params.id;

    const user = await getDoc(doc(db, "users", userId));
    const data = user.data();
    if (user) {
      setUserData({id:user.id, ...data});
    }

    const queryOperators = await query(collection(db, "trips"),
      where("projectId", "==", id), where("userId", "==", userId),
      orderBy("start", "desc"), limit(500));
    const querySnapshot = await getDocs(queryOperators);
    const localRows: DocumentData[] = [];

    for (const trip of querySnapshot.docs) {
      localRows.push({'id': trip.id, ...trip.data()})
      if (trip.get('startPosition') != null) {
        setPosition(trip.get('startPosition'))
      }
    }

    setTripRows(localRows);
    return 0;
  }

  const tripTableHead = <tr>
    <th>{t("date")}</th>
    <th>{t("user")}</th>
    <th>{t("vehicle")}</th>
    <th>{t("start-station")}</th>
    <th>{t("start")}</th>
    <th>{t("end")}</th>
    <th>{t("duration")}</th>
    <th>{t("stop-station")}</th>
  </tr>

  const tripTableBody = tripRows.map((value: DocumentData) => {
    const duration = (value.stop) ? getDurationFormat(value.stop - value.start, t('day')) : ""
    const user = (value.userId).substring(0, 5);
    const startDate = new Date(value.start).toLocaleDateString();
    const startTime = new Date(value.start).toLocaleTimeString();
    const stopDate = (value.stop) ? new Date(value.stop).toLocaleDateString() : "-";
    const stopTime = (value.stop) ? new Date(value.stop).toLocaleTimeString() : "";
    const startStation = value.startStation;
    const stopStation = value.stopStation;
    const vehicleName = vehicleMap.has(value.vehicleId) ? vehicleMap.get(value.vehicleId).name : "";
    const vehicleUrl = '/projects/'+props.match.params.id+'/vehicles/details?id='+value.vehicleId;

    return (
      <tr key={value.id} style={{alignItems: 'center'}}>
        <td>{startDate}</td>
        <td>{user}</td>
        <td><Button variant={'link'} size={'sm'} onClick={() => history.push(vehicleUrl)}>
          {vehicleName}</Button></td>
        <td>{startStation}</td>
        <td>{startDate}{<br/>}{startTime}</td>
        <td>{stopDate}{<br/>}{stopTime}</td>
        <td>{duration}</td>
        <td>{stopStation}</td>
      </tr>)
  })

  const loadUserReservations = async (userId: string) => {
    const db = getFirestore();
    const id = props.match.params.id;

    const queryOperators = await query(collection(db, "reservations"),
      where("projectId", "==", id), where("userId", "==", userId),
      where("confirmed", "==", true), orderBy("stop", "desc"),
      limit(500));
    const querySnapshot = await getDocs(queryOperators);
    const localRows: DocumentData[] = [];

    for (const reservation of querySnapshot.docs) {
      localRows.push({'id': reservation.id, ...reservation.data()})
    }

    setReservationRows(localRows);
    return 0;
  }

  const reservationTableHead = <tr>
    <th>{t("date")}</th>
    <th>{t("user")}</th>
    <th>{t("vehicle")}</th>
    <th>{t("station")}</th>
    <th>{t('confirmed')}</th>
    <th>{t("start")}</th>
    <th>{t("end")}</th>
    <th>{t("duration")}</th>
    <th>{t('discount')}</th>
  </tr>

  const reservationTableBody = reservationRows.map((value: DocumentData) => {
    const user = (value.userId).substring(0,5);
    const date = new Date(value.start).toLocaleDateString();
    const vehicleName = vehicleMap.has(value.vehicleId) ? vehicleMap.get(value.vehicleId).name : "";
    const vehicleUrl = '/projects/'+props.match.params.id+'/vehicles/details?id='+value.vehicleId;
    const station = value.station;
    const confirmed = (value.confirmed && value.active) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> :
      (value.confirmed && !value.active) ? <FontAwesomeIcon icon={faTimesCircle} color={"red"}/> : ""
    const startDate = new Date(value.start).toLocaleDateString();
    const startTime = new Date(value.start).toLocaleTimeString();
    const stopDate = (value.stop) ? new Date(value.stop).toLocaleDateString() : "-";
    const stopTime = (value.stop) ? new Date(value.stop).toLocaleTimeString() : "";
    const duration = (value.stop) ? getDurationFormat(value.stop - value.start, t('day')) : ""
    const discount = value.discount;

    return (
      <tr key={value.id} style={{alignItems: 'center'}}>
        <td>{date}</td>
        <td>{user}</td>
        <td><Button variant={'link'} size={'sm'} onClick={() => history.push(vehicleUrl)}>
          {vehicleName}</Button></td>
        <td>{station}</td>
        <td>{confirmed}</td>
        <td>{startDate}{<br/>}{startTime}</td>
        <td>{stopDate}{<br/>}{stopTime}</td>
        <td>{duration}</td>
        <td>{discount}</td>
      </tr>)
  })

  const switchHasPayment = (userId: string, hasPayment : boolean) => {
    const db = getFirestore();
    setDoc(doc(db, "users", userId), {
      hasPaymentMethod: !hasPayment
    }, {merge: true})
      .then(() => {
        loadUserTrips(userId);
      });
  }

  useEffect(() => {
    const searchParam = "?id=";
    const str = props.location.search.toString();
    const userId = str.substring(searchParam.length);

    setLoading(true);
    loadUsers()
      .finally(() => {
        if (userId != "") {
          loadUserTrips(userId)
            .finally(() => {
              loadUserReservations(userId)
                .finally(() => {
                  setUserId(userId)
                  setLoading(false)
                })
            })
        } else {
          setLoading(false)
        }
      })
  }, [])

  return (
      <div className="Dashboard">
        <LoadingElement loading={loading}/>
        <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                            projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
        <div className="App-header">
          <div className="App-side-menu">
            <div className="App-side-menu-title">
              {t("users")}
            </div>
            <div className="App-side-menu-body">
              <React.Fragment>
                <Navigation
                    // you can use your own router's api to get pathname
                    activeItemId={props.location.search.substr(4)}
                    onSelect={({itemId}) => {
                      if (projectId) {
                        setLoading(false);
                        setUserId(itemId);
                        const url = '/projects/' + projectId + '/users/details?id=' + itemId;
                        history.push(url);
                        loadUserTrips(itemId)
                          .finally(() => {
                            loadUserReservations(itemId)
                              .finally(() => {
                                setLoading(false)
                              })
                          })
                      }
                    }}
                    items={rows}
                />
              </React.Fragment>
            </div>
          </div>
          {userId != "" && <div className="App-box-small">
            <div className="App-box-upper">
              <div className="App-list-group">
                <ListGroup>
                  <ListGroup.Item>
                    <div className="App-list-group-item">
                      <div className="App-list-group-item-1"> {t('email')}{": "}</div>
                      <div className="App-list-group-item-2">
                        {(isAdmin) ? userData.email : t("hidden")}
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="App-list-group-item">
                      <div className="App-list-group-item-1"> {t('phone')}{": "}</div>
                      <div className="App-list-group-item-2">
                        {(isAdmin) ? userData.phone : t("hidden")}
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <div className="App-list-group-item">
                      <div className="App-list-group-item-1"> {t('name')}{": "}</div>
                      <div className="App-list-group-item-2">
                        {(isAdmin) ? `${userData.firstName} ${userData.lastName}` : t("hidden")}
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <div className="App-list-group-item">
                      <div className="App-list-group-item-1"> {t('address')}{": "}</div>
                      <div className="App-list-group-item-2">
                        {(isAdmin) ? userData.address : t("hidden")}
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <div className="App-list-group-item">
                      <div className="App-list-group-item-1"> {t('city')}{": "}</div>
                      <div className="App-list-group-item-2">
                        {(isAdmin) ? `${userData.zip} ${userData.city}` : t("hidden")}
                      </div>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('registration-date')}{": "}</div>
                    <div className="App-list-group-item-2">
                      {new Date(userData.registrationDate).toLocaleDateString()}{" "}
                      {new Date(userData.registrationDate).toLocaleTimeString()}
                    </div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('payment')}{": "}</div>
                    <div className="App-list-group-item-2">
                      <div className="switch-button-item">
                        <div className="switch-button-item">
                          <FormCheck type={"switch"} checked={userData.hasPaymentMethod}
                                     onChange={() => switchHasPayment(userId, userData.hasPaymentMethod)}/>
                          {(userData.hasPaymentMethod) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
                        </div>
                      </div>
                    </div></div></ListGroup.Item>
                </ListGroup>
              </div>
              <div className="App-map">
                <MapComponent markers={[{id: 1, type:'vehicle', position:{'lat':position?.latitude, 'lng':position?.longitude }}]}/>
              </div>
            </div>
            <Tabs defaultActiveKey="current" id="uncontrolled-tab-example" className="mb-3" transition={true}
                  style={{marginLeft: '40px', marginTop: '10px'}}
                  activeKey={key}
                  onSelect={(k) => {return k ? setKey(k.toString()) : setKey("")}}>
              <Tab eventKey="trips" title={t('trips')}>
                <div className="App-table" style={{height: '40vh'}}>
                  <div className="table-responsive" style={{height: '40vh'}}>
                    <table className="table" id="project-table">
                      <thead>{tripTableHead}</thead>
                      <tbody>{tripTableBody}</tbody>
                    </table>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="reservations" title={t('reservations')}>
                <div className="App-table" style={{height: '40vh'}}>
                  <div className="table-responsive" style={{height: '40vh'}}>
                    <table className="table" id="project-table">
                      <thead>{reservationTableHead}</thead>
                      <tbody>{reservationTableBody}</tbody>
                    </table>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>}
          {userId == "" && <div className="App-box-small-empty">
          </div>}
        </div>
      </div>
  )

}

export default UsersDetails;