import React, {useEffect, useState} from 'react';
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {doc, DocumentData, getFirestore, setDoc} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {NavSidebarOperators} from "../components/NavSidebarOperators";
import BasicModal from "../elements/BasicModal";
import {Button, Form} from "react-bootstrap";

type SettingsOperatorProps = {
  operatorMap: Map<string, DocumentData>
  userData: Record<string, unknown>;
} & RouteComponentProps<Identifier>

const SettingsOperator = (props:SettingsOperatorProps) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showDeleteOperatorModal, setShowDeleteOperatorModal] = useState(false);
  const [showSaveSettingsModal, setShowSaveSettingModal] = useState(false);
  const [name, setName] = useState("");
  const history = useHistory();

  const confirmDeleteOperator = () => {
    const db = getFirestore();
    const id = props.match.params.id;
    const ref = doc(db, "operators", id);
    setShowDeleteOperatorModal(false);
    setLoading(true);
    setDoc(ref, {active: false}, {merge: true})
      .then(() => {
        console.log("done")
        history.push('/');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {

        setLoading(false);
      })
  }

  const confirmSaveSettings = () => {
    const db = getFirestore();
    const id = props.match.params.id;
    const ref = doc(db, "operators", id);
    setShowSaveSettingModal(false);
    setLoading(true);

    setDoc(ref, {
      name: name,
    }, {merge: true})
      .then(() => {
        console.log("done")
        history.push('/operators/'+id+'/settings');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    const id = props.match.params.id;
    const operator = props.operatorMap.get(id);
    if (operator) {
      setName(operator.name)
    }
  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarOperators name={props.operatorMap.get(props.match.params.id)?.name} {...props}/>
      <BasicModal show={showSaveSettingsModal} title={t('save-operator-settings')}
                  body={t('save-operator-settings-body')}
                  handleClose={() => {setShowSaveSettingModal(false)}}
                  handleConfirm={confirmSaveSettings} showCancel={true}/>
      <BasicModal show={showDeleteOperatorModal} title={t('delete-operator-title')}
                  body={t('delete-settings-confirmation-body')}
                  handleClose={() => setShowDeleteOperatorModal(false)}
                  handleConfirm={confirmDeleteOperator} showCancel={true}/>
      <div className="App-header">
        <div className="App-box">
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr'}}>
            <div style={{borderRight:'lightgrey 1px solid'}}>
              <div className="App-title">
                {t("settings")}
              </div>
              <Form>
                <div className="Settings-input">
                  <Form.Label style={{fontSize:16}}>{t("operator-name")}</Form.Label>
                  <Form.Control type="name" onChange={(e) => setName(e.target.value)}
                                value={name} placeholder={t("operator-placeholder")}/>
                </div>
              </Form>
              <div className="Settings-button">
                <Button variant="danger" onClick={() => setShowSaveSettingModal(true)}>
                  <FontAwesomeIcon icon={faSave} style={{marginRight: '10px'}}/>
                  {t("save-operator-settings")}
                </Button>
              </div>
            </div>
            <div>
              <div className="App-title">
                {t("delete-operator-title")}
              </div>
              <div className="App-sub-title">
                {t("delete-operator-body")}
              </div>
              <div className="Settings-button">
                <Button variant="danger" onClick={() => setShowDeleteOperatorModal(true)}>
                  <FontAwesomeIcon icon={faTrashAlt} style={{marginRight: '10px'}}/>
                  {t("delete-operator")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsOperator;