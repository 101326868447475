import React, {useEffect, useState} from 'react';
import LoadingElement from "../elements/LoadingElement";
import {NavSidebarDashboard} from "../components/NavSidebarDashboard";
import {RouteComponentProps} from "react-router";
import {useTranslation} from "react-i18next";
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {getDurationFormat} from "../utils/DateTimeUtil";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import TerminateTripModal from "../elements/TerminateTripModal";

type TripsOverviewProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>;
} & RouteComponentProps<Empty>

const TripsOverview = (props: TripsOverviewProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = []
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(initRows);
  const [showTerminateTrip, setShowTerminateTrip] = useState(false);
  const [reservationId, setReservationId] = useState("");
  const [tripId, setTripId] = useState("");
  const [tripStart, setTripStart] = useState(0);
  const history = useHistory();

  const loadTrips = async () => {
    setLoading(true);
    const db = getFirestore();
    const clientId = sessionStorage.getItem("clientId");
    const vehicleMap = new Map();

    const queryVehicles = await query(collection(db, "vehicles"),
      where("clientId", "==", clientId));
    const snapshotVehicles = await getDocs(queryVehicles);

    for (const vehicle of snapshotVehicles.docs) {
      vehicleMap.set(vehicle.id, vehicle.data());
    }

    const isStaff = props.userData.role === 'staff';
    const isSuperAdmin = props.userData.role === 'super-admin';
    const projects = []

    if (isSuperAdmin || isStaff) {
      const subQuery = await query(collection(db, "projects"),
        where("clientId", "==", clientId),
        where("active", "==", true))
      const queryProjects = await getDocs(subQuery);

      for (const query of queryProjects.docs) {
        if (query.id !== "V6NpxwQ1bb2iBE5XVEOa" && query.id !== "wPxwICYG69sesXkLKrUs") {
          projects.push(query.id)
        }
      }

    } else {
      const operatorId = props.userData.operatorId
      const queryPath = 'operators/' + operatorId + '/projects';
      const queryProjects = await getDocs(collection(db, queryPath));

      for (const query of queryProjects.docs) {
        const project =  query.data();
        projects.push(project.id)
      }
    }

    const queryOperators = await query(collection(db, "trips"),
      where("projectId", "in", projects), orderBy("start", "desc"), limit(500));
    const querySnapshot = await getDocs(queryOperators);
    const localRows : DocumentData[] = [];

    for (const trip of querySnapshot.docs) {
      const tripData = trip.data();
      const vehicleData =  (tripData.vehicleId) ? vehicleMap.get(tripData.vehicleId) : undefined;
      const vehicleId = tripData.vehicleId;

      localRows.push({
        'id': trip.id,
        'vehicleId': vehicleId,
        'vehicleName': vehicleData?.name,
        'startStation': tripData?.startStation,
        ...tripData
      })
    }

    setRows(localRows);
    return 0;
  }

  const terminateTrip = (tripData:DocumentData) => {
    setReservationId(tripData.reservationId);
    setTripId(tripData.id);
    setTripStart(tripData.start);
    setShowTerminateTrip(true);
  }

  const confirmStopTime = (stopTime:number) => {
    const db = getFirestore();
    const ref = doc(db, 'trips', tripId);
    setShowTerminateTrip(false);

    setDoc(ref, {stop: stopTime}, {merge: true})
      .then(() => {
        loadTrips()
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  const tableHead = <tr>
    <th>{t("date")}</th>
    <th>{t("project")}</th>
    <th>{t("user")}</th>
    <th>{t("vehicle")}</th>
    <th>{t("start-station")}</th>
    <th>{t("start")}</th>
    <th>{t("end")}</th>
    <th>{t("duration")}</th>
    <th>{t("stop-station")}</th>
    <th>{t("termination")}</th>
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    const duration = (value.stop) ? getDurationFormat(value.stop - value.start, t('day')) : ""
    const user = (value.userId).substring(0,5);
    const vehicleName = value.vehicleName;
    const startStation = value.startStation;
    const stopStation = value.stopStation;
    const startDate = new Date(value.start).toLocaleDateString();
    const startTime = new Date(value.start).toLocaleTimeString();
    const stopDate = (value.stop) ? new Date(value.stop).toLocaleDateString() : "-";
    const stopTime = (value.stop) ? new Date(value.stop).toLocaleTimeString() : "";
    const terminate = (value.stop) ? "" :
      <Button size={'sm'} variant="danger" onClick={() => terminateTrip(value)}>{t("terminate")}</Button>;
    const vehicleUrl = '/projects/'+value.projectId+'/vehicles/details?id='+value.vehicleId;
    const userUrl = '/projects/'+value.projectId+'/users/details?id='+value.userId;
    const projectName = props.projectMap.get(value.projectId)?.name
    const projectUrl = '/projects/'+value.projectId+'/trips/';

    return (
      <tr key={value.id} style={{alignItems:'center'}}>
        <td>{startDate}</td>
        <td><Button variant="link" size={'sm'}
                    onClick={() => history.push(projectUrl)}>{projectName}</Button></td>
        <td><Button variant="link" size={'sm'}
                    onClick={() => history.push(userUrl)}>{user}</Button></td>
        <td><Button variant="link" size={'sm'}
                    onClick={() => history.push(vehicleUrl)}>{vehicleName}</Button></td>
        <td>{startStation}</td>
        <td>{startDate}{<br/>}{startTime}</td>
        <td>{stopDate}{<br/>}{stopTime}</td>
        <td>{duration}</td>
        <td>{stopStation}</td>
        <td>{terminate}</td>
      </tr>)
  })

  useEffect(() => {
    loadTrips()
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarDashboard {...props}/>
      <div className="App-header">
        <TerminateTripModal show={showTerminateTrip} tripId={tripId}
                            reservationId={reservationId} start={tripStart}
                            handleClose={() => setShowTerminateTrip(false)}
                            handleConfirm={confirmStopTime}/>
        <div className="App-box">
          <div className="App-title">
            {t("trips")}
          </div>
          <div className="App-table">
            <div className="table-responsive">
              <table className="table" id="project-table">
                <thead>{tableHead}</thead>
                <tbody>{tableBody}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TripsOverview;