import React, {ChangeEvent, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {addDoc, collection, GeoPoint, getFirestore} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type AddVehicleModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
  projectId: string,

}

export default function AddVehicleModal(props:AddVehicleModalProps) {

  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [displayNameError, setDisplayNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [brand, setBrand] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [boxChecked, setBoxChecked] = useState(new Set());
  const [checkError, setCheckError] = useState("");
  const [displayCheckError, setDisplayCheckError] = useState(false);

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (name == "" ) {
      setNameError(t("valid-name"));
      setDisplayNameError(true)
      setTimeout(() => setDisplayNameError(false), 5000)
    }

    if (boxChecked.size == 0) {
      setCheckError(t("must-select-vehicle-type"));
      setDisplayCheckError(true)
      setTimeout(() => setDisplayCheckError(false), 5000)
    }

    if (name != "" && boxChecked.size != 0) {
      const timestamp = (new Date()).getTime();
      const projectId = props.projectId;
      const type = boxChecked.keys().next().value;
      const clientId = sessionStorage.getItem("clientId");

      await addDoc(collection(db, "vehicles"), {
        active: true,
        available: true,
        brand: brand,
        lastModified: timestamp,
        lockId: "",
        name: name,
        projectId: projectId,
        serialNumber: serialNumber,
        type: type,
        clientId: clientId,
        position: new GeoPoint(0, 0)
      });


      handleConfirm()
    }
  }

  const cleanUp = () => {
    setDisplayCheckError(false);
    setName("")
    setBoxChecked(new Set())
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    const temp = new Set();
    if (e.target.checked) {
      temp.add(e.target.id)
    }
    setBoxChecked(new Set(temp));
  }


  return (
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={validate}>
          <Modal.Body>
            <Form.Group>
              <div>
                <Form.Label>{props.body}</Form.Label>
              </div>
              <div>
                <Form.Label>{t('name')}</Form.Label>
                <Form.Control autoFocus type="name" onChange={(e) => setName(e.target.value)} value={name}
                              placeholder={t("name")}/>
              </div>
              <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px'}}>
                {nameError}
              </div>
              <div>
                <Form.Label>{t('brand')}</Form.Label>
                <Form.Control type="name" onChange={(e) => setBrand(e.target.value)} value={brand}
                              placeholder={t("brand")}/>
              </div>
              <div style={{color: "transparent", marginBottom:'10px'}}>
                {""}
              </div>
              <div>
                <Form.Label>{t('serial-number')}</Form.Label>
                <Form.Control type="name" onChange={(e) => setSerialNumber(e.target.value)} value={serialNumber}
                              placeholder={t("serial-number")}/>
              </div>
              <div style={{color: "transparent", marginBottom:'10px'}}>
                {""}
              </div>
              <div>
                <Form.Label>{t("select-vehicle-type")}</Form.Label>
                <Form.Check
                  style={{fontWeight: 'bold'}}
                  type={"checkbox"}
                  id={"bike"}
                  label={t("bike")}
                  onChange={(e) => handleChange(e)}
                  checked={boxChecked.has('bike')}
                />
                <Form.Check
                  style={{fontWeight: 'bold'}}
                  type={"checkbox"}
                  id={"e-bike"}
                  label={t("e-bike")}
                  onChange={(e) => handleChange(e)}
                  checked={boxChecked.has('e-bike')}
                />
                <Form.Check
                  style={{fontWeight: 'bold'}}
                  type={"checkbox"}
                  id={"cargo-bike"}
                  label={t("cargo-bike")}
                  onChange={(e) => handleChange(e)}
                  checked={boxChecked.has('cargo-bike')}
                />
              </div>
              <div style={{color: displayCheckError ? "red": "transparent", marginBottom:'10px'}}>
                {checkError}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {props.showCancel && <Button variant="dark" onClick={handleClose}>
              {t("cancel")}
            </Button>}
            <Button variant="danger" type={'submit'}>
              {t("ok")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )

}


