import React, {useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {DocumentData} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faWrench} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

type EmailsProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Emails = (props:EmailsProps) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const id = props.match.params.id;

  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                          projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
      <div className="App-header">
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: '30px'}}>
            <FontAwesomeIcon icon={faExclamationTriangle} size={"5x"}/>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <FontAwesomeIcon icon={faWrench} size={"2x"} style={{marginRight: '30px'}}/>
            <h2>{t("under-construction")}</h2>
            <FontAwesomeIcon icon={faWrench} size={"2x"} style={{marginLeft: '30px', transform: 'rotate(270deg)'}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Emails;