import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {collection, doc, getDoc, getDocs, getFirestore, query, setDoc, where} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type EditVehicleModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  vehicle: Vehicle | undefined,
  stations: Station[]
}

export default function EditVehicleModal(props:EditVehicleModalProps) {

  const { t } = useTranslation();
  const [name, setName] = useState(props.vehicle?.name ?? "");
  const [displayNameError, setDisplayNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [brand, setBrand] = useState(props.vehicle?.brand ?? "");
  const [serialNumber, setSerialNumber] = useState(props.vehicle?.serialNumber ?? "");
  const [lockId, setLockId] = useState(props.vehicle?.lockId ?? "");
  const [selectedStation, setSelectedStation] = useState<Station>();

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (name == "" ) {
      setNameError(t("valid-name"));
      setDisplayNameError(true);
      setTimeout(() => setDisplayNameError(false), 5000);
    }

    if (name != "" && props.vehicle) {
      const timestamp = (new Date()).getTime();

      await setDoc(doc(db, "vehicles", props.vehicle.id), {
        brand: brand,
        lastModified: timestamp,
        lockId: lockId,
        name: name,
        serialNumber: serialNumber,
        station: selectedStation?.name,
      }, {merge: true});

      // transfer the vehicle into the new station:
      if (selectedStation && selectedStation.name !== props.vehicle.station) {
        {
          const oldStationRef = query(collection(db, "stations"),
            where("vehicles", "array-contains", props.vehicle.id));
          const oldStationData = await getDocs(oldStationRef);

          for (const stationData of oldStationData.docs) {
            const vehiclesAtOldStation: string[] = stationData.get("vehicles");
            const index = vehiclesAtOldStation.findIndex(val => val === props.vehicle?.id)
            vehiclesAtOldStation.splice(index, 1);

            await setDoc(doc(db, "stations", stationData.id), {
              vehicles: vehiclesAtOldStation
            }, {merge: true});
          }
        }
        {
          const newStationRef = doc(db, "stations", selectedStation.id);
          const newStationData = await getDoc(newStationRef);
          const vehiclesAtNewStation: string[] = newStationData.get("vehicles");

          if (!vehiclesAtNewStation.includes(props.vehicle.id)) {
            vehiclesAtNewStation.push(props.vehicle.id);

            await setDoc(doc(db, "stations", selectedStation.id), {
              vehicles: vehiclesAtNewStation
            }, {merge: true});
          }
        }
      }

      handleConfirm()
    }
  }

  const onSelectNewStation = (event: ChangeEvent<HTMLSelectElement>) => {
    const stationId = event.target.options[event.target.selectedIndex].id;
    const station = {id: stationId, name: event.target.value} as Station;
    setSelectedStation(station);
  }

  const cleanUp = () => {
    setName("");
    setBrand("");
    setLockId("");
    setSerialNumber("");
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  useEffect(() => {
    setName(props.vehicle?.name ?? "");
    setBrand(props.vehicle?.brand ?? "");
    setSerialNumber(props.vehicle?.serialNumber ?? "");
    setLockId(props.vehicle?.lockId ?? "");
    for (const station of props.stations) {
      if (station.name === props.vehicle?.station) {
        setSelectedStation(station);
      }
    }
    if (!selectedStation && props.stations.length > 0) {
      setSelectedStation(props.stations[0])
    }
  }, [props.vehicle])

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={validate}>
        <Modal.Body>
          <Form.Group>
            <div>
              <Form.Label>{props.body}</Form.Label>
            </div>
            <div>
              <Form.Label>{t('name')}</Form.Label>
              <Form.Control autoFocus type="name" onChange={(e) => setName(e.target.value)} value={name}
                            placeholder={t("name")}/>
            </div>
            <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px'}}>
              {nameError}
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label>{t('lock')}</Form.Label>
              <Form.Control type="name" onChange={(e) => setLockId(e.target.value)} value={lockId}
                            placeholder={t("lock")}/>
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label>{t('station')}</Form.Label>
              <Form.Select size={"sm"} aria-label="station" defaultValue={props.vehicle?.station}
                           onChange={(event) => onSelectNewStation(event)}>
                {props.stations.map((station) => {
                    return <option key={station.id} id={station.id} value={station.name}>
                      {station.name}</option>
                })}
              </Form.Select>
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label>{t('brand')}</Form.Label>
              <Form.Control type="name" onChange={(e) => setBrand(e.target.value)} value={brand}
                            placeholder={t("brand")}/>
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label>{t('serial-number')}</Form.Label>
              <Form.Control type="name" onChange={(e) => setSerialNumber(e.target.value)} value={serialNumber}
                            placeholder={t("serial-number")}/>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {<Button variant="dark" onClick={handleClose}>
            {t("cancel")}
          </Button>}
          <Button variant="danger" type={'submit'}>
            {t("ok")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )

}


