import React, {ChangeEvent, useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {doc, DocumentData, getFirestore, setDoc} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import BasicModal from "../elements/BasicModal";
import AddDomainModal from "../elements/AddDomainModal";
import DomainElement from "../elements/DomainElement";
import {toInteger} from "lodash";


type SettingsProjectProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>;
} & RouteComponentProps<Identifier>

const SettingsProject = (props:SettingsProjectProps) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showSaveSettingsModal, setShowSaveSettingModal] = useState(false);
  const [showAddDomainModal, setShowAddDomainModal] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [domains, setDomains] = useState<string[]>([]);
  const [boxChecked, setBoxChecked] = useState(new Set());
  const [timeWindowInDays, setTimeWindowInDays] = useState(0);
  const [maxReservationInTimeWindow, setMaxReservationInTimeWindow] = useState(0);
  const [maxNumberOfVehiclesPerReservation, setMaxNumberOfVehiclesPerReservation] = useState(0);
  const [maxDurationDuringTheWeek, setMaxDurationDuringTheWeek] = useState(0);
  const [maxDurationStartingOnFriday, setMaxDurationStartingOnFriday] = useState(0);
  const [maxDurationStartingOnSaturday, setMaxDurationStartingOnSaturday] = useState(0);
  const [showExtraParameters, setShowExtraParameters] = useState(false);

  const history = useHistory();

  const confirmDeleteProject = () => {
    const db = getFirestore();
    const id = props.match.params.id;
    const ref = doc(db, "projects", id);
    setShowDeleteProjectModal(false);
    setLoading(true);
    setDoc(ref, {active: false}, {merge: true})
      .then(() => {
        console.log("done");
        history.push('/');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const confirmSaveSettings = () => {
    const db = getFirestore();
    const id = props.match.params.id;
    const ref = doc(db, "projects", id);
    setShowSaveSettingModal(false);
    setLoading(true);
    const projectType = boxChecked.keys().next().value;

    setDoc(ref, {
      name: name,
      type: projectType,
      code: code,
      timeWindowInDays: timeWindowInDays,
      maxReservationInTimeWindow: maxReservationInTimeWindow,
      maxNumberOfVehicles: maxNumberOfVehiclesPerReservation,
      maxDurationDuringTheWeek: maxDurationDuringTheWeek,
      maxDurationStartingOnFriday: maxDurationStartingOnFriday,
      maxDurationStartingOnSaturday: maxDurationStartingOnSaturday,
      domains: domains,
    }, {merge: true})
      .then(() => {
        console.log("done")
        history.push('/projects/'+id+'/settings');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    const temp = new Set();
    if (e.target.checked) {
      temp.add(e.target.id)
    }
    setBoxChecked(new Set(temp));
  }

  const onAddDomain = (domain: string) => {
    domains.push(domain);
    setDomains([...domains]);
    setShowAddDomainModal(false)
  }

  const onDeleteDomain = (index: number) => {
    domains.splice(index, 1);
    setDomains([...domains])
  }

  useEffect(() => {
    const id = props.match.params.id;
    const project = props.projectMap.get(id);
    const clientId = sessionStorage.getItem("clientId");
    if (clientId == 'UgjWlKhtBTcY0USIUScv') {
      setShowExtraParameters(true);
    }

    if (project) {
      setName(project.name)
      setCode(project.code)
      setBoxChecked(new Set().add(project.type))
      if (project.domains) {
        setDomains([...project.domains])
      }
      if (project.timeWindowInDays) {
        setTimeWindowInDays(project.timeWindowInDays)
      }
      if (project.maxReservationInTimeWindow) {
        setMaxReservationInTimeWindow(project.maxReservationInTimeWindow)
      }
      if (project.maxNumberOfVehicles) {
        setMaxNumberOfVehiclesPerReservation(project.maxNumberOfVehicles)
      }
      if (project.maxDurationDuringTheWeek) {
        setMaxDurationDuringTheWeek(project.maxDurationDuringTheWeek)
      }
      if (project.maxDurationStartingOnFriday) {
        setMaxDurationStartingOnFriday(project.maxDurationStartingOnFriday)
      }
      if (project.maxDurationStartingOnSaturday) {
        setMaxDurationStartingOnSaturday(project.maxDurationStartingOnSaturday)
      }
    }
  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                          projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
      <BasicModal show={showSaveSettingsModal} title={t('save-project-settings')}
                  body={t('save-project-settings-body')}
                  handleClose={() => {setShowSaveSettingModal(false)}}
                  handleConfirm={confirmSaveSettings} showCancel={true}/>
      <BasicModal show={showDeleteProjectModal} title={t('delete-project-title')}
                  body={t('delete-project-confirmation-body')}
                  handleClose={() => setShowDeleteProjectModal(false)}
                  handleConfirm={confirmDeleteProject} showCancel={true}/>
      <AddDomainModal show={showAddDomainModal} title={t('add-domain-title')}
                      body={t('add-domain-body')}
                      handleClose={() => setShowAddDomainModal(false)}
                      handleConfirm={(domain) => onAddDomain(domain)}
                      showCancel={true}
                      projectId={props.match.params.id}/>
      <div className="App-header">
        <div className="App-box">
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr'}}>
            <div style={{borderRight:'lightgrey 1px solid'}}>
              <div className="App-title">
                {t("settings")}
              </div>
              <Form>
                <div className="Settings-input">
                  <Form.Label style={{fontSize:16}}>{t("project-name")}</Form.Label>
                  <Form.Control type="name" onChange={(e) => setName(e.target.value)}
                                value={name} placeholder={t("project-placeholder")}/>
                </div>
                <div className="Settings-input">
                  <Form.Label style={{fontSize:16}}>{t("project-code")}</Form.Label>
                  <Form.Control type="code" onChange={(e) => setCode(e.target.value)}
                                value={code} placeholder={t("code-placeholder")}/>
                </div>
                <div>
                  <div style={{display: 'flex', flexDirection: 'row', paddingRight: '50px'}}>
                    <div className="Settings-input">
                      <Form.Label style={{fontSize:16}}>{t("project-time-window")}</Form.Label>
                      <Form.Control type="number" onChange={(e) => setTimeWindowInDays(toInteger(e.target.value))}
                                  value={Math.max(0, timeWindowInDays)} placeholder={t("time-window-placeholder")}/>
                    </div>
                    <div className="Settings-input">
                      <Form.Label style={{fontSize:16}}>{t("project-max-reservation-in-time-window")}</Form.Label>
                      <Form.Control type="number" onChange={(e) => setMaxReservationInTimeWindow(toInteger(e.target.value))}
                                  value={Math.max(0, maxReservationInTimeWindow)} placeholder={t("project-max-reservation-in-time-window-placeholder")}/>
                    </div>
                  </div>
                  <div className="Settings-input">
                    <Form.Label style={{fontSize:16}}>{t("project-max-vehicles")}</Form.Label>
                    <Form.Control type="number" onChange={(e) => setMaxNumberOfVehiclesPerReservation(toInteger(e.target.value))}
                                  value={Math.max(0, maxNumberOfVehiclesPerReservation)} placeholder={t("max-vehicles-placeholder")}/>
                  </div>

                  <div style={{display: 'flex', flexDirection: 'row', paddingLeft: '50px', color: 'black', paddingTop: '20px'}}>
                    <Form.Label style={{fontSize:16}}>{t('project-max-duration')}</Form.Label>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', paddingRight: '50px'}}>
                    <div className="Settings-input" style={{marginTop: '5px'}}>
                      <Form.Label style={{fontSize:16}}>{t("project-max-duration-week")}</Form.Label>
                      <Form.Control type="number" onChange={(e) => setMaxDurationDuringTheWeek(toInteger(e.target.value))}
                                    value={Math.max(0, maxDurationDuringTheWeek)} placeholder={t("project-max-duration-week-placeholder")}/>
                    </div>
                    <div className="Settings-input" style={{marginTop: '5px'}}>
                      <Form.Label style={{fontSize:16}}>{t("project-max-duration-friday")}</Form.Label>
                      <Form.Control type="number" onChange={(e) => setMaxDurationStartingOnFriday(toInteger(e.target.value))}
                                    value={Math.max(0, maxDurationStartingOnFriday)} placeholder={t("project-max-duration-friday-placeholder")}/>
                    </div>
                    <div className="Settings-input" style={{marginTop: '5px'}}>
                      <Form.Label style={{fontSize:16}}>{t("project-max-duration-saturday")}</Form.Label>
                      <Form.Control type="number" onChange={(e) => setMaxDurationStartingOnSaturday(toInteger(e.target.value))}
                                    value={Math.max(0, maxDurationStartingOnSaturday)} placeholder={t("project-max-duration-saturday-placeholder")}/>
                    </div>
                  </div>

                </div>
                <div className="Settings-input">
                  <Form.Label style={{fontSize:16}}>{t("select-project-type")}</Form.Label>
                  <div style={{display: 'flex', flexDirection:'row', margin: 'auto'}}>
                    <Form.Check
                      style={{fontWeight: 'bold', fontSize:16, marginRight: 10, marginLeft: 10}}
                      type={"checkbox"}
                      id={"corporate"}
                      label={t("corporate")}
                      onChange={(e) => handleChange(e)}
                      checked={boxChecked.has('corporate')}
                    />
                    <Form.Check
                      style={{fontWeight: 'bold', fontSize:16, marginRight: 10, marginLeft: 10}}
                      type={"checkbox"}
                      id={"tourism"}
                      label={t("tourism")}
                      onChange={(e) => handleChange(e)}
                      checked={boxChecked.has('tourism')}
                    />
                    <Form.Check
                      style={{fontWeight: 'bold', fontSize:16, marginRight: 10, marginLeft: 10}}
                      type={"checkbox"}
                      id={"real-estate"}
                      label={t("real-estate")}
                      onChange={(e) => handleChange(e)}
                      checked={boxChecked.has('real-estate')}
                    />
                  </div>
                </div>
                <div className="Settings-input">
                  <div style={{display: 'flex', flexDirection: 'row', marginBottom: '20px'}}>
                    <div style={{marginRight: 50}}>
                      <Form.Label style={{fontSize:16}}>{t("email-domains")}</Form.Label>
                    </div>
                    <div className="App-button">
                      <Button variant="outline-dark" size={"sm"} onClick={() => setShowAddDomainModal(true)}>
                        {t("add-domain")}
                      </Button>
                    </div>
                  </div>
                  <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                    {domains.map((domain:string, index:number) => {
                      return (<DomainElement key={index} name={domain}
                                             onDelete={() => onDeleteDomain(index)}/>)
                    })}
                  </div>
                </div>
              </Form>
              <div className="Settings-button">
                <Button variant="danger" onClick={() => setShowSaveSettingModal(true)}>
                  <FontAwesomeIcon icon={faSave} style={{marginRight: '10px'}}/>
                  {t("save-project-settings")}
                </Button>
              </div>
            </div>
            <div>
              <div className="App-title">
                {t("delete-project-title")}
              </div>
              <div className="App-sub-title">
                {t("delete-project-body")}
              </div>
              <div className="Settings-button">
                <Button variant="danger" onClick={() => setShowDeleteProjectModal(true)}>
                  <FontAwesomeIcon icon={faTrashAlt} style={{marginRight: '10px'}}/>
                  {t("delete-project")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsProject;