import rentabikeLogo from "../resources/Rent-a-Bike-logo.png";
import phazeLogo from "../resources/Phaze-Bike-logo-inverse.png";
import buggyrentLogo from "../resources/Buggyrent-logo.png";
import airbieLogo from "../resources/AirBie-logo.png";

export function getMainLogo(): string {
  if (origin.includes("rab") || origin.includes("rentabike")) {
    return rentabikeLogo;
  } else if (origin.includes("phaze")) {
    return phazeLogo;
  } else if (origin.includes("buggyrent")) {
    return buggyrentLogo;
  } else if (origin.includes("admin")) {
    return airbieLogo;
  } else {
    return buggyrentLogo;
  }
}

export function setFavicon(): void {
  const favicon = document.getElementById("favicon");
  if (favicon) {
    if ((origin.includes("rab") || origin.includes("rentabike"))) {
      favicon.setAttribute("href", "/favicon-rentabike.ico");
    } else if (origin.includes("phaze")) {
      favicon.setAttribute("href", "/favicon-phaze.ico");
    } else if (origin.includes("buggyrent")) {
      favicon.setAttribute("href", "/favicon-buggyrent.ico")
    } else if (origin.includes("admin")) {
      favicon.setAttribute("href","/favicon.ico");
    } else {
      favicon.setAttribute("href","/favicon.ico");
    }
  }
}

export function setClientId(): void {
  let clientId;
  if ((origin.includes("rab") || origin.includes("rentabike"))) {
    clientId = '5wmBJuWPdzvaStrn08Sq'
  } else if (origin.includes("phaze")) {
    clientId = 'UgjWlKhtBTcY0USIUScv'
  } else if (origin.includes("buggyrent")) {
    clientId = 'XQW3lvQ6UT4CM7Dy8TWq'
  } else if (origin.includes("admin")) {
    clientId = 'LxHlhmpZzT2vgjeGVUcI'
  } else {
    clientId = 'XQW3lvQ6UT4CM7Dy8TWq'
  }
  sessionStorage.setItem('clientId', clientId);

}