import React, {useState} from "react";
import {Form} from "react-bootstrap";
import "../App.css";
import TouchableOpacity from "../elements/TouchableOpacity";
import {browserSessionPersistence, getAuth, sendPasswordResetEmail, setPersistence} from "firebase/auth";
import {useHistory} from "react-router";
import BasicModal from "../elements/BasicModal";
import {useTranslation} from "react-i18next";
import {getMainLogo} from "../utils/MainLogo";

type ResetPasswordProps = {
  setLoggedIn: (args: boolean) => void;
  setLoading: (args: boolean) => void;
}

export default function ResetPassword(props:ResetPasswordProps) {

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [showUserNotFound, setShowUserNotFound] = useState(false);
  const [showEmailSent, setShowEmailSent] = useState(false);

  const history = useHistory();

  const validateForm = () => {
    return email.length > 0;
  }

  const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    const isValid = validateForm();
    const auth = getAuth();
    event.preventDefault();

    if (!isValid) {
      alert(t("invalid-info"))
    } else {
      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          sendPasswordResetEmail(auth, email)
            .then(() => {
              setShowEmailSent(true)
            })
            .catch((error) => {
              const errorCode = error.code;

              switch (errorCode) {
                case 'auth/user-not-found': {
                  setShowUserNotFound(true)
                }
                  break;
                default: {
                  setShowUserNotFound(true)
                }
              }
            })

        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleModalClose = () => {
    setShowUserNotFound(false)
    setShowEmailSent(false)
  }

  const handleModalConfirm = () => {
    setShowEmailSent(false)
    setEmail("")
    history.push('/login');
  }

  return (
    <div className="login">
      <BasicModal show={showUserNotFound} title={t("account-not-exist-title")} body={t("account-not-exist-body")}
                  handleClose={handleModalClose} handleConfirm={handleModalClose} showCancel={false}/>
      <BasicModal show={showEmailSent} title={t("email-sent-title")} body={t("email-sent-body")}
                  handleClose={handleModalClose} handleConfirm={handleModalConfirm} showCancel={true}/>
      <div className="login-box">
        <div className="login-logo">
          <img
            src={getMainLogo()}
            height="40"
            className="d-inline-block align-top"
            alt="logo"
          />
        </div>
        <div className="login-title">
          {t("reset-password-title")}
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>{t("email")}</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <TouchableOpacity className="login-button" type="submit">
            {t("send-email")}
          </TouchableOpacity>
        </Form>
      </div>
    </div>
  );
}