import './App.css';

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import React, {useEffect, useState} from 'react';

import {BrowserRouter, Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import Trips from './screens/Trips'
import {NavTopbar} from "./components/NavTopbar";
import OperatorProjects from "./screens/OperatorProjects";
import Login from "./components/Login";
import {getAuth} from "firebase/auth";
import Dashboard from "./screens/Dashboard";
import OperatorUsers from "./screens/OperatorUsers";
import Actions from "./screens/Actions";
import Reservations from "./screens/Reservations";
import Vehicles from "./screens/Vehicles";
import Stations from "./screens/Stations";
import Users from "./screens/Users";
import Locks from "./screens/Locks";
import Pricing from "./screens/Pricing";
import Emails from "./screens/Emails";
import TripsOverview from "./screens/TripsOverview";
import ActionsOverview from "./screens/ActionsOverview";
import ReservationsOverview from "./screens/ReservationsOverview";
import VehiclesOverview from "./screens/VehiclesOverview";
import StationsOverview from "./screens/StationsOverview";
import UsersOverview from "./screens/UsersOverview";
import LocksOverview from "./screens/LocksOverview";
import {collection, DocumentData, getDocs, getFirestore, query, where} from "@firebase/firestore";
import LoadingElement from "./elements/LoadingElement";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import AdminUsers from "./screens/AdminUsers";
import Discounts from "./screens/Discounts";
import VehiclesDetails from "./screens/VehiclesDetails";
import StationsDetails from "./screens/StationsDetails";
import UsersDetails from "./screens/UsersDetails";
import LocksDetails from "./screens/LocksDetails";
import ConfirmEmail from "./components/ConfirmEmail";
import SettingsProject from "./screens/SettingsProject";
import SettingsOperator from "./screens/SettingsOperator";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDnIn3RFca95VEawIzVkjR_kPQmo3lJiHk",
  authDomain: "mobility-platform-rab.firebaseapp.com",
  projectId: "mobility-platform-rab",
  storageBucket: "mobility-platform-rab.appspot.com",
  messagingSenderId: "752487825576",
  appId: "1:752487825576:web:eedbb4d564dafec2b8029d",
  measurementId: "G-K85CRVQ5MQ"
};


function App() {

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [projectMap, setProjectMap] = useState(new Map());
  const [operatorMap, setOperatorMap] = useState(new Map());
  const [clientMap, setClientMap] = useState(new Map());
  const [userData, setUserData] = useState({});

  const updateProjectMap = (k:string,v:DocumentData) => {
    setProjectMap(new Map(projectMap.set(k,v)));
  };

  const updateOperatorMap = (k:string,v:DocumentData) => {
    setOperatorMap(new Map(operatorMap.set(k,v)));
  };

  const updateClientMap = (k: string, v:DocumentData) => {
    setClientMap(new Map(clientMap.set(k,v)));
  }

  const auth = getAuth();

  const loadData = async () => {
    const db = getFirestore();
    const queryProjects = await getDocs(query(collection(db, "projects"),
      where("active", "==", true)));
    const queryOperators = await getDocs(query(collection(db, "operators"),
      where("active", "==", true)));
    const queryClients = await getDocs(query(collection(db, "clients"),
      where("active", "==", true)));

    queryProjects.forEach((doc) => {
      updateProjectMap(doc.id, doc.data())
    });

    queryOperators.forEach((doc) => {
      updateOperatorMap(doc.id, doc.data())
    });

    queryClients.forEach((doc) => {
      updateClientMap(doc.id, doc.data())
    })

    const userEmail = sessionStorage.getItem("userEmail")

    if (userEmail) {
      const queryUser = await query(collection(db, "operatorUser"),
          where("email", "==", userEmail), where("active", "==", true));
      const querySnapshot = await getDocs(queryUser);
      const result = await querySnapshot.forEach( (res) => {
        setUserData({id: res.id, ...res.data()})
      });
    }

  };

  auth.onAuthStateChanged(function (user) {
    if (user) {
      if (!user.isAnonymous) {
        setLoggedIn(true)
      }
    }
  });

  useEffect(() => {
    setLoading(true)
    loadData()
      .then(() => {
        console.log("done");
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div>
      <div>
        {loading && <LoadingElement loading={loading}/>}
        {!loading &&
        <BrowserRouter>
        {loggedIn && <NavTopbar setLoggedIn={setLoggedIn} projectMap={projectMap} operatorMap={operatorMap}
                                userData={userData}/>}
        <div className="App">
              <Switch>
                <Route exact path="/" render={(props) => {
                  return (loggedIn) ? <Redirect to="/dashboard/trips"/> : <Redirect to="/login"/>
                }}/>
                <Route exact path="/login" render={(props) => {
                  return (loggedIn) ? <Redirect to="/"/> :
                      <Login setLoggedIn={setLoggedIn} setLoading={setLoading} setUserData={setUserData}/>}}/>
                <Route exact path="/register" render={(props) => {
                  return (loggedIn) ? <Redirect to="/"/> :
                    <Register setLoggedIn={setLoggedIn} setLoading={setLoading} {...props}/> }}/>
                <Route exact path="/reset-password" render={(props) => {
                  return (loggedIn) ? <Redirect to="/"/> :
                    <ResetPassword setLoggedIn={setLoggedIn} setLoading={setLoading}/> }}/>
                <Route exact path="/dashboard/" render={(props) => {
                  return (loggedIn) ? <Dashboard {...props}/> : <Redirect to="/"/> }}/>
                <Route exact path="/dashboard/trips" render={(props) => {
                  return (loggedIn) ? <TripsOverview projectMap={projectMap} userData={userData} {...props}/> : <Redirect to="/"/> }}/>
                <Route exact path="/dashboard/actions" render={(props) => {
                  return (loggedIn) ? <ActionsOverview {...props}/> : <Redirect to="/"/> }}/>
                <Route exact path="/dashboard/reservations" render={(props) => {
                  return (loggedIn) ? <ReservationsOverview {...props}/> : <Redirect to="/"/> }}/>
                <Route exact path="/dashboard/vehicles" render={(props) => {
                  return (loggedIn) ? <VehiclesOverview {...props}/> : <Redirect to="/"/> }}/>
                <Route exact path="/dashboard/stations" render={(props) => {
                  return (loggedIn) ? <StationsOverview {...props}/> : <Redirect to="/"/> }}/>
                <Route exact path="/dashboard/users" render={(props) => {
                  return (loggedIn) ? <UsersOverview {...props}/> : <Redirect to="/"/> }}/>
                <Route exact path="/dashboard/locks" render={(props) => {
                  return (loggedIn) ? <LocksOverview {...props}/> : <Redirect to="/"/> }}/>

                <Route path="/operators/:id/dashboard" render={(props:RouteComponentProps<Identifier>) => {
                  const id = props.match.params.id
                  return (loggedIn) ? <Redirect to={'/operators/'+id+'/users'}/> : <Redirect to="/"/> }}/>
                <Route path="/operators/:id/projects" render={(props) => {
                  return (loggedIn) ? <OperatorProjects operatorMap={operatorMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/operators/:id/users" render={(props) => {
                  return (loggedIn) ? <OperatorUsers operatorMap={operatorMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/operators/:id/settings" render={(props) => {
                  return (loggedIn) ? <SettingsOperator operatorMap={operatorMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>

                <Route path="/projects/:id/dashboard" render={(props:RouteComponentProps<Identifier>) => {
                  const id = props.match.params.id
                  return (loggedIn) ? <Redirect to={'/projects/'+id+'/trips'}/> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/trips" render={(props) => {
                  return (loggedIn) ? <Trips projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/actions" render={(props) => {
                  return (loggedIn) ? <Actions projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/reservations" render={(props) => {
                  return (loggedIn) ? <Reservations projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/vehicles/overview" render={(props) => {
                  return (loggedIn) ? <Vehicles projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/vehicles/details" render={(props) => {
                  return (loggedIn) ? <VehiclesDetails projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/stations/overview" render={(props) => {
                  return (loggedIn) ? <Stations projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/stations/details" render={(props) => {
                  return (loggedIn) ? <StationsDetails projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/users/overview" render={(props) => {
                  return (loggedIn) ? <Users projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/users/details" render={(props) => {
                  return (loggedIn) ? <UsersDetails projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/locks/overview" render={(props) => {
                  return (loggedIn) ? <Locks projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/locks/details" render={(props) => {
                  return (loggedIn) ? <LocksDetails projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/pricing" render={(props) => {
                  return (loggedIn) ? <Pricing projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/discounts" render={(props) => {
                  return (loggedIn) ? <Discounts projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/emails" render={(props) => {
                  return (loggedIn) ? <Emails projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>
                <Route path="/projects/:id/settings" render={(props) => {
                  return (loggedIn) ? <SettingsProject projectMap={projectMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>

                <Route path="/admin/:id/users" render={(props) => {
                  return (loggedIn) ? <AdminUsers clientMap={clientMap} userData={userData} {...props} /> : <Redirect to="/"/> }}/>

                <Route path="/confirmEmail/:authId" render={(props) => { return <ConfirmEmail {...props}/> }}/>

                <Redirect from="*" to="/" />
            </Switch>
        </div>
        </BrowserRouter>}
      </div>
    </div>
  );
}

export default App;
