import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {collection, doc, DocumentData, getDocs, getFirestore, query, setDoc, where} from "@firebase/firestore";
import {Button} from "react-bootstrap"
import {faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TouchableOpacity from "../elements/TouchableOpacity";
import BasicModal from "../elements/BasicModal";
import AddMemberModal from "../elements/AddMemberModal";
import {useTranslation} from "react-i18next";
import {NavSidebarAdmin} from "../components/NavSidebarAdmin";


type AdminUsersProps = {
  clientMap: Map<string, DocumentData>;
  userData: Record<string, unknown>;
}
& RouteComponentProps<Identifier>

const AdminUsers = (props: AdminUsersProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = []
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState(initRows);
  const [show, setShow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [showAddMember, setShowAddMember] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");

  const clientId = props.match.params.id;
  const clientName = props.clientMap.get(clientId)?.name;

  const operator : Operator = {
    id: "",
    name: clientName,
    clientId: clientId
  }


  const handleClose = () => {
    setShow(false);
    setShowAddMember(false);
    setSelectedUserId("");
    setSelectedUserEmail("");
  }

  const handleConfirm = () => {
    const id = selectedUserId;
    const email = selectedUserEmail;

    const db = getFirestore()
    const ref = doc(db, 'operatorUser', id);
    setDoc(ref, {active: false}, {merge: true})
        .then(() => {
          loadUsers()
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setShow(false)
          setSelectedUserId("");
          setSelectedUserEmail("");
        })
  }

  const handleShow = (id: string, email: string) => {
    setSelectedUserId(id);
    setSelectedUserEmail(email);
    setShow(true);
  }

  const handleShowAddMember = () => {
    setShowAddMember(true);
  }

  const handleAddMemberConfirm = () => {
    setShowAddMember(false);

    setLoading(true)
    loadUsers()
        .then(() => {
          setLoading(false)
        })
  }

  const loadUsers = async () => {
    const db = getFirestore();
    const id = props.match.params.id;
    const userEmail = sessionStorage.getItem("userEmail")
    if (userEmail) {
      setCurrentUserEmail(userEmail);
    }

    const queryOperators = await query(collection(db, "operatorUser"),
        where("clientId", "==", id), where("active", "==", true),
        where("role", "==", 'super-admin'));
    const querySnapshot = await getDocs(queryOperators);
    const localRows = new Map();

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      localRows.set(doc.id, {'id': doc.id, ...doc.data()})
    });

    setRows([...localRows.values()]);
    return 0;
  }

  const tableHead = <tr>
    <th>{t("first-name")}</th>
    <th>{t("last-name")}</th>
    <th></th>
    <th>{t("email")}</th>
    <th>{t("role")}</th>
    <th>{t("last-login")}</th>
    <th>{t("delete")}</th>
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    return (
        <tr key={value.id}>
          <td>{value.firstName}</td>
          <td>{value.lastName}</td>
          <td>
            {(currentUserEmail == value.email) ?
                <div style={{fontWeight:'bold', padding:'3px', backgroundColor:'lightblue', borderRadius:'10px'}}>{t("you")}</div>
                : ""}
          </td>
          <td>{value.email}</td>
          <td>{t(value.role)}</td>
          <td>{value.lastLogin ? new Date(value.lastLogin).toLocaleDateString() + " - " +
              new Date(value.lastLogin).toLocaleTimeString() : t("invitation-sent")}</td>
          <td>{(currentUserEmail != value.email) ?
              <TouchableOpacity className="table-button" onClick={() => handleShow(value.id, value.email)}>
                <FontAwesomeIcon icon={faTrashAlt}/></TouchableOpacity> : <></>}
          </td>
        </tr>)
  })


  useEffect(() => {
    loadUsers()
        .finally(() => {
          setLoading(false)
        })

  }, [])

  return (
      <div className="Dashboard">
        <LoadingElement loading={loading}/>
        <NavSidebarAdmin name={clientName} {...props}/>
        <BasicModal show={show} title={t("remove-user-title")} body={t("remove-user-body")}
                    handleClose={handleClose} handleConfirm={handleConfirm} showCancel={true}/>
        <AddMemberModal show={showAddMember} title={t("add-member-title")} body={t("add-member-body")}
                        handleClose={handleClose} handleConfirm={handleAddMemberConfirm} showCancel={true}
                        operator={operator} isSuperAdmin={true}/>
        <div className="App-header">
          <div className="App-box">
            <div className="App-title">
              {t("members")}
            </div>
            <div className="App-button">
              <Button variant="danger" onClick={() => setShowAddMember(true)}>
                <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
                {t("new-member")}
              </Button>
            </div>
            <div className="App-table">
              <div className="table-responsive">
                <table className="table" id="project-table">
                  <thead>{tableHead}</thead>
                  <tbody>{tableBody}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default AdminUsers;