import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import "../App.css";
import TouchableOpacity from "../elements/TouchableOpacity";
import {browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {collection, doc, getDocs, getFirestore, query, setDoc, where} from "@firebase/firestore";
import {useHistory} from "react-router";
import BasicModal from "../elements/BasicModal";
import {useTranslation} from "react-i18next";
import {getMainLogo, setClientId, setFavicon} from "../utils/MainLogo";

type LoginProps = {
  setLoggedIn: (args: boolean) => void;
  setLoading: (args: boolean) => void;
  setUserData: (args: Record<string, unknown>) => void;
}

export default function Login(props:LoginProps) {

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showWrongPassword, setShowWrongPassword] = useState(false);
  const [showNoAccount, setShowNoAccount] = useState(false);
  const history = useHistory();

  const isUserActive = async (userEmail: string) => {
    const db = getFirestore();
    const timestamp = (new Date()).getTime();

    const queryOperators = await query(collection(db, "operatorUser"),
      where("email", "==", userEmail), where("active", "==", true));
    const querySnapshot = await getDocs(queryOperators);

    let res;

    for (const snap of querySnapshot.docs) {
      const operatorUserData = snap.data();
      if (operatorUserData && operatorUserData.active) {
        const ref = doc(db, 'operatorUser', snap.id);
        await setDoc(ref, {lastLogin: timestamp}, {merge: true});
        res = operatorUserData;
      } else {
        res = {active: false};
      }
    }

    return res;

  }

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    const isValid = validateForm();
    const auth = getAuth();
    event.preventDefault();

    if (!isValid) {
      alert(t("invalid-info"))
    } else {
      setPersistence(auth, browserSessionPersistence)
        .then(() => {

          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              props.setLoading(true)

              if (user.email) {
                isUserActive(user.email)
                  .then((userData) => {
                    if (userData) {
                      props.setUserData(userData);
                      props.setLoggedIn(true)
                      history.push('/dashboard/trips')
                      props.setLoading(false)
                      if (user.email) {
                        sessionStorage.setItem("userEmail", user.email);
                      }

                    } else {
                      signOut(auth).then(() => {
                        props.setLoggedIn(false)
                        props.setLoading(false)
                        setTimeout(() => alert(t("deactivated-account")),300)

                      }).catch((error) => {
                        console.log(error)
                      });

                    }
                  });
              }

            })
            .catch((error) => {
              const errorCode = error.code;

              switch (errorCode) {
                case 'auth/user-not-found': {
                  setShowNoAccount(true);
                }
                break;
                case 'auth/wrong-password': {
                  setShowWrongPassword(true);
                }
                break;
                default: {
                  setShowWrongPassword(true);
                }
              }
            });
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleModalClose = () => {
    setShowWrongPassword(false)
    setShowNoAccount(false)
  }

  useEffect(() => {
    setClientId();
    setFavicon();
  }, [])

  return (
    <div className="login">
      <BasicModal show={showWrongPassword} title={t("wrong-password-title")} body={t("wrong-password-body")}
                  handleClose={handleModalClose} handleConfirm={handleModalClose} showCancel={false}/>
      <BasicModal show={showNoAccount} title={t("no-account-title")} body={t("no-account-body")}
                  handleClose={handleModalClose} handleConfirm={handleModalClose} showCancel={false}/>
      <div className="login-box">
        <div className="login-logo">
          <img
            src={getMainLogo()}
            height="40"
            className="d-inline-block align-top"
            alt="logo"
          />
        </div>
        <div className="login-title">
          {t("login-title")}
        </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>{t("email")}</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>{t("password")}</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <TouchableOpacity className="login-button" type="submit">
          {t("login")}
        </TouchableOpacity>
      </Form>
        <div className="login-forgot-passowrd">
          <Button variant="link" onClick={() => history.push('/reset-password')}>{t("forgot-password")}</Button>
        </div>
      </div>
    </div>
  );
}