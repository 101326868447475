import React, {useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {doc, getFirestore, setDoc} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type UserProfileModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
  userData: Record<string, string>;
}

export default function UserProfileModal(props: UserProfileModalProps) {

  const { t } = useTranslation();
  const [firstName, setFirstName] = useState(props.userData.firstName);
  const [displayFirstNameError, setDisplayFirstNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState(props.userData.lastName);
  const [displayLastNameError, setDisplayLastNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState("");

  const validate = async (event: React.FormEvent<HTMLFormElement>) => {
    const db = getFirestore();
    const ref = doc(db, 'operatorUser', props.userData.id);
    event.preventDefault();

    if (firstName == "") {
      setFirstNameError(t("valid-name"));
      setDisplayFirstNameError(true);
      setTimeout(() => setDisplayFirstNameError(false), 5000)
    }
    if (lastName == "") {
      setLastNameError(t("valid-name"));
      setDisplayLastNameError(true)
      setTimeout(() => setDisplayLastNameError(false), 5000)

    }
    if (firstName !== "" && lastName !== "") {
      //Save changes in the user profile:
      setDoc(ref, {firstName: firstName, lastName: lastName}, {merge: true})
          .then(() => {
            console.log("done")
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            handleConfirm();
            window.location.reload();
          })
    }
  }

  const cleanUp = () => {
    setDisplayFirstNameError(false)
    setDisplayLastNameError(false);
    setFirstName(props.userData.firstName);
    setLastName(props.userData.lastName);
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  return (
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={validate}>
            <div>
              <Form.Label>{props.body}</Form.Label>
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label>{t("first-name")}</Form.Label>
              <Form.Control autoFocus type="name" onChange={(e) => setFirstName(e.target.value)}
                            value={firstName} placeholder={t("first-name")}/>
              <div style={{color: displayFirstNameError ? "red": "transparent", marginBottom:'10px'}}>
                {firstNameError}
              </div>
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label htmlFor={"name"}>{t("last-name")}</Form.Label>
              <Form.Control id={"name"} type="name" onChange={(e) => setLastName(e.target.value)}
                            value={lastName} placeholder={t("last-name")}/>
              <div style={{color: displayLastNameError ? "red": "transparent", marginBottom:'10px'}}>
                {lastNameError}
              </div>
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label>{t("email")}</Form.Label>
              <Form.Control type="email" placeholder={props.userData.email} disabled/>
            </div>
            <div style={{marginBottom: '10px'}}>
              <Form.Label>{t("role")}</Form.Label>
              <Form.Control type="name" placeholder={t(props.userData.role)} disabled/>
            </div>
            <Modal.Footer>
              {props.showCancel && <Button variant="dark" onClick={handleClose}>
                {t("cancel")}
              </Button>}
              <Button variant="danger" type={'submit'}>
                {t("save-changes")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
  )

}