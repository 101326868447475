import React, {ChangeEvent, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {collection, doc, DocumentData, getDocs, getFirestore, query, setDoc, where} from "@firebase/firestore";
import {useTranslation} from "react-i18next";


type AddProjectModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
  operator: Operator;
}

export default function AddProjectModal(props:AddProjectModalProps) {

  const { t } = useTranslation();
  const initRows: DocumentData[] = []
  const [rows, setRows] = useState(initRows);
  const [displayCheckError, setDisplayCheckError] = useState(false);
  const [checkError, setCheckError] = useState("-");
  const [boxChecked, setBoxChecked] = useState(new Set<string>());

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    console.log("validate")
    event.preventDefault();
    const db = getFirestore();

    if (boxChecked.size == 0) {
      setCheckError(t("must-select-project"))
      setDisplayCheckError(true)
      setTimeout(() => setDisplayCheckError(false), 5000)

    } else {
      const operatorId = props.operator.id;
      const projects = [...boxChecked.keys()];
      const timestamp = (new Date()).getTime();
      const userEmail = sessionStorage.getItem("userEmail");

      const path = 'operators/' + operatorId + '/projects';

      projects.forEach(async (id: string) => {
        console.log(id)
        await setDoc(doc(db, path, id), {
          id: id,
          addedBy: userEmail,
          timestamp: timestamp,
          name: rows.find(r => r.id == id)?.name
        });
      })

      handleConfirm()
    }
  }

  const cleanUp = () => {
    setDisplayCheckError(false);
    setBoxChecked(new Set())
  }

  const handleClose = () => {
    props.handleClose();
    cleanUp();
  }

  const handleConfirm = () => {
    props.handleConfirm();
    cleanUp();
  }

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.id)
    if (e.target.checked) {
      boxChecked.add(e.target.id);
    } else {
      boxChecked.delete(e.target.id);
    }
    setBoxChecked(new Set<string>(boxChecked));
  }

  const loadProjects  = async () => {
    const db = getFirestore()
    const queryPath = 'projects';
    const subQueryPath = 'operators/' + props.operator.id + '/projects';
    const localRows = [];
    const clientId = props.operator.clientId;

    const queryProjects = await getDocs(query(collection(db, queryPath),
      where("clientId", "==", clientId), where("active", "==", true)));
    const subQueryProjects = await getDocs(collection(db, subQueryPath));
    const existingProjectIds = subQueryProjects.docs.map(d => d.id);

    for (const project of queryProjects.docs) {
      if (!existingProjectIds.includes(project.id) ) {
        localRows.push({id: project.id, ...project.data()})
      }
    }

    setRows(localRows);
    return 0;
  }

  const projects = rows.map((row) => {
    return (<Form.Check
        key={row.id}
        style={{fontWeight: 'bold'}}
        type={"checkbox"}
        id={row.id}
        label={row.name}
        onChange={(e) => handleChange(e)}
        checked={boxChecked.has(row.id)}
    />)
  })

  return (
      <Modal show={props.show} onHide={handleClose} onShow={loadProjects}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={validate}>
          <Modal.Body>
            <Form.Group>
              <div>
                <Form.Label>{props.body}</Form.Label>
                {projects}
              </div>
              <div style={{color: displayCheckError ? "red": "transparent", marginBottom:'10px'}}>
                {checkError}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {props.showCancel && <Button variant="dark" onClick={handleClose}>
              {t("cancel")}
            </Button>}
            <Button variant="danger" type={'submit'}>
              {t("ok")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )

}