
export function getDurationFormat(millis: number, dayString: string) {

  let seconds = Math.floor(millis / 1000);
  let minutes = Math.floor(millis / 60000);
  let hours = Math.floor(millis / 3600000);
  const days = Math.floor(millis / (24 * 3600000));
  seconds = seconds - (minutes * 60);
  minutes = minutes - (hours * 60);
  hours = hours - (days * 24);

  const formatted = `${days > 0 ? days +" "+ dayString + ", " : ""}${hours < 10 ? 0 : ""}${hours}:${minutes < 10 ?
      0 : ""}${minutes}:${seconds < 10 ? 0 : ""}${seconds}`;

  return formatted;

}