import {RouteComponentProps, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Navigation, NavItemProps} from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faBicycle,
  faCalendarAlt,
  faCog,
  faCreditCard,
  faEnvelopeOpenText,
  faFileAlt,
  faLockOpen,
  faMapSigns,
  faPercent,
  faRoute,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import '../App.css';
import {useTranslation} from "react-i18next";

type NavSidebarProjectsProps = {
  name: string
  userData: Record<string, unknown>
  projectType: string
} & RouteComponentProps<Identifier>


export const NavSidebarProjects = (props:NavSidebarProjectsProps) => {
  const initVehicles : NavItemProps[] = [];
  const [projectId, setProjectId] = useState(props.match.params.id)
  const [projectName, setProjectName] = useState(props.name)
  const [vehicles, setVehicles] = useState(initVehicles)

  const history = useHistory();
  const { t } = useTranslation();

  const isStaff = (props.userData.role == 'staff');
  const isSuperAdmin = (props.userData.role == 'super-admin') || isStaff;
  const isAdmin = (props.userData.role == 'admin') || isSuperAdmin;

  const items = [
    {
      title: t('trips'),
      itemId: '/projects/' + projectId + '/trips',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faRoute}/>
      </div>,
    },
    {
      title: t('reservations'),
      itemId: '/projects/' + projectId + '/reservations',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faCalendarAlt}/>
      </div>
    },
    {
      title: t('vehicles'),
      itemId: '/projects/' + projectId + '/vehicles/overview',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faBicycle}/>
      </div>,
      subNav:  [{
        title: t('sub-overview'),
        itemId: '/projects/' + projectId + '/vehicles/overview',
        elemBefore: () => <FontAwesomeIcon icon={faBicycle} />,
      },
        {
          title: t('sub-details'),
          itemId: '/projects/' + projectId + '/vehicles/details',
          elemBefore: () => <FontAwesomeIcon icon={faBicycle} />,
        },
      ]
    },
    {
      title: t('stations'),
      itemId: '/projects/' + projectId + '/stations/overview',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faMapSigns}/>
      </div>,
      subNav:  [{
        title: t('sub-overview'),
        itemId: '/projects/' + projectId + '/stations/overview',
        elemBefore: () => <FontAwesomeIcon icon={faMapSigns} />,
      },
        {
          title: t('sub-details'),
          itemId: '/projects/' + projectId + '/stations/details',
          elemBefore: () => <FontAwesomeIcon icon={faMapSigns} />,
        },
      ]
    },
    {
      title: t('users'),
      itemId: '/projects/' + projectId + '/users/overview',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faUser}/>
      </div>,
      subNav:  [{
        title: t('sub-overview'),
        itemId: '/projects/' + projectId + '/users/overview',
        elemBefore: () => <FontAwesomeIcon icon={faUser} />,
      },
        {
          title: t('sub-details'),
          itemId: '/projects/' + projectId + '/users/details',
          elemBefore: () => <FontAwesomeIcon icon={faUser} />,
        },
      ]
    },
    {
      title: t('locks'),
      itemId: '/projects/' + projectId + '/locks/overview',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faLockOpen}/>
      </div>,
      subNav:  [{
        title: t('sub-overview'),
        itemId: '/projects/' + projectId + '/locks/overview',
        elemBefore: () => <FontAwesomeIcon icon={faLockOpen} />,
      },
        {
          title: t('sub-details'),
          itemId: '/projects/' + projectId + '/locks/details',
          elemBefore: () => <FontAwesomeIcon icon={faLockOpen} />,
        },
      ]
    },
  ]

  if (isSuperAdmin) {
    items.push({
      title: t('settings'),
      itemId: '/projects/' + projectId + '/settings',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faCog}/>
      </div>
    })
  }

  const isTourismApp = (props.projectType === 'tourism');

  if (isTourismApp) {
    items.push(
      {
        title: t('pricing'),
        itemId: '/projects/' + projectId + '/pricing',
        elemBefore: () => <div>
          <FontAwesomeIcon icon={faCreditCard}/>
        </div>
      });
    items.push(
      {
        title: t('discounts'),
        itemId: '/projects/' + projectId + '/discounts',
        elemBefore: () => <div>
          <FontAwesomeIcon icon={faPercent}/>
        </div>
      });
    items.push(
      {
        title: t('emails'),
        itemId: '/projects/' + projectId + '/emails',
        elemBefore: () => <div>
          <FontAwesomeIcon icon={faEnvelopeOpenText}/>
        </div>
      });
  }

  useEffect(() => {
    const id = props.match.params.id;

  }, [])

  return (
    <div className="App-sidebar">
      <div className="navsidebar-title">
        <div className="navsidebar-title-icon">
          <FontAwesomeIcon icon={faFileAlt} style={{marginRight:'15px'}}/>
          {projectName}
        </div>
      </div>
      <div className="navsidebar-menu">
        <React.Fragment>
          <Navigation
            // you can use your own router's api to get pathname
            activeItemId={props.match.url}
            onSelect={({itemId}) => {
              if (projectId) {
                history.push(itemId)
              }
            }}
            items={items}
          />
        </React.Fragment>
      </div>
    </div>
  )
}