import React, {useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEdit,
  faExclamationTriangle,
  faMapMarker,
  faPlusCircle,
  faTemperatureHigh,
  faTemperatureLow,
  faTrashAlt,
  faWater
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import TouchableOpacity from "../elements/TouchableOpacity";
import GaugeChart from 'react-gauge-chart';
import closedLock from '../resources/lock-closed.png';
import openLock from '../resources/lock-open-blinking.gif';
import TimeseriesChart from "../charts/TimeseriesChart";
import AddLockModal from "../elements/AddLockModal";
import BasicModal from "../elements/BasicModal";
import EditLockModal from "../elements/EditLockModal";

type LocksProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Locks = (props:LocksProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = [];
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(initRows);
  const [showAddLock, setShowAddLock] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [selectedLockId, setSelectedLockId] = useState("");
  const [selectedLock, setSelectedLock] = useState<Lock>();
  const [selectedField, setSelectedField] = useState("");
  const [showDeleteLock, setShowDeleteLock] = useState(false);
  const [showEditLock, setShowEditLock] = useState(false);
  const [showRequestGps, setShowRequestGps] = useState(false);
  const [showRequestGpsSuccess, setShowRequestGpsSuccess] = useState(false);
  const history = useHistory();

  const isSuperAdmin = (props.userData.role === 'super-admin' || props.userData.role === 'staff');

  const loadLocks = async () => {
    const db = getFirestore();
    const id = props.match.params.id;

    const queryStations = await query(collection(db, "locks"),
        where("projectId", "==", id), where("active", "==", true),
        orderBy("name", "asc"), limit(500));

    const querySnapshot = await getDocs(queryStations);
    const localRows : DocumentData[] = [];

    for (const lock of querySnapshot.docs) {
      localRows.push({'id': lock.id, ...lock.data()})
    }

    localRows.sort((a,b) => a.displayName === b.displayName ? 0 : a.displayName < b.displayName ? -1 : 1);

    setRows(localRows);

    return 0;
  }

  const handleShowEdit = (id:string, loraId: string, name:string) => {
    setSelectedLockId(id);
    setSelectedLock({id: id, loraId: loraId, displayName: name} as Lock);
    setShowEditLock(true);
  }

  const handleRequestGps = (id:string, loraId: string, name: string) => {
    setSelectedLockId(id);
    setSelectedLock({id: id, loraId: loraId, displayName: name} as Lock);
    setShowRequestGps(true);
  }

  const handleShowDelete = (id:string) => {
    setSelectedLockId(id);
    setShowDeleteLock(true);
  }

  const showBatteryChart = (id:string) => {
    setSelectedLockId(id);
    setSelectedField("battery");
    setShowChart(true);
  }

  const showStatusChart = (id:string) => {
    setSelectedLockId(id);
    setSelectedField("isOpen");
    setShowChart(true);
  }

  const showTheftChart = (id:string) => {
    setSelectedLockId(id);
    setSelectedField("theft");
    setShowChart(true);
  }

  const showTemperatureChart = (id:string) => {
    setSelectedLockId(id);
    setSelectedField("temperature");
    setShowChart(true);
  }

  const showHumidityChart = (id:string) => {
    setSelectedLockId(id);
    setSelectedField("humidity");
    setShowChart(true);
  }

  const tableHead = <tr>
    <th>{t("display-name")}</th>
    <th>{t("lora-id")}</th>
    <th>{t("ble-name")}</th>
    <th>{t("battery")}</th>
    <th>{t("status")}</th>
    <th>{t("theft")}</th>
    <th>{t("temperature")}</th>
    <th>{t("humidity")}</th>
    <th>{t("last-message")}</th>
    {isSuperAdmin && <th>{t("request-gps")}</th>}
    {isSuperAdmin && <th>{t("edit")}</th>}
    {isSuperAdmin && <th>{t("delete")}</th>}
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    const lockUrl = '/projects/'+props.match.params.id+'/locks/details?id='+value.id;
    const lockName = <Button variant={"link"} size={"sm"} onClick={() => {history.push(lockUrl)}}>{value.displayName}</Button>;

    const lastMessageDate = (value.lastMessage) ? new Date(value.lastMessage).toLocaleDateString() : "";
    const lastMessageTime = (value.lastMessage) ? new Date(value.lastMessage).toLocaleTimeString() : "";

    const gauge =
      <div className="device-data-button" id={"gauge-"+value.id} onClick={() => showBatteryChart(value.id)}>
        <GaugeChart id={"gauge-chart-"+value.id} nrOfLevels={3} percent={Math.round(value.battery)/100} animate={false}
                  colors={['red','yellow','green']} style={{width:60}}/>
      </div>

    const lockStatus =
      <div className="device-data-button" id="lockStatus" onClick={() => showStatusChart(value.id)}>
        <img
        src={value.isOpen ? openLock : closedLock}
        height="50"
        className="d-inline-block align-top"
        alt="Locked Closed"/>
      </div>

    const theftDetection = <div className="device-data-button" id="theft" onClick={() => showTheftChart(value.id)}>
      {(value.theft) ?
      <FontAwesomeIcon icon={faExclamationTriangle} color={'red'} size={'lg'}/> :
      <FontAwesomeIcon icon={faCheckCircle} color={"green"}/>}
    </div>;

    const temperature = (value.temperature) ?
      <div onClick={() => showTemperatureChart(value.id)}>{(value.temperature < 40) ?
        <div className="device-data-button" id="temperature">
        <FontAwesomeIcon icon={faTemperatureLow} size={'lg'}/>
        {Math.round(value.temperature)}{"°"}</div> :
        <div className="device-data-button" style={{color:'red'}} id="temperature">
          <FontAwesomeIcon icon={faTemperatureHigh} color={'red'} size={'lg'}/>
          {Math.round(value.temperature)}{"°"}</div>}</div> : "";

      const humidity = (value.humidity) ?
        <div onClick={() => showHumidityChart(value.id)}>{(value.humidity < 95) ?
          <div className="device-data-button" id="humidity">
          <FontAwesomeIcon icon={faWater} size={'lg'}/>
          {value.humidity}{"%"}</div> :
          <div className="device-data-button" style={{color: 'red'}} id="humidity">
            <FontAwesomeIcon icon={faWater} color={'red'} size={'lg'}/>
            {value.humidity}{"%"}</div>}</div>
          : "";

    return (
        <tr key={value.id} style={{alignItems:'center'}}>
          <td>{lockName}</td>
          <td>{value.loraId}</td>
          <td>{value.name}</td>
          <td>{gauge}{Math.round(value.battery)}{"%"}</td>
          <td>{lockStatus}</td>
          <td>{theftDetection}</td>
          <td>{temperature}</td>
          <td>{humidity}</td>
          <td>{lastMessageDate}{<br/>}{lastMessageTime}</td>
          {isSuperAdmin && <td>
            <TouchableOpacity className="table-button"
                              onClick={() => handleRequestGps(value.id, value.loraId, value.displayName)}>
              <FontAwesomeIcon icon={faMapMarker}/>
            </TouchableOpacity>
          </td>}
          {isSuperAdmin && <td>
            <TouchableOpacity className="table-button"
                              onClick={() => handleShowEdit(value.id, value.loraId, value.displayName)}>
              <FontAwesomeIcon icon={faEdit}/>
            </TouchableOpacity>
          </td>}
          {isSuperAdmin && <td>
            <TouchableOpacity className="table-button"
                              onClick={() => handleShowDelete(value.id)}>
              <FontAwesomeIcon icon={faTrashAlt}/>
            </TouchableOpacity>
          </td>}
        </tr>
    )
  });

  const handleConfirmAddLock = () => {
    setLoading(true)
    setShowAddLock(false);
    loadLocks()
      .finally(() => {
        setLoading(false)
      })
  }

  const handleClose = () => {
    setSelectedLockId("");
    setShowAddLock(false);
    setShowDeleteLock(false);
    setShowEditLock(false);
    setShowRequestGps(false);
    setShowRequestGpsSuccess(false);
  }

  const handleConfirmDelete = () => {
    setShowDeleteLock(false);
    const db = getFirestore();
    const ref = doc(db, 'locks', selectedLockId);
    setLoading(true)
    setDoc(ref, {active: false}, {merge: true})
      .then(() => {
        loadLocks()
          .finally(() => {
            setLoading(false)
            setSelectedLockId("");
          })
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
      });
  }

  const handleConfirmEdit = () => {
    setShowEditLock(false);
    setLoading(true)
    loadLocks()
      .finally(() => {
        setLoading(false)
      })
  }

  const handleConfirmGpsRequest = () => {
    setShowRequestGps(false);
    const url = `https://proxy1.lpn.swisscom.ch/thingpark/lrc/rest/downlink/?DevEUI=${selectedLock?.loraId}&Fport=1&Payload=04`;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const myInit = {method: 'POST', headers: myHeaders, mode: 'no-cors' as RequestMode};
    fetch(url, myInit)
      .then((res) => {
        if(res.ok) {
          console.log("OK")
        } else {
          console.log("Opaque Response due to no-cors mode")
        }
        setShowRequestGpsSuccess(true);
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    setLoading(true)
    loadLocks()
        .finally(() => {
          setLoading(false)
        })
  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                          projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
      <div className="App-header">
        <TimeseriesChart show={showChart} lockId={selectedLockId}
                         type={selectedField} handleClose={() => setShowChart(false)}/>
        <AddLockModal show={showAddLock} title={t('add-lock-title')}
                      body={t('add-lock-body')} handleClose={handleClose}
                      handleConfirm={handleConfirmAddLock} showCancel={true} projectId={props.match.params.id}/>
        <BasicModal show={showDeleteLock} title={t("delete-lock-title")} body={t("delete-lock-body")}
                    handleClose={handleClose} handleConfirm={handleConfirmDelete} showCancel={true}/>
        <EditLockModal show={showEditLock} title={t("edit-lock-title")}
                       body={t("edit-lock-body")}
                       handleClose={handleClose}
                       handleConfirm={handleConfirmEdit}
                       projectId={props.match.params.id}
                       lockId={selectedLockId}
                       lock={selectedLock}
                       projectMap={props.projectMap}/>
        <BasicModal show={showRequestGps} title={t('request-gps-title')}
                    body={t('request-gps-body')}
                    handleClose={handleClose}
                    handleConfirm={handleConfirmGpsRequest}
                    showCancel={true}/>
        <BasicModal show={showRequestGpsSuccess} title={t('request-gps-success-title')}
                    body={t('request-gps-success-body')}
                    handleClose={handleClose}
                    handleConfirm={handleClose} showCancel={false}/>
        <div className="App-box">
          <div className="App-title">
            {t("locks")}
          </div>
          {isSuperAdmin && <div className="App-button">
            <Button variant="danger" onClick={() => setShowAddLock(true)}>
              <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
              {t("new-lock")}
            </Button>
          </div>}
          <div className="App-table">
            <div className="table-responsive" style={{height: '70vh'}}>
              <table className="table" id="project-table">
                <thead>{tableHead}</thead>
                <tbody>{tableBody}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Locks;