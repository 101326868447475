import React, {useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faEdit, faPlusCircle, faTimesCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button, ListGroup} from "react-bootstrap";
import TouchableOpacity from "../elements/TouchableOpacity";
import AddStationModal from "../elements/AddStationModal";
import BasicModal from "../elements/BasicModal";
import EditStationModal from "../elements/EditStationModal";
import LatLng = google.maps.LatLng;

type StationsProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Stations = (props:StationsProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = [];
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(initRows);
  const [showAddStation, setShowAddStation] = useState(false);
  const [showDeleteStation, setShowDeleteStation] = useState(false);
  const [showEditStation, setShowEditStation] = useState(false);
  const [selectedStationId, setSelectedStationId] = useState("");
  const [selectedStation, setSelectedStation] = useState<StationDetails>();
  const [nVehiclesOnTheRoad, setNVehiclesOnTheRoad] = useState(0);
  const history = useHistory();

  const isSuperAdmin = (props.userData.role === 'super-admin' || props.userData.role === 'staff');

  const loadStations = async () => {
    const db = getFirestore();
    const id = props.match.params.id;

    const queryStations = await query(collection(db, "stations"),
        where("projectId", "==", id), where("active", "==", true),
        orderBy("name", "asc"), limit(500));

    const querySnapshot = await getDocs(queryStations);
    const localRows : DocumentData[] = [];

    const now = new Date();
    const todayAtMidnight = new Date();
    todayAtMidnight.setHours(0,0,0,0);
    todayAtMidnight.setDate(now.getDate() + 1);
    const tomorrow = new Date();
    tomorrow.setHours(0,0,0,0);
    tomorrow.setDate(now.getDate() + 2);
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setHours(0,0,0,0);
    dayAfterTomorrow.setDate(now.getDate() + 3);

    for (const station of querySnapshot.docs) {
      const queryReservations = await query(collection(db, "reservations"),
          where("station", "==", station.get("name")),
          where("active", "==", true),
          where("confirmed", "==", true),
          where("completed", "==", false),
          where("start", "<=", dayAfterTomorrow.getTime() ))

      let nReservationsToday = 0;
      let nReservationsTomorrow = 0;
      let nReservationsDayAfterTomorrow = 0;

      const queryReservationsSnapshot = await getDocs(queryReservations);
      for (const reservation of queryReservationsSnapshot.docs) {
        const start = reservation.get("start");
        if (start < todayAtMidnight.getTime()) {
          nReservationsToday++
        } else if (start < tomorrow.getTime()) {
          nReservationsTomorrow++
        } else if (start < dayAfterTomorrow.getTime()) {
          nReservationsDayAfterTomorrow++
        }
      }

      localRows.push({'id': station.id,
        'nReservationsToday': nReservationsToday,
        'nReservationsTomorrow': nReservationsTomorrow,
        'nReservationsDayAfterTomorrow': nReservationsDayAfterTomorrow,
        ...station.data()})
    }

    setRows(localRows);

    const queryVehicles = query(collection(db, "vehicles"),
        where("projectId", "==", id), where("active", "==", true),
        where("station", "==", "on-the-road"));

    const queryVehiclesSnapshot = await getDocs(queryVehicles)
    setNVehiclesOnTheRoad(queryVehiclesSnapshot.docs.length)

    return 0;
  }

  const tableHead = <tr>
    <th>{t("name")}</th>
    <th>{t("n-vehicles-target")}</th>
    <th>{t("n-vehicles-current")}</th>
    <th>{t("n-reservations-today")}</th>
    <th>{t("n-reservations-tomorrow")}</th>
    <th>{t("n-reservations-day-after-tomorrow")}</th>
    <th>{t("geofencing")}</th>
    {isSuperAdmin && <th>{t("edit")}</th>}
    {isSuperAdmin && <th>{t("delete")}</th>}
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    const stationUrl = '/projects/'+props.match.params.id+'/stations/details?id='+value.id;
    const stationName = <Button variant={"link"} size={"sm"} onClick={() => {history.push(stationUrl)}}>{value.name}</Button>;
    const geofencing = value.geofencing ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> :
        <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>

    const vehicleTargetMap : Map<string, number> = new Map(Object.entries(value.vehiclesTarget)) ;

    const stationDetails : StationDetails = {
      id: value.id,
      name: value.name,
      bikeAvailable: vehicleTargetMap.has("bike"),
      numberOfBikes: vehicleTargetMap.has("bike") ? (vehicleTargetMap.get("bike") ?? 0) : 0,
      ebikeAvailable: vehicleTargetMap.has("e-bike"),
      numberOfEBikes: vehicleTargetMap.has("e-bike") ? (vehicleTargetMap.get("e-bike") ?? 0) : 0,
      ebikeSAvailable: vehicleTargetMap.has("e-bike-S"),
      numberOfEBikesS: vehicleTargetMap.has("e-bike-S") ? (vehicleTargetMap.get("e-bike-S") ?? 0) : 0,
      ebikeMAvailable: vehicleTargetMap.has("e-bike-M"),
      numberOfEBikesM: vehicleTargetMap.has("e-bike-M") ? (vehicleTargetMap.get("e-bike-M") ?? 0) : 0,
      ebikeLAvailable: vehicleTargetMap.has("e-bike-L"),
      numberOfEBikesL: vehicleTargetMap.has("e-bike-L") ? (vehicleTargetMap.get("e-bike-L") ?? 0) : 0,
      cargoAvailable: vehicleTargetMap.has("cargo"),
      numberOfCargos: vehicleTargetMap.has("cargo") ? (vehicleTargetMap.get("cargo") ?? 0) : 0,
      geofencing: value.geofencing,
      geofencingRadius: value.radius,
      latLng: new LatLng(value.position?.latitude, value.position?.longitude)
    }

    return (
        <tr key={value.id} style={{alignItems:'center'}}>
          <td>{stationName}</td>
          <td>{value.nVehiclesTarget}</td>
          <td>{value.nVehiclesCurrent}</td>
          <td>{value.nReservationsToday}</td>
          <td>{value.nReservationsTomorrow}</td>
          <td>{value.nReservationsDayAfterTomorrow}</td>
          <td>{geofencing}</td>
          {isSuperAdmin && <td>{<TouchableOpacity className="table-button" onClick={() => handleShowEdit(stationDetails)}>
            <FontAwesomeIcon icon={faEdit}/></TouchableOpacity>}
          </td>}
          {isSuperAdmin && <td>{<TouchableOpacity className="table-button" onClick={() => handleShowDelete(value.id)}>
            <FontAwesomeIcon icon={faTrashAlt}/></TouchableOpacity>}
          </td>}
        </tr>
    )
  });

  const confirmNewStation = () => {
    setShowAddStation(false)
    setLoading(true)
    loadStations()
      .finally(() => {
        setLoading(false)
      })
  }

  const confirmEditStation = () => {
    setShowEditStation(false);
    setLoading(true)
    loadStations()
      .finally(() => {
        setLoading(false)
      })
  }

  const handleClose = () => {
    setSelectedStationId("");
    setShowDeleteStation(false);
    setShowAddStation(false);
    setShowEditStation(false);
  }

  const handleShowEdit = (stationDetails: StationDetails) => {
    setSelectedStationId(stationDetails.id);
    setSelectedStation(stationDetails);
    setShowEditStation(true);
  }

  const handleShowDelete = (id:string) => {
    setSelectedStationId(id);
    setShowDeleteStation(true);
  }

  const handleConfirmDelete = () => {
    setShowDeleteStation(false);
    const db = getFirestore();
    const ref = doc(db, 'stations', selectedStationId);
    setLoading(true)
    setDoc(ref, {active: false}, {merge: true})
      .then(() => {
        loadStations()
          .finally(() => {
            setLoading(false)
            setSelectedStationId("");
          })
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
      });
  }

  useEffect(() => {
    setLoading(true)
    loadStations()
        .finally(() => {
          setLoading(false)
        })
  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                          projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
      <BasicModal show={showDeleteStation} title={t("delete-station-title")} body={t("delete-station-body")}
                  handleClose={handleClose} handleConfirm={handleConfirmDelete} showCancel={true}/>
      <AddStationModal show={showAddStation} title={t('add-station-title')}
                       body={t('add-station-body')}
                       handleClose={handleClose}
                       handleConfirm={confirmNewStation}
                       projectId={props.match.params.id}
                       isProjectGlobal={props.projectMap.get(props.match.params.id)?.type === 'tourism'}
                       showCancel={true}/>
      <EditStationModal show={showEditStation} title={t('edit-station-title')}
                        body={t('edit-station-body')}
                        handleClose={handleClose}
                        handleConfirm={confirmEditStation}
                        station={selectedStation}/>
      <div className="App-header">
        <div className="App-box">
          <div className="App-title">
            {t("stations")}
          </div>
          {isSuperAdmin && <div className="App-button">
            <Button variant="danger" onClick={() => setShowAddStation(true)}>
              <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
              {t("new-station")}
            </Button>
          </div>}
          <div className="App-list-group">
            <ListGroup style={{marginLeft:10, width:250}}>
              <ListGroup.Item as="li"><div className="App-list-group-item">
                <div className="App-list-group-item-1"> {t('vehicles')}{" ("}{t('on-the-road')}{"): "}</div>
                <div className="App-list-group-item-2">{nVehiclesOnTheRoad}</div></div></ListGroup.Item>
            </ListGroup>
          </div>
          <div className="App-table">
            <div className="table-responsive" style={{height: '70vh'}}>
              <table className="table" id="project-table">
                <thead>{tableHead}</thead>
                <tbody>{tableBody}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stations;