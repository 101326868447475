import React, {useEffect, useState, FC} from 'react'

export const Marker : FC<google.maps.MarkerOptions> = (options) => {

  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {

    if (marker) {
      marker.setPosition(options.position);
      if (options.map) {
        marker.setMap(options.map)
        marker.setIcon(options.icon)
      }
    }
  }, [marker, options]);

  return null;
};