import React, {useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {getFirestore} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type AddPricingLevelModalProps = {
    show: boolean,
    title: string,
    body: string,
    handleClose: () => void;
    handleConfirm: (start: string, stop: string,
                    priceWithoutAbo: string, priceWithAbo: string) => void;
    showCancel: boolean;
    projectId: string,
}

export default function AddPricingLevelModal(props:AddPricingLevelModalProps) {

    const { t } = useTranslation();
    const [start, setStart] = useState("");
    const [displayStartError, setDisplayStartError] = useState(false);
    const [startError, setStartError] = useState("");
    const [stop, setStop] = useState("");
    const [displayStopError, setDisplayStopError] = useState(false);
    const [stopError, setStopError] = useState("");
    const [priceWithoutAbo, setPriceWithoutAbo] = useState("");
    const [displayPriceWithoutAboError, setDisplayPriceWithoutAboError] = useState(false);
    const [priceWithoutAboError, setPriceWithoutAboError] = useState("");
    const [priceWithAbo, setPriceWithAbo] = useState("");
    const [displayPriceWithAboError, setDisplayPriceWithAboError] = useState(false);
    const [priceWithAboError, setPriceWithAboError] = useState("");

    const validate = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const db = getFirestore();

        if (start === "" ) {
            setStartError(t("valid-data"));
            setDisplayStartError(true)
            setTimeout(() => setDisplayStartError(false), 5000)
        }

        if (stop === "" ) {
            setStopError(t("valid-data"));
            setDisplayStopError(true)
            setTimeout(() => setDisplayStopError(false), 5000)
        }

        if (priceWithoutAbo === "" ) {
            setPriceWithoutAboError(t("valid-data"));
            setDisplayPriceWithoutAboError(true)
            setTimeout(() => setDisplayPriceWithoutAboError(false), 5000)
        }

        if (priceWithAbo === "" ) {
            setPriceWithAboError(t("valid-data"));
            setDisplayPriceWithAboError(true)
            setTimeout(() => setDisplayPriceWithAboError(false), 5000)
        }

        if (start && stop && priceWithoutAbo && priceWithAbo) {
            props.handleConfirm(start, stop, priceWithoutAbo, priceWithAbo);
            cleanUp();
        }
    }

    const cleanUp = () => {
        setStart("")
        setStop("")
        setPriceWithoutAbo("")
        setPriceWithAbo("")
    }

    const handleClose = () => {
        cleanUp()
        props.handleClose()
    }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={validate}>
                <Modal.Body>
                    <Form.Group>
                        <div>
                            <Form.Label>{props.body}</Form.Label>
                        </div>
                        <div>
                            <Form.Label>{t('start')+" ("+t('hours')+")"}</Form.Label>
                            <Form.Control autoFocus type="number" onChange={(e) => setStart(e.target.value)} value={start}
                                          placeholder={t("start")}/>
                        </div>
                        <div style={{color: displayStartError ? "red": "transparent", marginBottom:'10px'}}>
                            {startError}
                        </div>
                        <div>
                            <Form.Label>{t('end')+" ("+t('hours')+")"}</Form.Label>
                            <Form.Control autoFocus type="number" onChange={(e) => setStop(e.target.value)} value={stop}
                                          placeholder={t("end")}/>
                        </div>
                        <div style={{color: displayStopError ? "red": "transparent", marginBottom:'10px'}}>
                            {stopError}
                        </div>
                        <div>
                            <Form.Label>{t('price-without-abo')}</Form.Label>
                            <Form.Control autoFocus type="number" onChange={(e) => setPriceWithoutAbo(e.target.value)}
                                          value={priceWithoutAbo} placeholder={t("price-without-abo")}/>
                        </div>
                        <div style={{color: displayPriceWithoutAboError ? "red": "transparent", marginBottom:'10px'}}>
                            {priceWithoutAboError}
                        </div>
                        <div>
                            <Form.Label>{t('price-with-abo')}</Form.Label>
                            <Form.Control autoFocus type="number" onChange={(e) => setPriceWithAbo(e.target.value)}
                                          value={priceWithAbo} placeholder={t("price-with-abo")}/>
                        </div>
                        <div style={{color: displayPriceWithAboError ? "red": "transparent", marginBottom:'10px'}}>
                            {priceWithAboError}
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {props.showCancel && <Button variant="dark" onClick={handleClose}>
                        {t("cancel")}
                    </Button>}
                    <Button variant="danger" type={'submit'}>
                        {t("ok")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}


