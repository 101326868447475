import React, {useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {addDoc, collection, getFirestore} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type AddLockModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
  projectId: string,
}

export default function AddLockModal(props:AddLockModalProps) {

  const { t } = useTranslation();
  const [bleName, setBleName] = useState("");
  const [loraId, setLoraId] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [displayNameError, setDisplayNameError] = useState(false);
  const [nameError, setNameError] = useState("");

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (displayName == "" ) {
      setNameError(t("valid-name"));
      setDisplayNameError(true)
      setTimeout(() => setDisplayNameError(false), 5000)
    }

    if (displayName != "") {
      const projectId = props.projectId;
      const clientId = sessionStorage.getItem("clientId");

      await addDoc(collection(db, "locks"), {
        active: true,
        clientId: clientId,
        displayName: displayName,
        isGlobal: false,
        loraId: loraId,
        name: bleName,
        projectId: projectId,
      });

      handleConfirm()
    }
  }

  const cleanUp = () => {
    setBleName("")
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={validate}>
        <Modal.Body>
          <Form.Group>
            <div>
              <Form.Label>{props.body}</Form.Label>
            </div>
            <div>
              <Form.Label>{t('display-name')}</Form.Label>
              <Form.Control type="name" onChange={(e) => setDisplayName(e.target.value)} value={displayName}
                            placeholder={t("display-name")}/>
            </div>
            <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px'}}>
              {nameError}
            </div>
            <div>
              <Form.Label>{t('lora-id')}</Form.Label>
              <Form.Control type="name" onChange={(e) => setLoraId(e.target.value)} value={loraId}
                            placeholder={t("lora-id")}/>
            </div>
            <div style={{color: "transparent", marginBottom:'10px'}}>
              {""}
            </div>
            <div>
              <Form.Label>{t('ble-name')}</Form.Label>
              <Form.Control autoFocus type="name" onChange={(e) => setBleName(e.target.value)} value={bleName}
                            placeholder={t("ble-name")}/>
            </div>
            <div style={{color: "transparent", marginBottom:'10px'}}>
              {""}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {props.showCancel && <Button variant="dark" onClick={handleClose}>
            {t("cancel")}
          </Button>}
          <Button variant="danger" type={'submit'}>
            {t("ok")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )

}