import React, {useState} from 'react'
import classNames from 'classnames'

export default function TouchableOpacity({ className, children, ...rest }: TTouchableOpacityProps) {
  const [touched, touchedSet] = useState(false)
  const [hover, setHover] = useState(false)

  return (
    <button
      className={classNames(className)}
      type="button"
      style={{ opacity: touched ? 0.5 : 1, color: hover ? "darkslategrey": "black", transition: 'opacity 300ms ease',
        borderColor: hover ? "darkslategrey" : "black"}}
      onMouseDown={() => touchedSet(true)}
      onMouseUp={() => touchedSet(false)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...rest}>
      {children}
    </button>
  )
}