import React, {useEffect, useState} from 'react';
import LoadingElement from "../elements/LoadingElement";
import {NavSidebarDashboard} from "../components/NavSidebarDashboard";
import {RouteComponentProps} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faWrench} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";


const ActionsOverview = (props: RouteComponentProps<Empty>) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setLoading(false)

  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarDashboard {...props}/>
      <div className="App-header">
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: '30px'}}>
            <FontAwesomeIcon icon={faExclamationTriangle} size={"5x"}/>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <FontAwesomeIcon icon={faWrench} size={"2x"} style={{marginRight: '30px'}}/>
            <h2>{t("under-construction")}</h2>
            <FontAwesomeIcon icon={faWrench} size={"2x"} style={{marginLeft: '30px', transform: 'rotate(270deg)'}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionsOverview;