import React from 'react';
import {NavSidebarDashboard} from "../components/NavSidebarDashboard";
import {RouteComponentProps} from "react-router";

const Dashboard = (props: RouteComponentProps<Empty>) => {

  return (
    <div className="Dashboard">
      <NavSidebarDashboard {...props}/>
      <div className="App-header">
        <h2>{"Dashboard"}</h2>
      </div>
    </div>
  )
}

export default Dashboard;