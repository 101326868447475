import React, {useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button, FormCheck} from "react-bootstrap";

type UsersProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Users = (props:UsersProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = [];
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(initRows);
  const history = useHistory();

  const isSuperAdmin = (props.userData.role === 'super-admin' || props.userData.role === 'staff');
  const isAdmin = (props.userData.role === 'admin') || isSuperAdmin;

  const loadUsers = async () => {
    const db = getFirestore();
    const id = props.match.params.id;
    const projectType = props.projectMap.get(id)?.type;
    const clientId = sessionStorage.getItem("clientId");

    let queryUsers;

    if (projectType === 'corporate') {
      queryUsers = await query(collection(db, "users"),
          where("projectId", "==", id),
          orderBy("registrationDate", "desc"), limit(500));
    } else { // tourism project
      queryUsers = await query(collection(db, "users"),
          where("isGlobal", "==", true),
          where("clientId", "==", clientId),
          orderBy("registrationDate", "desc"), limit(1000));
    }

    const querySnapshot = await getDocs(queryUsers);
    const localRows : DocumentData[] = [];
    let i = 0;

    for (const user of querySnapshot.docs) {
      i++;
      localRows.push({'id': i, 'userId': user.id, ...user.data()})
    }

    setRows(localRows);

    return 0;
  }

  const switchHasPayment = (userId: string, hasPayment : boolean) => {
    const db = getFirestore();
    setDoc(doc(db, "users", userId), {
      hasPaymentMethod: !hasPayment
    }, {merge: true})
      .then(() => {
        loadUsers();
      });
  }

  const switchIsAdmin = (userId: string, isAdmin : boolean) => {
    const clientId = sessionStorage.getItem("clientId");
    const db = getFirestore();
    setDoc(doc(db, "users", userId), {
      isAdmin: !isAdmin,
      clientId: clientId
    }, {merge: true})
      .then(() => {
        loadUsers();
      });
  }

  const tableHead = <tr>
    <th>{"#"}</th>
    <th>{t("id")}</th>
    <th>{t("email")}</th>
    <th>{t("phone")}</th>
    <th>{t("first-name")}</th>
    <th>{t("last-name")}</th>
    <th>{t('registration-date')}</th>
    <th>{t('payment')}</th>
    {isSuperAdmin && <th>{t("admin")}</th>}
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    const userUrl = '/projects/'+props.match.params.id+'/users/details?id='+value.userId;
    const userId = <Button variant={"link"} size={"sm"} onClick={() => {history.push(userUrl)}}>{value.userId.substring(0,5)}</Button>;
    const registrationDate = new Date(value.registrationDate).toLocaleDateString();
    const registrationTime = new Date(value.registrationDate).toLocaleTimeString();
    const hasPaymentMethod = <div className="switch-button-item">
      <FormCheck type={"switch"} checked={value.hasPaymentMethod} onChange={() => switchHasPayment(value.userId, value.hasPaymentMethod)}/>
      {(value.hasPaymentMethod) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
    </div>
    const isAdminCheck = <div className="switch-button-item">
      <FormCheck type={"switch"} checked={value.isAdmin} onChange={() => switchIsAdmin(value.userId, value.isAdmin)}/>
      {(value.isAdmin) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
    </div>

    return (
        <tr key={value.id} style={{alignItems:'center'}}>
          <td>{value.id}</td>
          <td>{userId}</td>
          <td>{(isAdmin) ? value.email : t("hidden")}</td>
          <td>{(isAdmin) ? value.phone : t("hidden")}</td>
          <td>{(isAdmin) ? value.firstName : t("hidden")}</td>
          <td>{(isAdmin) ? value.lastName : t("hidden")}</td>
          <td>{registrationDate}{<br/>}{registrationTime}</td>
          <td>{hasPaymentMethod}</td>
          {isSuperAdmin && <td>{isAdminCheck}</td>}
        </tr>
    )
  });

  useEffect(() => {
    setLoading(true)
    loadUsers()
        .finally(() => {
          setLoading(false)
        })
  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                          projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
      <div className="App-header">
        <div className="App-box">
          <div className="App-title">
            {t("users")}
          </div>
          <div className="App-table">
            <div className="table-responsive">
              <table className="table" id="project-table">
                <thead>{tableHead}</thead>
                <tbody>{tableBody}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users;