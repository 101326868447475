import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {collection, DocumentData, getDocs, getFirestore, query, where} from "@firebase/firestore";
import {getAuth, signOut} from "firebase/auth";
import TouchableOpacity from "../elements/TouchableOpacity";
import {Link} from 'react-router-dom';
import CreateProjectModal from "../elements/CreateProjectModal";
import CreateOperatorModal from "../elements/CreateOperatorModal";
import {useTranslation} from 'react-i18next';
import i18next from "i18next";
import UserProfileModal from "../elements/UserProfileModal";
import {getMainLogo} from "../utils/MainLogo";

type NavTopbarProps = {
  setLoggedIn: (args: boolean) => void;
  projectMap: Map<string,DocumentData>;
  operatorMap: Map<string,DocumentData>;
  userData: Record<string, string>;
}

export const NavTopbar = (props: NavTopbarProps) => {

  const { t } = useTranslation();
  const [projectMap, setProjectMap] = useState(new Map());
  const [operatorMap, setOperatorMap] = useState(new Map());
  const [showProjects, setShowProjects] = useState(false);
  const [showOperators, setShowOperators] = useState(false);
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [showCreateOperator, setShowCreateOperator] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);


  const logout = () => {
    const auth = getAuth();

    signOut(auth).then(() => {
      console.log("logout")
      sessionStorage.removeItem("userEmail");

    }).catch((error) => {
      console.log(error)
    });

  }

  const loadData = async () => {
    const db = getFirestore();
    let queryProjects;

    if (props.userData.role == 'super-admin' || props.userData.role == 'staff') {
      const clientId = sessionStorage.getItem("clientId");
      const queryProjectPerClient = await query(collection(db, "projects"),
        where("clientId", "==", clientId), where("active", "==", true));
      queryProjects = await getDocs(queryProjectPerClient);

    } else {
      const operatorId = props.userData.operatorId;
      queryProjects = await getDocs(collection(db, "operators/" + operatorId + "/projects"));
    }

    if (queryProjects) {
      queryProjects.forEach((doc) => {
        setProjectMap(new Map(projectMap.set(doc.id, doc.data())));
      });
    }

    const queryOperatorsPerClient = await query(collection(db, "operators"),
      where("clientId", "==", clientId), where("active", "==", true));
    const queryOperators = await getDocs(queryOperatorsPerClient);

    queryOperators.forEach((doc) => {
      setOperatorMap(new Map(operatorMap.set(doc.id, doc.data())));
    });
  }

  const showDropdownOperators = (e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    setShowOperators(!showOperators);
  }
  const hideDropdownOperators = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShowOperators(false);
  }

  const showDropdownProjects = (e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    setShowProjects(!showProjects);
  }
  const hideDropdownProjects = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShowProjects(false);
  }

  const showDropdownLanguageMenu = (e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    setShowLanguageMenu(!showLanguageMenu);
  }
  const hideDropdownLanguageMenu = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShowLanguageMenu(false);
  }

  const handleConfirmCreateProject = () => {
    setShowCreateProject(false);
  }

  const isStaff = (props.userData.role == 'staff');
  const isSuperAdmin = (props.userData.role == 'super-admin') || isStaff;
  const isAdmin = (props.userData.role == 'admin') || isSuperAdmin;
  const isOperator = (props.userData.role == 'operator') || isAdmin;
  const clientId = (props.userData.role == 'staff') ? sessionStorage.getItem("clientId") : props.userData.clientId;

  useEffect(() => {
    loadData()
        .then(() => {
          console.log("done");
        })
  }, [])

  return (
    <Navbar expand="lg" className="basic-navbar">
      <CreateProjectModal show={showCreateProject} title={t("create-project-title")}
                          body={t("create-project-body")} handleClose={() => setShowCreateProject(false)}
                          handleConfirm={handleConfirmCreateProject} showCancel={true}/>
      <CreateOperatorModal show={showCreateOperator} title={t("create-operator-title")}
                           body={t("create-operator-body")} handleClose={() => setShowCreateOperator(false)}
                           handleConfirm={() => setShowCreateOperator(false)} showCancel={true}/>
      <UserProfileModal show={showUserProfile} title={t("user-profile-title")} body={t("user-profile-body")}
                        handleClose={() => setShowUserProfile(false)} userData={props.userData}
                        handleConfirm={() => setShowUserProfile(false)} showCancel={true}/>
      <Container fluid>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-start">
          <Navbar.Brand>
            <img
              src={getMainLogo()}
              height="30"
              className="d-inline-block align-top"
              alt="Rent-a-Bike logo"
            />
          </Navbar.Brand>
          <Nav>
              <Nav.Link as={Link} to={'/dashboard/trips'}>
                <div className={"navbar-button-text"}>
                  {t("overview")}
                </div>
              </Nav.Link>
            <NavDropdown show={showProjects} onMouseEnter={showDropdownProjects} onMouseLeave={hideDropdownProjects}
                         onSelect={() => setShowProjects(false)} title={t("projects")} id="basic-nav-dropdown"
                         className="basic-nav-dropdown">
              <NavDropdown.Header className="basic-nav-dropdown-header"/>
              <div className="scroll-nav-dropdown">
                <NavDropdown.Divider />
                {[...projectMap.keys()].map((k) => {
                  const name = projectMap.get(k)?.name;
                  return (<NavDropdown.Item key={k} as={Link} to={{
                    pathname: "/projects/" + k + '/dashboard',
                    state: {id: k}
                  }}>{name}</NavDropdown.Item>)
                })}
                <NavDropdown.Divider />
                {isSuperAdmin && <NavDropdown.Item onClick={() => setShowCreateProject(true)}>
                  {t("create-project")}</NavDropdown.Item>}
              </div>
            </NavDropdown>
            {isAdmin && <NavDropdown show={showOperators} onMouseEnter={showDropdownOperators} onMouseLeave={hideDropdownOperators}
                         onSelect={() => setShowOperators(false)} title={t("operators")} id="basic-nav-dropdown" className="basic-nav-dropdown">
              <NavDropdown.Header className="basic-nav-dropdown-header"/>
              <div className="scroll-nav-dropdown">
                <NavDropdown.Divider />
                {[...operatorMap.keys()].map((k) => {
                  if (props.userData.operatorId == k || isSuperAdmin) {
                    const name = operatorMap.get(k)?.name;
                    return (<NavDropdown.Item key={k} as={Link} to={"/operators/" + k + "/dashboard"}>{name}</NavDropdown.Item>)
                  }
                })}
                <NavDropdown.Divider />
                {isSuperAdmin && <NavDropdown.Item onClick={() => setShowCreateOperator(true)}>{t("create-operator")}</NavDropdown.Item>}
              </div>
            </NavDropdown>}
            {isSuperAdmin && <Nav.Link as={Link} to={'/admin/' + clientId + '/users'}>
              <div className={"navbar-button-text"}>
                {t("super-admin-menu")}
              </div>
            </Nav.Link>}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <div className={"app-title"}>
            {t("title")}
          </div>
        </Navbar.Collapse>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link onClick={() => setShowUserProfile(true)}>
              <div className={"navbar-button"}>
                <FontAwesomeIcon icon={faUser} />
                <div className={"navbar-button-text"}>
                  {t("profile")}
                </div>
              </div>
            </Nav.Link>
            <NavDropdown show={showLanguageMenu} onMouseEnter={showDropdownLanguageMenu} onMouseLeave={hideDropdownLanguageMenu}
                         onSelect={() => setShowLanguageMenu(false)} title={t("language")} id="basic-nav-dropdown"
                         className="basic-nav-dropdown">
              <NavDropdown.Header className="basic-nav-dropdown-header"/>
              <div className="scroll-nav-dropdown">
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => i18next.changeLanguage('de')}>{t('german')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => i18next.changeLanguage('en')}>{t('english')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => i18next.changeLanguage('fr')}>{t('french')}</NavDropdown.Item>
                <NavDropdown.Divider />
              </div>
            </NavDropdown>
            <Nav.Link href="/login">
              <TouchableOpacity onClick={logout} className="logout-button">
                <div className={"navbar-button"}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  <div className={"navbar-button-text"}>
                    {t("logout")}
                  </div>
                </div>
              </TouchableOpacity>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )

}