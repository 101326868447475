import React, {useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {doc, getDoc, getFirestore} from "@firebase/firestore";
import DateTimePicker from 'react-datetime-picker'
import i18next from "i18next";
import {getDurationFormat} from "../utils/DateTimeUtil";

type TerminateTripModalProps = {
  show: boolean,
  tripId: string,
  reservationId: string,
  start: number,
  handleClose: () => void;
  handleConfirm: (arg0: number) => void;
}

export default function TerminateTripModal(props:TerminateTripModalProps) {

  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(props.start);
  const [reservationStop, setReservationStop] = useState(Date.now());
  const [stopDate, setStopDate] = useState(new Date());
  const [languageCode, setLanguageCode] = useState('de-DE');
  const [duration, setDuration] = useState("");
  const { t } = useTranslation();

  const loadReservationData = (reservationId:string) => {
    const db = getFirestore();
    setLoading(true);
    getDoc(doc(db, 'reservations', reservationId))
      .then(async (documentData) => {
        const data = documentData.data();
        if (data) {
          setReservationStop(data.stop);
          setStopDate(new Date(data.stop));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    setStart(props.start)
    if (props.show) {
      if(props.reservationId != null) {
        loadReservationData(props.reservationId);
      } else {
        setStopDate(new Date())
        setReservationStop(Date.now())
      }
    }
  }, [props.tripId])

  useEffect(() => {
    setDuration(getDurationFormat(stopDate.getTime() - start, t('day')))
  }, [props.tripId, stopDate])

  useEffect(() => {
    const language = i18next.language;
    switch (language) {
      case 'en':
        setLanguageCode('en-GB');
        break;
      case 'de':
        setLanguageCode('de-DE');
        break;
      case 'fr':
        setLanguageCode('fr-FR');
        break;
    }
  }, [])

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('terminate-trip-title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('terminate-trip-body')}</Modal.Body>
      {!loading && <Modal.Body>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('start')}{":"}</div>
          <div className={"modal-item left"}>{(new Date(start)).toLocaleDateString()}{" - "}{(new Date(start)).toLocaleTimeString()}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('reservation-stop')}{":"}</div>
          <div className={"modal-item left"}>{(new Date(reservationStop)).toLocaleDateString()}{" - "}{(new Date(reservationStop)).toLocaleTimeString()}</div>
        </div>
        <div style={{display:'flex', margin:20, justifyContent:'center'}}>
          <DateTimePicker locale={languageCode} onChange={setStopDate} value={stopDate} minDate={new Date(start)}/>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('selected-date')}{":"}</div>
          <div className={"modal-item left"}>{(new Date(stopDate)).toLocaleDateString()}{" - "}{(new Date(stopDate)).toLocaleTimeString()}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('duration')}{":"}</div>
          <div className={"modal-item left"}>{duration}</div>
        </div>
      </Modal.Body>}

      <Modal.Footer>
        <Button variant="dark" onClick={props.handleClose}>
          {t("cancel")}
        </Button>
        <Button variant="danger" onClick={() => props.handleConfirm(stopDate.getTime())}>
          {t("ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  )

}