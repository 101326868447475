import React from 'react'
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

type BasicModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
}

export default function BasicModal(props:BasicModalProps) {

  const { t } = useTranslation();

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        {props.showCancel && <Button variant="dark" onClick={props.handleClose}>
          {t("cancel")}
        </Button>}
        <Button variant="danger" onClick={props.handleConfirm}>
          {t("ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  )

}