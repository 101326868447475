import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {DocumentData} from "@firebase/firestore";

type ChangeReservationVehicleModalProps = {
  show: boolean,
  station: string,
  vehicle: string,
  otherVehicles: DocumentData[],
  handleClose: () => void;
  handleConfirm: (args: string) => void;
}

export default function ChangeReservationVehicleModal(props:ChangeReservationVehicleModalProps) {

  const [selectedVehicle, setSelectedVehicle] = useState(props.otherVehicles[0]?.id);
  const { t } = useTranslation();

  const onSelectNewVehicle = (event: ChangeEvent<HTMLSelectElement>) => {
    const vehicleId = event.target.options[event.target.options.selectedIndex].getAttribute('value');
    setSelectedVehicle(vehicleId);
  }

  const onConfirmChange = () => {
    props.handleConfirm(selectedVehicle);
  }

  useEffect(() => {
    setSelectedVehicle(props.otherVehicles[0]?.id);
  }, [props.otherVehicles])

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('changing-reservation-title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('changing-reservation-body')}</Modal.Body>
      <Modal.Body>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('station')}{":"}</div>
          <div className={"modal-item left"}>{props.station}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('current-vehicle')}{":"}</div>
          <div className={"modal-item left"}>{props.vehicle}</div>
        </div>
        <div className={"modal-container"}>
          <div className={"modal-item right"}>{t('new-vehicle')}{":"}</div>
          <div className={"modal-item left"}>
            <Form.Select size={"sm"} aria-label="reason"
                         onChange={(event) => onSelectNewVehicle(event)}>
              {props.otherVehicles.map((vehicle) => {
                if (vehicle.station == props.station && vehicle.name != props.vehicle && vehicle.available) {
                  return <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.name}{" ("}{vehicle.station}{")"}</option>
                }
              })}
            </Form.Select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={props.handleClose}>
          {t("cancel")}
        </Button>
        <Button variant="danger" onClick={onConfirmChange}>
          {t("ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  )

}