import {RouteComponentProps, useHistory} from "react-router-dom";
import React, {useState} from "react";
import {Navigation} from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFile, faTools} from '@fortawesome/free-solid-svg-icons'
import '../App.css';
import {useTranslation} from "react-i18next";

type NavSidebarAdminProps = {
  name: string;
  userData: Record<string, unknown>

} & RouteComponentProps<Identifier>

export const NavSidebarAdmin = (props:NavSidebarAdminProps) => {
  const [clientId, setClientId] = useState(props.match.params.id)
  const [clientName, setClientName] = useState(props.name)
  const history = useHistory();
  const { t } = useTranslation();

  const isSuperAdmin = (props.userData.role == 'super-admin');

  const items = []

  if (isSuperAdmin) {
    items.push ({
      title: t('members'),
      itemId: '/admin/' + clientId + '/users',
      // you can use your own custom Icon component as well
      // icon is optional
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faFile}/>
      </div>
    })
  }

  return (
      <div className="App-sidebar">
        <div className="navsidebar-title">
          <div className="navsidebar-title-icon">
            <FontAwesomeIcon icon={faTools} style={{marginRight:'15px'}}/>
            {clientName}
          </div>
        </div>
        <div
            className={`fixed inset-y-0 left-0 z-30 w-10 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
                "ease-in -translate-x-full"
            }`}
        >
          <React.Fragment>
            <Navigation
                // you can use your own router's api to get pathname
                activeItemId={props.match.url}
                onSelect={({itemId}) => {
                  if (clientId) {
                    history.push(itemId)
                  }
                }}
                items={items}
            />
          </React.Fragment>
        </div>
      </div>
  )
}