import React, {ChangeEvent, useEffect, useState} from 'react';
import LoadingElement from "../elements/LoadingElement";
import {RouteComponentProps} from "react-router";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {useTranslation} from "react-i18next";
import {Navigation} from "react-minimal-side-navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBicycle, faCheckCircle, faPlusCircle, faTimesCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import {getDurationFormat} from "../utils/DateTimeUtil";
import {Button, Form, FormCheck, ListGroup, Tab, Tabs} from "react-bootstrap";
import MapComponent from "../components/MapComponent";
import TouchableOpacity from "../elements/TouchableOpacity";
import AddServiceModal from "../elements/AddServiceModal";
import BasicModal from "../elements/BasicModal";


type VehiclesDetailsProps = {
  projectMap: Map<string, DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const VehiclesDetails = (props: VehiclesDetailsProps) => {

  const {t} = useTranslation();
  const initRows: SideMenu[] = [];
  const initTripRows: DocumentData[] = [];
  const init : DocumentData  = {};
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(props.match.params.id);
  const [rows, setRows] = useState(initRows);
  const [vehicleId, setVehicleId] = useState("");
  const [tripRows, setTripRows] = useState(initTripRows);
  const [serviceRows, setServiceRows] = useState(initTripRows);
  const [vehicleData, setVehicleData] = useState(init);
  const [key, setKey] = useState("trips");
  const [showAddService, setShowAddService] = useState(false);
  const [showDeleteService, setShowDeleteService] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [lockUrl, setLockUrl] = useState("");
  const [lockMap, setLockMap] = useState(new Map());
  const history = useHistory();

  const loadVehicles = async () => {
    const db = getFirestore();
    const id = props.match.params.id;
    setRows(initRows)

    const queryLocks = await query(collection(db, "locks"),
      where("projectId", "==", id), where("active", "==", true));

    const lockSnapshot = await getDocs(queryLocks);
    const lockMapTemp = new Map();
    for (const lock of lockSnapshot.docs) {
      lockMapTemp.set(lock.get("loraId"), {id: lock.id, ...lock.data()});
    }
    setLockMap(lockMapTemp);

    const queryVehicles = await query(collection(db, "vehicles"),
        where("projectId", "==", id), where("active", "==", true),
        orderBy("name", "asc"), limit(500));

    const querySnapshot = await getDocs(queryVehicles);

    for (const vehicle of querySnapshot.docs) {
      const vehicleData = vehicle.data();
      rows.push(
          {
            title: vehicleData.name,
            itemId: vehicle.id,
            elemBefore: () => <div><FontAwesomeIcon icon={faBicycle}/></div>,
          })
    }
    setRows([...rows]);
    return 0;
  }

  const loadVehicleTrips = async (vehicleId: string) => {

    const db = getFirestore();
    const id = props.match.params.id;

    const vehicle = await getDoc(doc(db, "vehicles", vehicleId));
    const data = vehicle.data();
    if (data) {
      setVehicleData({id:vehicle.id, ...data});
      if (lockMap.get(data.lockId)) {
        const lockData = lockMap.get(data.lockId);
        const lockUrl = '/projects/'+props.match.params.id+'/locks/details?id='+lockData.id;
        setLockUrl(lockUrl);
      }
    }

    const queryOperators = await query(collection(db, "trips"),
        where("projectId", "==", id), where("vehicleId", "==", vehicleId),
        orderBy("start", "desc"), limit(500));
    const querySnapshot = await getDocs(queryOperators);
    const localRows: DocumentData[] = [];

    for (const trip of querySnapshot.docs) {
      localRows.push({'id': trip.id, 'vehicleId': vehicleId, 'vehicleName': vehicleData?.name, ...trip.data()})
    }

    setTripRows(localRows);
    return 0;
  }

  const tripTableHead = <tr>
    <th>{t("date")}</th>
    <th>{t("user")}</th>
    <th>{t("start-station")}</th>
    <th>{t("start")}</th>
    <th>{t("end")}</th>
    <th>{t("duration")}</th>
    <th>{t("stop-station")}</th>
  </tr>

  const tripTableBody = tripRows.map((value: DocumentData) => {
    const userUrl = '/projects/'+props.match.params.id+'/users/details?id='+value.userId;
    const duration = (value.stop) ? getDurationFormat(value.stop - value.start, t('day')) : ""
    const user = (value.userId).substring(0, 5);
    const startDate = new Date(value.start).toLocaleDateString();
    const startTime = new Date(value.start).toLocaleTimeString();
    const stopDate = (value.stop) ? new Date(value.stop).toLocaleDateString() : "-";
    const stopTime = (value.stop) ? new Date(value.stop).toLocaleTimeString() : "";
    const lateReturn = value.lateReturn;
    const startStation = value.startStation;
    const stopStation = value.stopStation;

    return (
        <tr key={value.id} style={{alignItems: 'center'}}>
          <td>{startDate}</td>
          <td><Button variant="link" size={'sm'}
                      onClick={() => history.push(userUrl)}>{user}</Button></td>
          <td>{startStation}</td>
          <td>{startDate}{<br/>}{startTime}</td>
          <td>{stopDate}{<br/>}{stopTime}</td>
          <td>{duration}</td>
          <td>{stopStation}</td>
        </tr>)
  })

  const loadVehicleServices = async (vehicleId: string) => {
    const db = getFirestore();
    const id = props.match.params.id;

    const queryOperators = await query(collection(db, "services"),
        where("projectId", "==", id), where("vehicleId", "==", vehicleId),
        where("active", "==", true), orderBy("timestamp", "desc"),
        limit(500));
    const querySnapshot = await getDocs(queryOperators);
    const localRows: DocumentData[] = [];

    for (const service of querySnapshot.docs) {
      localRows.push({'id': service.id, ...service.data()})
    }

    setServiceRows(localRows);
    return 0;
  }

  const serviceTableHead = <tr>
    <th>{t("date")}</th>
    <th>{t("user")}</th>
    <th style={{width: '60%'}}>{t("remarks")}</th>
    <th>{t("delete")}</th>
  </tr>

  const serviceTableBody = serviceRows.map((value: DocumentData) => {
    const user = (value.createdBy);
    const date = new Date(value.timestamp).toLocaleDateString();
    const remarks = value.remarks;

    return (
        <tr key={value.id} style={{alignItems: 'center'}}>
          <td>{date}</td>
          <td>{user}</td>
          <td>{remarks}</td>
          <td>{<TouchableOpacity className="table-button" onClick={() => handleShowDelete(value.id)}>
            <FontAwesomeIcon icon={faTrashAlt}/></TouchableOpacity>}
          </td>
        </tr>)
  })

  const handleShowDelete = (id : string) => {
    setShowDeleteService(true);
    setSelectedService(id);
  }

  const handleConfirm = () => {
    setShowDeleteService(false);
    const db = getFirestore();
    const ref = doc(db, 'services', selectedService);
    setDoc(ref, {active: false}, {merge: true});
    setLoading(true)
    loadVehicleServices(vehicleId)
        .finally(() => {
          setLoading(false)
          setSelectedService("");
        })

  }

  const handleConfirmAddService = () => {
    setShowAddService(false);
    loadVehicleTrips(vehicleId)
      .finally(() => {
        setLoading(false)
      });
  }

  const switchAvailability = (vehicleId:string, isAvailable:boolean) => {
    const db = getFirestore()
    const ref = doc(db, 'vehicles', vehicleId);
    setLoading(true);
    const newData = isAvailable ? {available: !isAvailable} : {available: !isAvailable, blockedReason: "", blockedUntil: ""}
    setDoc(ref, newData, {merge: true})
      .then(() => {
        loadVehicleTrips(vehicleId)
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  const onChangeBlockedReason = (event:ChangeEvent<HTMLSelectElement>, vehicleId: string) => {
    const db = getFirestore()
    const ref = doc(db, 'vehicles', vehicleId);
    const reason = event.target.value;
    let blockedUntil;
    setLoading(true);
    switch (reason) {
      case 'battery':
        blockedUntil = Date.now() + 3 * 24 * 60 * 60 * 1000;
        break;
      case 'maintenance':
        blockedUntil = Date.now() + 7 * 24 * 60 * 60 * 1000;
        break;
      default:
        blockedUntil = null;
    }
    setDoc(ref, {blockedReason: reason, blockedUntil: blockedUntil}, {merge: true})
      .then(() => {
        loadVehicleTrips(vehicleId)
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  useEffect(() => {
    const searchParam = "?id=";
    const str = props.location.search.toString();
    const vehicleId = str.substring(searchParam.length);

    setLoading(true);
    loadVehicles()
      .finally(() => {
        setLoading(false);
        })
  }, [])

  useEffect(() => {
    const searchParam = "?id=";
    const str = props.location.search.toString();
    const vehicleId = str.substring(searchParam.length);
    setLoading(true);
    if (vehicleId != "") {
      loadVehicleTrips(vehicleId)
        .then(() => {
          loadVehicleServices(vehicleId)
            .finally(() => {
              setVehicleId(vehicleId)
              setLoading(false)
            })
        })
    } else {
      setLoading(false)
    }
  }, [lockMap]);

  return (
      <div className="Dashboard">
        <LoadingElement loading={loading}/>
        <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                            projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
        <BasicModal show={showDeleteService} title={t('delete-service-title')} body={t('delete-service-body')}
                    handleClose={() => setShowDeleteService(false)} handleConfirm={handleConfirm} showCancel={true}/>
        <AddServiceModal show={showAddService} title={t('add-service-title')} body={t('add-service-body')}
                         handleClose={() => setShowAddService(false)} handleConfirm={handleConfirmAddService}
                         showCancel={true} projectId={projectId} vehicleId={vehicleId}/>
        <div className="App-header">
          <div className="App-side-menu">
            <div className="App-side-menu-title">
              {t("vehicles")}
            </div>
            <div className="App-side-menu-body">
              <React.Fragment>
                <Navigation
                    // you can use your own router's api to get pathname
                    activeItemId={props.location.search.substr(4)}
                    onSelect={({itemId}) => {
                      if (projectId) {
                        setLoading(true)
                        setVehicleId(itemId)
                        const url = '/projects/' + projectId + '/vehicles/details?id=' + itemId;
                        loadVehicleTrips(itemId)
                            .then(() => {
                                loadVehicleServices(itemId)
                                    .finally(() => {
                                      setLoading(false)
                                    })
                              })
                        history.push(url)
                      }
                    }}
                    items={rows}
                />
              </React.Fragment>
            </div>
          </div>
          {vehicleId != "" && <div className="App-box-small">
            <div className="App-box-upper">
              <div className="App-list-group">
                <ListGroup>
                  <ListGroup.Item><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('name')}{": "}</div>
                    <div className="App-list-group-item-2">
                      {vehicleData.name}{" ("}{t(vehicleData.type)}{")"}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('station')}{": "}</div>
                    <div className="App-list-group-item-2">
                      {vehicleData.station == 'on-the-road' ? t(vehicleData.station) : vehicleData.station}
                    </div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('brand')}{": "}</div>
                    <div className="App-list-group-item-2">{vehicleData.brand}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('serial-number')}{": "}</div>
                    <div className="App-list-group-item-2">{vehicleData.serialNumber}</div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('lock')}{": "}</div>
                    <div className="App-list-group-item-2">
                      <Button variant={'link'} onClick={() => {history.push(lockUrl)}} size={"sm"}>
                        {vehicleData.lockId}</Button></div></div></ListGroup.Item>
                  <ListGroup.Item as="li"><div className="App-list-group-item">
                    <div className="App-list-group-item-1"> {t('available')}{": "}</div>
                    <div className="App-list-group-item-2">
                      <div className="switch-button-item">
                        <FormCheck type={"switch"} checked={vehicleData.available} onChange={() => switchAvailability(vehicleId, vehicleData.available)}/>
                        {(vehicleData.available) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
                        {!vehicleData.available &&
                          <div style={{marginLeft:20}}>
                            <Form.Select size={"sm"} aria-label="reason" defaultValue={vehicleData.blockedReason}
                                         onChange={(event) => onChangeBlockedReason(event, vehicleData.id)}>
                              <option value="">{""}</option>
                              <option value="broken">{t('broken')}</option>
                              <option value="battery">{t('battery-3days')}</option>
                              <option value="maintenance">{t('maintenance')}</option>
                            </Form.Select>
                          </div>
                        }
                      </div>
                    </div></div></ListGroup.Item>
                </ListGroup>
              </div>
              <div className="App-map">
                <MapComponent markers={[{id:1, type:'vehicle', position:{'lat':vehicleData.position?.latitude, 'lng': vehicleData.position?.longitude}}]}/>
              </div>
            </div>
            <Tabs defaultActiveKey="current" id="uncontrolled-tab-example" className="mb-3" transition={true}
                  style={{marginLeft: '40px', marginTop: '10px'}}
                  activeKey={key}
                  onSelect={(k) => {return k ? setKey(k.toString()) : setKey("")}}>
              <Tab eventKey="trips" title={t('trips')}>
                <div className="App-table" style={{height: '40vh'}}>
                  <div className="table-responsive" style={{height: '40vh'}}>
                    <table className="table" id="project-table">
                      <thead>{tripTableHead}</thead>
                      <tbody>{tripTableBody}</tbody>
                    </table>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="services" title={t('services')}>
                <div className="App-button">
                  <Button variant="danger" onClick={() => setShowAddService(true)}>
                    <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
                    {t("new-service")}
                  </Button>
                </div>
                <div className="App-table" style={{height: '40vh'}}>
                  <div className="table-responsive" style={{height: '40vh'}}>
                    <table className="table" id="project-table">
                      <thead>{serviceTableHead}</thead>
                      <tbody>{serviceTableBody}</tbody>
                    </table>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>}
          {vehicleId == "" && <div className="App-box-small-empty">
          </div>}
        </div>
      </div>
  )
}

export default VehiclesDetails;