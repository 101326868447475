import React, {useEffect, useRef, useState} from 'react'
import {Marker} from "./MarkerComponent";

import markerIcon from "../resources/marker-tourism@3x.png";
import antennaIcon from "../resources/antenna.png";

type MapComponentProps = {
  markers?: Marker[]
}

export default function MapComponent(props: MapComponentProps) {

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const center: google.maps.LatLngLiteral = {lat: 46.735013, lng: 8.214732};

  useEffect(() => {
    if (ref.current && !map && props.markers) {
      setMap(new google.maps.Map(ref.current, {
        center,
        zoom: 6.7,
      }));
    }
  }, [ref, map]);

  const iconMarker = {
    url: markerIcon, // url
    scaledSize: new google.maps.Size(40, 40), // scaled size
    origin: new google.maps.Point(0,0), // origin
    anchor: new google.maps.Point(20, 40) // anchor
  };

  const iconAntenna = {
    url: antennaIcon, // url
    scaledSize: new google.maps.Size(40, 40), // scaled size
    origin: new google.maps.Point(0,0), // origin
    anchor: new google.maps.Point(20, 40) // anchor
  };

  return (
        <div ref={ref} style={{height: '240px', width: '500px'}}>
          {props.markers?.map((m) => {
            if (m.type == 'antenna') {
              return <Marker key={Math.random()} position={m.position} map={map} icon={iconAntenna}/>
            } else {
              return <Marker key={Math.random()} position={m.position} map={map} icon={iconMarker}/>
            }
          })}
        </div>
  );
}