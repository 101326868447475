import React, {useEffect} from "react";
import "../App.css";
import {getAuth, isSignInWithEmailLink, signInAnonymously} from "firebase/auth";
import {doc, getFirestore, setDoc} from "@firebase/firestore";
import {RouteComponentProps} from "react-router";
import {useTranslation} from "react-i18next";
import {getMainLogo} from "../utils/MainLogo";


type ConfirmEmailProps = RouteComponentProps<MagicLink>

export default function ConfirmEmail(props: ConfirmEmailProps) {

  const { t } = useTranslation();

  useEffect(() => {
    console.log(props);
    const authId = props.match.params.authId;
    console.log(authId);
    const link = window.location.href;
    const db = getFirestore();
    console.log(link);

    if(isSignInWithEmailLink(getAuth(), link)) {
      signInAnonymously(getAuth())
        .then( async () => {
          await setDoc(doc(db, "accountMagicLink", authId), {
            authId: authId,
            link: link,
            timestamp: Date.now(),
          });
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, []);

  return (
    <div className="login">
      <div className="login-box">
        <div className="login-logo">
          <img
            src={getMainLogo()}
            height="40"
            className="d-inline-block align-top"
            alt="logo"
          />
        </div>
        <div className="confirm-email">
          {t('confirm-email-message')}
        </div>
      </div>
    </div>
  );
}