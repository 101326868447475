import React, {useEffect, useState} from 'react';
import {downloadExcel} from 'react-export-table-to-excel';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {getDurationFormat} from "../utils/DateTimeUtil";
import ReservationDetailsModal from "../elements/ReservationDetailsModal";
import TerminateTripModal from "../elements/TerminateTripModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";


type TripsProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Trips = (props:TripsProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = []
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(initRows);
  const [showReservation, setShowReservation] = useState(false);
  const [showTerminateTrip, setShowTerminateTrip] = useState(false);
  const [reservationId, setReservationId] = useState("");
  const [tripId, setTripId] = useState("");
  const [tripStart, setTripStart] = useState(0);
  const history = useHistory();

  const loadTrips = async () => {
    setLoading(true);
    const db = getFirestore();
    const id = props.match.params.id;
    const vehicleMap = new Map();

    const queryVehicles = await query(collection(db, "vehicles"),
      where("projectId", "==", id));
    const snapshotVehicles = await getDocs(queryVehicles);

    for (const vehicle of snapshotVehicles.docs) {
      vehicleMap.set(vehicle.id, vehicle.data());
    }

    const queryOperators = await query(collection(db, "trips"),
        where("projectId", "==", id), orderBy("start", "desc"), limit(500));
    const querySnapshot = await getDocs(queryOperators);
    const localRows : DocumentData[] = [];
    let totalDuration = 0

    for (const trip of querySnapshot.docs) {
      const tripData = trip.data();
      const vehicleData =  (tripData.vehicleId) ? vehicleMap.get(tripData.vehicleId) : undefined;
      const vehicleId = tripData.vehicleId;

      localRows.push({
        'id': trip.id,
        'vehicleId': vehicleId,
        'vehicleName': vehicleData?.name,
        'startStation': tripData?.startStation,
        ...tripData
      })

      if (tripData.reservationId) {
        totalDuration += (tripData.stop) ? tripData.stop - tripData.start : 0
      }
    }

    localRows.push({
      'id': 'totalDuration',
      'vehicleId': '',
      'vehicleName': '',
      'startStation': '',
      'stopStation': '',
      'userId': '',
      'start': 0,
      'stop': totalDuration,
    })

    setRows(localRows);
    return 0;
  }

  const terminateTrip = (tripData:DocumentData) => {
    setReservationId(tripData.reservationId);
    setTripId(tripData.id);
    setTripStart(tripData.start);
    setShowTerminateTrip(true);
  }

  const showReservationDetails = (reservationId : string) => {
    setReservationId(reservationId);
    setShowReservation(true);
  }

  const tableHead = <tr>
    <th>{t("date")}</th>
    <th>{t("user")}</th>
    <th>{t("vehicle")}</th>
    <th>{t("start-station")}</th>
    <th>{t("start")}</th>
    <th>{t("end")}</th>
    <th>{t("duration")}</th>
    <th>{t("stop-station")}</th>
    <th>{t("termination")}</th>
    <th>{t("reservation")}</th>
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    const duration = (value.stop) ? getDurationFormat(value.stop - value.start, t('day')) : ""
    const user = (value.userId).substring(0,5);
    const vehicleName = value.vehicleName;
    const startStation = value.startStation;
    const stopStation = value.stopStation;
    const startDate = value.id === 'totalDuration' ? '' : new Date(value.start).toLocaleDateString();
    const startTime = value.id === 'totalDuration' ? '' : new Date(value.start).toLocaleTimeString();
    const stopDate = (value.stop && value.id !== 'totalDuration') ? new Date(value.stop).toLocaleDateString() : "";
    const stopTime = (value.stop && value.id !== 'totalDuration') ? new Date(value.stop).toLocaleTimeString() : "";
    const terminate = (value.stop) ? "" :
      <Button size={'sm'} variant="danger" onClick={() => terminateTrip(value)}>{t("terminate")}</Button>;
    const vehicleUrl = '/projects/'+props.match.params.id+'/vehicles/details?id='+value.vehicleId;
    const userUrl = '/projects/'+props.match.params.id+'/users/details?id='+value.userId;
    const reservation = (value.reservationId) ?
      <Button size={"sm"} variant="secondary" onClick={() => showReservationDetails(value.reservationId)}>{t("reservation")}</Button> : ""

    return (
        <tr key={value.id} style={{alignItems:'center'}}>
          {startDate ? <td>{startDate}</td> : <td><b>TOTAL</b></td>}
          <td><Button variant="link" size={'sm'}
                      onClick={() => history.push(userUrl)}>{user}</Button></td>
          <td><Button variant="link" size={'sm'}
                      onClick={() => history.push(vehicleUrl)}>{vehicleName}</Button></td>
          <td>{startStation}</td>
          <td>{startDate}{<br/>}{startTime}</td>
          <td>{stopDate}{<br/>}{stopTime}</td>
          {startDate ? <td>{duration}</td> : <td><b>{duration}</b></td>}
          <td>{stopStation}</td>
          <td>{terminate}</td>
          <td>{reservation}</td>
        </tr>)
  })

  const exportHead = [t("date"), t("user"), t("vehicle"), t("start-station"), t("start"), t("end"), t("duration"), t("stop-station")]

  const exportBody = rows.filter((value: DocumentData) => value.reservationId).map((value: DocumentData) => {
    const duration = (value.stop) ? getDurationFormat(value.stop - value.start, t('day')) : ""
    const user = (value.userId).substring(0, 5);
    const vehicleName = value.vehicleName;
    const startStation = value.startStation;
    const stopStation = value.stopStation;
    const startDate = value.id === 'totalDuration' ? '' : new Date(value.start).toLocaleDateString();
    const startTime = value.id === 'totalDuration' ? '' : new Date(value.start).toLocaleTimeString();
    const stopTime = (value.stop && value.id !== 'totalDuration') ? new Date(value.stop).toLocaleTimeString() : "";

    return (
      {
        "startDate": startDate,
        "user": user,
        "vehicleName": vehicleName,
        "startStation": startStation,
        "startTime": startTime,
        "stopTime": stopTime,
        "duration": duration,
        "stopStation": stopStation,
      }
    )
  })



  const confirmStopTime = (stopTime:number) => {
    const db = getFirestore();
    const ref = doc(db, 'trips', tripId);
    setShowTerminateTrip(false);

    setDoc(ref, {stop: stopTime}, {merge: true})
      .then(() => {
        loadTrips()
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  useEffect(() => {
    loadTrips()
        .finally(() => {
          setLoading(false)
        })
  }, [])

  function handleDownloadExcel() {
    downloadExcel({
      fileName: t("trips"),
      sheet: t("trips"),
      tablePayload: {
        header: exportHead,
        body: exportBody,
      },
    });
  }

    return (
        <div className="Dashboard">
          <LoadingElement loading={loading}/>
          <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                              projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
          <ReservationDetailsModal show={showReservation} reservationId={reservationId}
                                   handleClose={() => setShowReservation(false)}/>
          <TerminateTripModal show={showTerminateTrip} tripId={tripId}
                              reservationId={reservationId} start={tripStart}
                              handleClose={() => setShowTerminateTrip(false)}
                              handleConfirm={confirmStopTime}/>
          <div className="App-header">
            <div className="App-box">
              <div className="App-title" style={{justifyContent: 'space-between'}}>
                {t("trips")}
                <Button variant="danger" style={{marginRight: '30px'}} onClick={handleDownloadExcel}>
                  <FontAwesomeIcon icon={faFileExport} style={{marginRight: '10px'}}/>
                    {t("export-to-excel")}
                </Button>
              </div>
              <div className="App-table">
                <div className="table-responsive">
                  <table className="table" id="project-table">
                    <thead>{tableHead}</thead>
                    <tbody>{tableBody}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Trips;