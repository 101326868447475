import React, {ChangeEvent, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {generateRandomId} from "../utils/GenerateRandomId";
import {addDoc, collection, doc, getFirestore, setDoc} from "@firebase/firestore";
import {useTranslation} from "react-i18next";

type AddMemberModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
  operator: Operator;
  isSuperAdmin: boolean;
}

export default function AddMemberModal(props:AddMemberModalProps) {

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [displayEmailError, setDisplayEmailError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [displayCheckError, setDisplayCheckError] = useState(false);
  const [checkError, setCheckError] = useState("");
  const [boxChecked, setBoxChecked] = useState(props.isSuperAdmin ? new Set(["super-admin"]) : new Set());

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (email == "" || !email.includes("@")) {
      setEmailError(t("valid-email"))
      setDisplayEmailError(true)
      setTimeout(() => setDisplayEmailError(false), 5000)
    }
    if (boxChecked.size == 0) {
      setCheckError(t("must-select-role"))
      setDisplayCheckError(true)
      setTimeout(() => setDisplayCheckError(false), 5000)
    }

    //TODO: Check if an email invite has already been sent for this user
    //TODO: Check if an operatorUser already has this email.

    if (!displayEmailError && !displayCheckError) {
      //Send Invite:
      const id = generateRandomId(20);
      const operatorId = props.operator.id;
      const operatorName = props.operator.name;
      const clientId = props.operator.clientId;
      const recipient = email;
      const role = boxChecked.keys().next().value;
      const timestamp = (new Date()).getTime();
      const sender = sessionStorage.getItem("userEmail");

      await setDoc(doc(db, "accountInvite", id), {
        id: id,
        operatorId: operatorId,
        clientId: clientId,
        operatorName: operatorName,
        recipient: recipient,
        role: role,
        sender: sender,
        timestamp: timestamp,
      });

      await addDoc(collection(db, "operatorUser"),{
        active: true,
        email: recipient,
        operatorId: operatorId,
        clientId: clientId,
        role: role,
      })

      handleConfirm()
    }

  }

  const cleanUp = () => {
    setDisplayCheckError(false);
    setDisplayEmailError(false);
    setEmail("")
    setBoxChecked(props.isSuperAdmin ? new Set(["super-admin"]) : new Set())
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    const temp = new Set();
    if (e.target.checked) {
      temp.add(e.target.id)
    }
    setBoxChecked(new Set(temp));
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={validate}>
      <Modal.Body>
        <Form.Group>
        <div>
          <Form.Label>{props.body}</Form.Label>
          <Form.Control autoFocus type="email" onChange={(e) => setEmail(e.target.value)} value={email}
                        placeholder={t("email")}/>
        </div>
        <div style={{color: displayEmailError ? "red": "transparent", marginBottom:'10px'}}>
          {emailError}
        </div>
          {props.isSuperAdmin &&
              <div>
                <Form.Label>{t("select-super-admin-role")}</Form.Label>
                <Form.Check
                  style={{fontWeight: 'bold'}}
                  type={"checkbox"}
                  id={"super-admin"}
                  label={t("super-admin")}
                  onChange={(e) => handleChange(e)}
                  checked={boxChecked.has('super-admin')}
                />
              </div>
          }
          {!props.isSuperAdmin &&
          <div>
            <Form.Label>{t("select-role")}</Form.Label>
            <Form.Check
              style={{fontWeight: 'bold'}}
              type={"checkbox"}
              id={"admin"}
              label={t("admin")}
              onChange={(e) => handleChange(e)}
              checked={boxChecked.has('admin')}
            />
            <Form.Check
              style={{fontWeight: 'bold'}}
              type={"checkbox"}
              id={"operator"}
              label={t("operator")}
              onChange={(e) => handleChange(e)}
              checked={boxChecked.has('operator')}
            />
            <Form.Check
              style={{fontWeight: 'bold'}}
              type={"checkbox"}
              id={"read-only"}
              label={t("read-only")}
              onChange={(e) => handleChange(e)}
              checked={boxChecked.has('read-only')}
            />
          </div>}
        <div style={{color: displayCheckError ? "red": "transparent", marginBottom:'10px'}}>
          {checkError}
        </div>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {props.showCancel && <Button variant="dark" onClick={handleClose}>
          {t("cancel")}
        </Button>}
        <Button variant="danger" type={'submit'}>
          {t("ok")}
        </Button>
      </Modal.Footer>
    </Form>
</Modal>
  )

}