import React, {useEffect, useState} from 'react';
import {NavSidebarOperators} from '../components/NavSidebarOperators'
import {RouteComponentProps} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {collection, deleteDoc, doc, DocumentData, getDoc, getDocs, getFirestore} from "@firebase/firestore";
import TouchableOpacity from "../elements/TouchableOpacity";
import BasicModal from "../elements/BasicModal";
import AddProjectModal from "../elements/AddProjectModal";
import {useTranslation} from "react-i18next";

type OperatorProjectsProps = {
    operatorMap: Map<string,DocumentData>;
    userData: Record<string, unknown>;
}
& RouteComponentProps<Identifier>

const OperatorProjects = (props: OperatorProjectsProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = []
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState(initRows);
  const [showAddProject, setShowAddProject] = useState(false);
  const [showRemoveProject, setShowRemoveProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");

  const operatorId = props.match.params.id;
  const operatorName = props.operatorMap.get(operatorId)?.name
  const clientId = props.operatorMap.get(operatorId)?.clientId
  const operator : Operator = {
    id: operatorId,
    name: operatorName,
    clientId: clientId
  }

  const loadProjects = async () => {
    const id = props.match.params.id;
    const db = getFirestore()
    const queryPath = 'operators/' + id + '/projects';
    const localRows = [];

    const queryProjects = await getDocs(collection(db, queryPath));

    for (const query of queryProjects.docs) {
      const project =  query.data();
      const snap = await getDoc(doc(db, 'projects', project.id));
      localRows.push({'id': project.id, 'timestamp': project.timestamp, ...snap.data()})
    }

    setRows(localRows);
    return 0;
  }

  const tableHead = <tr>
    <th>{t("name")}</th>
    <th>{t("type")}</th>
    <th>{t("linked-on")}</th>
    <th>{t("n-users")}</th>
    <th>{t("n-vehicles")}</th>
    <th>{t("n-stations")}</th>
    <th>{t("remove")}</th>
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    return (
        <tr key={value.id}>
          <td>{value.name}</td>
          <td>{t(value.type)}</td>
          <td>{new Date(value.timestamp).toLocaleDateString()}</td>
          <td>{value.nUsers}</td>
          <td>{value.nVehicles}</td>
          <td>{value.nStations}</td>
          <td>{<TouchableOpacity className="table-button" onClick={() => removeProject(value.id)}>
                <FontAwesomeIcon icon={faTrashAlt}/></TouchableOpacity>}
          </td>
        </tr>
    )
  })

  const removeProject = (id:string) => {
    setSelectedProject(id);
    setShowRemoveProject(true);
  }

  const handleClose = () => {
    setSelectedProject("");
    setShowRemoveProject(false);
    setShowAddProject(false);
  }

  const handleConfirm = async () => {
    const id = props.match.params.id;
    const db = getFirestore()
    const queryPath = 'operators/' + id + '/projects';
    await deleteDoc(doc(db, queryPath, selectedProject));
    setShowRemoveProject(false);
    setSelectedProject("");

    setLoading(true)
    loadProjects()
        .finally(() => {
          setLoading(false)
        })
  }

  const handleConfirmAddProject = async () => {
    setShowAddProject(false);

    setLoading(true);
    loadProjects()
        .finally(() => {
          setLoading(false)
        })
  }

  useEffect(() => {
    loadProjects()
        .finally(() => {
          setLoading(false)
        })

  }, []);

  return (
    <div className="Dashboard">
      <BasicModal show={showRemoveProject} title={t("remove-project-title")} body={t("remove-project-body")}
                  handleClose={handleClose} handleConfirm={handleConfirm} showCancel={true}/>
      <AddProjectModal show={showAddProject} title={t("add-project-title")} body={t("add-project-body")}
                       handleClose={handleClose} handleConfirm={handleConfirmAddProject}
                       showCancel={true} operator={operator}/>
      <LoadingElement loading={loading}/>
      <NavSidebarOperators name={props.operatorMap.get(props.match.params.id)?.name} {...props}/>
      <div className="App-header">
        <div className="App-box">
          <div className="App-title">
            {t("projects")}
          </div>
          <div className="App-button">
            <Button variant="danger" onClick={() => {setShowAddProject(true)}}>
              <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
              {t("add-project")}
            </Button>
          </div>
          <div className="App-table">
            <div className="table-responsive">
              <table className="table" id="project-table">
                <thead>{tableHead}</thead>
                <tbody>{tableBody}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OperatorProjects;