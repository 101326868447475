import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {addDoc, collection, getFirestore} from "@firebase/firestore";
import {useTranslation} from "react-i18next";
import DateTimePicker from "react-datetime-picker";
import i18next from "i18next";

type UserProfileModalProps = {
  show: boolean,
  projectId: string,
  handleClose: () => void;
  handleConfirm: () => void;
}

export default function AddDiscountModal(props: UserProfileModalProps) {

  const {t} = useTranslation();
  const [code, setCode] = useState("");
  const [displayCodeError, setDisplayCodeError] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [discount, setDiscount] = useState("");
  const [displayDiscountError, setDisplayDiscountError] = useState(false);
  const [discountError, setDiscountError] = useState("");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date(Date.now() + 6*30*24*60*60*1000));
  const [boxChecked, setBoxChecked] = useState(new Set(['percent']));

  const validate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (code == "") {
      setCodeError(t("valid-code"));
      setDisplayCodeError(true);
      setTimeout(() => setDisplayCodeError(false), 5000)
    }
    if (discount == "" || isNaN(parseInt(discount))) {
      setDiscountError(t("valid-discount"));
      setDisplayDiscountError(true)
      setTimeout(() => setDisplayDiscountError(false), 5000)

    }
    if (code !== "" && discount !== "" && !isNaN(parseInt(discount))) {
      //Save changes in the user profile:
      const db = getFirestore();
      const discountType = boxChecked.keys().next().value;

      addDoc(collection(db, "discounts"),
        {
          active: true,
          code: code,
          discountType: discountType,
          discount: parseInt(discount),
          from: from.getTime(),
          to: to.getTime(),
          projectId: props.projectId,
          valid: true,
        })
        .then(() => {
          console.log("done")
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          handleConfirm();
          window.location.reload();
        })
    }
  };

  const cleanUp = () => {
    setDisplayCodeError(false)
    setDisplayDiscountError(false);
    setCode("");
    setDiscount("");
    setBoxChecked(new Set(['percent']))
  }

  const handleClose = () => {
    cleanUp();
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp();
    props.handleConfirm()
  }

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    const temp = new Set<string>();
    if (e.target.checked) {
      temp.add(e.target.id)
    }
    setBoxChecked(new Set(temp));
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('add-discount-title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validate}>
          <div>
            <Form.Label>{t('add-discount-body')}</Form.Label>
          </div>
          <div style={{marginBottom: '10px'}}>
            <Form.Label>{t("code")}</Form.Label>
            <Form.Control autoFocus type="code" onChange={(e) => setCode(e.target.value)}
                          value={code} placeholder={t("code")}/>
            <div style={{color: displayCodeError ? "red": "transparent", marginBottom:'10px'}}>
              {codeError}
            </div>
          </div>
          <div style={{marginBottom: '10px', marginTop: '20px'}}>
            <Form.Label style={{fontSize:16}}>{t("select-discount-type")}</Form.Label>
            <div style={{display: 'flex', flexDirection:'row', margin: 'auto', justifyContent:'center'}}>
              <Form.Check
                  style={{fontWeight: 'bold', fontSize:16, marginRight: 10, marginLeft: 10}}
                  type={"checkbox"}
                  id={"percent"}
                  label={t("percent")}
                  onChange={(e) => handleChange(e)}
                  checked={boxChecked.has('percent')}
              />
              <Form.Check
                  style={{fontWeight: 'bold', fontSize:16, marginRight: 10, marginLeft: 10}}
                  type={"checkbox"}
                  id={"absolute"}
                  label={t("absolute")}
                  onChange={(e) => handleChange(e)}
                  checked={boxChecked.has('absolute')}
              />
              <Form.Check
                  style={{fontWeight: 'bold', fontSize:16, marginRight: 10, marginLeft: 10}}
                  type={"checkbox"}
                  id={"hours"}
                  label={t("hours")}
                  onChange={(e) => handleChange(e)}
                  checked={boxChecked.has('hours')}
              />
            </div>
          </div>
          <div style={{marginBottom: '10px'}}>
            <Form.Label>{t("discount")}
              {boxChecked.has('percent') ? " [%]" : ""}
              {boxChecked.has('absolute') ? " [CHF]" : ""}
              {boxChecked.has('hours') ? " [" + t('hours') + "]" : ""}
            </Form.Label>
            <Form.Control type="name" onChange={(e) => setDiscount(e.target.value)}
                          value={discount} placeholder={"20"}/>
            <div style={{color: displayDiscountError ? "red": "transparent", marginBottom:'10px'}}>
              {discountError}
            </div>
          </div>
          <div style={{marginBottom: '10px'}}>
            <Form.Label>{t("from")}</Form.Label>
            <div style={{display:'flex', justifyContent:'center'}}>
              <DateTimePicker locale={i18next.language} onChange={setFrom} value={from} minDate={new Date()}/>
            </div>
          </div>
          <div style={{marginBottom: '10px'}}>
            <Form.Label>{t("to")}</Form.Label>
            <div style={{display:'flex', justifyContent:'center'}}>
              <DateTimePicker locale={i18next.language} onChange={setTo} value={to} minDate={new Date()}/>
            </div>
          </div>
          <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
              {t("cancel")}
            </Button>
            <Button variant="danger" type={'submit'}>
              {t("save-changes")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  )

}