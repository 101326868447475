import React, {ChangeEvent, useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps, useHistory} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from "@firebase/firestore";
import {Button, Form, FormCheck} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faEdit, faPlusCircle, faTimesCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import TouchableOpacity from "../elements/TouchableOpacity";
import BasicModal from "../elements/BasicModal";
import AddVehicleModal from "../elements/AddVehicleModal";
import EditVehicleModal from "../elements/EditVehicleModal";


type VehiclesProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Vehicles = (props:VehiclesProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = [];
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(initRows);
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [showEditVehicle, setShowEditVehicle] = useState(false);
  const [showDeleteVehicle, setShowDeleteVehicle] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
  const [stations, setStations] = useState<Station[]>([]);
  const history = useHistory();

  const isSuperAdmin = (props.userData.role === 'super-admin' || props.userData.role === 'staff');

  const loadVehicles = async () => {
    const db = getFirestore();
    const id = props.match.params.id;
    const lockMap = new Map();

    const queryLocks = await query(collection(db, "locks"),
      where("projectId", "==", id),
      where("active", "==", true));

    const snapshot = await getDocs(queryLocks);
    for (const lock of snapshot.docs) {
      lockMap.set(lock.data().loraId, {'id': lock.id, ...lock.data()});
    }

    const queryVehicles = await query(collection(db, "vehicles"),
        where("projectId", "==", id), where("active", "==", true),
        orderBy("name", "asc"), limit(500));

    const querySnapshot = await getDocs(queryVehicles);
    const localRows : DocumentData[] = [];

    for (const vehicle of querySnapshot.docs) {
      localRows.push({'id': vehicle.id, lock: lockMap.get(vehicle.data().lockId)?.id, ...vehicle.data()})
    }

    setRows(localRows);

    return 0;
  }

  const loadStations = async () => {
    const db = getFirestore();
    const id = props.match.params.id;

    const queryStations = await query(collection(db, "stations"),
      where("projectId", "==", id), where("active", "==", true),
      orderBy("name", "asc"), limit(500));

    const querySnapshot = await getDocs(queryStations);
    const localRows : Station[] = [];

    for (const station of querySnapshot.docs) {
      localRows.push({id: station.id, name: station.get("name")})
    }

    setStations(localRows);

    return 0;
  }

  const switchAvailability = (vehicleId:string, isAvailable:boolean) => {
    const db = getFirestore()
    const ref = doc(db, 'vehicles', vehicleId);
    setLoading(true);
    const newData = isAvailable ? {available: !isAvailable} : {available: !isAvailable, blockedReason: "", blockedUntil: ""}
    setDoc(ref, newData, {merge: true})
      .then(() => {
        loadVehicles()
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  const onChangeBlockedReason = (event:ChangeEvent<HTMLSelectElement>, vehicleId: string) => {
    const db = getFirestore()
    const ref = doc(db, 'vehicles', vehicleId);
    const reason = event.target.value;
    let blockedUntil;
    setLoading(true);
    switch (reason) {
      case 'battery':
        blockedUntil = Date.now() + 3 * 24 * 60 * 60 * 1000;
        break;
      case 'maintenance':
        blockedUntil = Date.now() + 7 * 24 * 60 * 60 * 1000;
        break;
      default:
        blockedUntil = null;
    }
    setDoc(ref, {blockedReason: reason, blockedUntil: blockedUntil}, {merge: true})
      .then(() => {
        loadVehicles()
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((error)=> {
        console.log(error);
      })
  }

  const tableHead = <tr>
    <th>{t("name")}</th>
    <th>{t("type")}</th>
    <th>{t("lock")}</th>
    <th>{t("station")}</th>
    <th>{t("available")}</th>
    <th>{t("reason")}</th>
    <th>{t("blocked-until")}</th>
    {isSuperAdmin && <th>{t("edit")}</th>}
    {isSuperAdmin && <th>{t("delete")}</th>}
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    const available =
      <div className="switch-button-item">
        <FormCheck type={"switch"} checked={value.available} onChange={() => switchAvailability(value.id, value.available)}/>
        {(value.available) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
      </div>
    const lockUrl = '/projects/'+props.match.params.id+'/locks/details?id='+value.lock
    const lockId = <Button variant={"link"} size={"sm"} onClick={() => history.push(lockUrl)}>{value.lockId}</Button>;
    const vehicleUrl = '/projects/'+props.match.params.id+'/vehicles/details?id='+value.id;
    const vehicleName = <Button variant={"link"} size={"sm"} onClick={() => {history.push(vehicleUrl)}}>{value.name}</Button>;
    const station = (value.station == 'on-the-road') ? <i>{t('on-the-road')}</i> : <b>{value.station}</b>;
    const blockedUntil = (value.blockedUntil) ? new Date(value.blockedUntil).toLocaleDateString() : "";
    const vehicle: Vehicle = {
      id: value.id,
      name: value.name,
      lockId: value.lockId,
      station: value.station,
      brand: value.brand,
      serialNumber: value.serialNumber,
    }

    return (
        <tr key={value.id} style={{alignItems:'center'}}>
          <td>{vehicleName}</td>
          <td>{t(value.type)}</td>
          <td>{lockId}</td>
          <td>{station}</td>
          <td>{available}</td>
          <td>
            {!value.available &&
              <Form.Select size={"sm"} aria-label="reason" defaultValue={value.blockedReason}
                           onChange={(event) => onChangeBlockedReason(event, value.id)}>
                <option value="">{""}</option>
                <option value="broken">{t('broken')}</option>
                <option value="battery">{t('battery-3days')}</option>
                <option value="maintenance">{t('maintenance')}</option>
              </Form.Select>
            }
          </td>
          <td>{blockedUntil}</td>
          {isSuperAdmin && <td>{<TouchableOpacity className="table-button" onClick={() => handleShowEdit(value.id, vehicle)}>
            <FontAwesomeIcon icon={faEdit}/></TouchableOpacity>}
          </td>}
          {isSuperAdmin && <td>{<TouchableOpacity className="table-button" onClick={() => handleShowDelete(value.id)}>
                <FontAwesomeIcon icon={faTrashAlt}/></TouchableOpacity>}
          </td>}
        </tr>
    )
  });

  const handleClose = () => {
    setSelectedVehicleId("");
    setShowDeleteVehicle(false);
    setShowAddVehicle(false);
    setShowEditVehicle(false);
  }

  const handleConfirmDelete = () => {
    setShowDeleteVehicle(false);
    const db = getFirestore();
    const ref = doc(db, 'vehicles', selectedVehicleId);
    setLoading(true)
    setDoc(ref, {active: false}, {merge: true})
      .then(() => {
        loadVehicles()
          .finally(() => {
            setLoading(false)
            setSelectedVehicleId("");
          })
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
      });
  }

  const handleShowEdit = (id : string, vehicle: Vehicle) => {
    setSelectedVehicleId(id);
    setSelectedVehicle(vehicle);
    setShowEditVehicle(true);
  }

  const handleShowDelete = (id : string) => {
    setShowDeleteVehicle(true);
    setSelectedVehicleId(id);
  }

  const handleConfirmAddVehicle = () => {
    setShowAddVehicle(false);
    setLoading(true)
    loadVehicles()
        .finally(() => {
          setLoading(false)
          setSelectedVehicleId("");
        })
  }

  const handleConfirmEdit = () => {
    setShowEditVehicle(false);
    loadVehicles()
      .finally(() => {
        setLoading(false)
        setSelectedVehicleId("");
      })
  }

  useEffect(() => {
    setLoading(true)
    loadVehicles()
        .finally(() => {
          loadStations();
          setLoading(false)
        })
  }, [])

  return (
    <div className="Dashboard">
      <LoadingElement loading={loading}/>
      <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                          projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
      <BasicModal show={showDeleteVehicle} title={t("delete-vehicle-title")} body={t("delete-vehicle-body")}
                  handleClose={handleClose} handleConfirm={handleConfirmDelete} showCancel={true}/>
      <AddVehicleModal show={showAddVehicle} title={t('add-vehicle-title')} body={t('add-vehicle-body')}
                       handleClose={handleClose} handleConfirm={handleConfirmAddVehicle} showCancel={true}
                       projectId={props.match.params.id}/>
      <EditVehicleModal show={showEditVehicle}
                        title={t("edit-vehicle-title")} body={t("edit-vehicle-body")}
                        handleClose={handleClose}
                        handleConfirm={handleConfirmEdit} vehicle={selectedVehicle} stations={stations}/>
      <div className="App-header">
        <div className="App-box">
          <div className="App-title">
            {t("vehicles")}
          </div>
          {isSuperAdmin && <div className="App-button">
            <Button variant="danger" onClick={() => setShowAddVehicle(true)}>
              <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
              {t("new-vehicle")}
            </Button>
          </div>}
          <div className="App-table">
            <div className="table-responsive" style={{height: '70vh'}}>
              <table className="table" id="project-table">
                <thead>{tableHead}</thead>
                <tbody>{tableBody}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Vehicles;