import React, {useEffect, useRef, useState} from 'react'
import markerIcon from "../resources/marker-tourism@3x.png";
import antennaIcon from "../resources/antenna.png";
import {Marker} from "./MarkerComponent";
import MapMouseEvent = google.maps.MapMouseEvent;
import LatLng = google.maps.LatLng;


type MapForAddStationProps = {
  setLatLng: (latLng: LatLng) => void;
  latLng?: LatLng;
}

export default function MapForAddStation(props: MapForAddStationProps) {

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [latLng, setLatLng] = useState<LatLng>(props.latLng ?? new LatLng(0,0));

  const onClick = (e: MapMouseEvent) => {
    if (e.latLng) {
      setLatLng(e.latLng);
      props.setLatLng(e.latLng);
    }
  }

  const center: google.maps.LatLngLiteral = {lat: 46.735013, lng: 8.214732};

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new google.maps.Map(ref.current, {
        center,
        zoom: 6.7,
        //disableDoubleClickZoom: true
      }));
    }

    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      map.addListener("click", onClick);
    }
  }, [ref, map]);

  useEffect(() => {
    if (props.latLng) {
      setLatLng(props.latLng)
    }
  }, [props.latLng])

  const iconMarker = {
    url: markerIcon, // url
    scaledSize: new google.maps.Size(40, 40), // scaled size
    origin: new google.maps.Point(0,0), // origin
    anchor: new google.maps.Point(20, 40) // anchor
  };

  const iconAntenna = {
    url: antennaIcon, // url
    scaledSize: new google.maps.Size(40, 40), // scaled size
    origin: new google.maps.Point(0,0), // origin
    anchor: new google.maps.Point(20, 40) // anchor
  };

  return (
    <div ref={ref} style={{height: '300px', width: '600px'}}>
      {(latLng) && <Marker key={Math.random()} position={latLng} map={map} icon={iconMarker}/>}
    </div>
  );
}