import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {doc, getFirestore, setDoc} from "@firebase/firestore";
import {useTranslation} from "react-i18next";


type EditMemberModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
  operatorUserId: string,
  email: string,
  role: string
}

export default function EditMemberModal(props:EditMemberModalProps) {

  const { t } = useTranslation();
  const [displayCheckError, setDisplayCheckError] = useState(false);
  const [checkError, setCheckError] = useState("");
  const [boxChecked, setBoxChecked] = useState(new Set([props.role]));

  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (boxChecked.size == 0) {
      setCheckError(t("must-select-role"))
      setDisplayCheckError(true)
      setTimeout(() => setDisplayCheckError(false), 5000)
    }

    if (!displayCheckError) {
      const id = props.operatorUserId;
      const role = boxChecked.keys().next().value;

      await setDoc(doc(db, "operatorUser", id),{role: role}, {merge: true})

      handleConfirm()
    }

  }

  const cleanUp = () => {
    setDisplayCheckError(false);
    setBoxChecked(new Set())
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    const temp = new Set<string>();
    if (e.target.checked) {
      temp.add(e.target.id)
    }
    setBoxChecked(new Set(temp));
  }

  useEffect(() => {
    setBoxChecked(new Set([props.role]));
  },[])

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={validate}>
        <Modal.Body>
          <Form.Group>
            <div>
              <Form.Label>{props.body}{" "}<b>{props.email}{":"}</b></Form.Label>
              <Form.Check
                style={{fontWeight: 'bold'}}
                type={"checkbox"}
                id={"admin"}
                label={t("admin")}
                onChange={(e) => handleChange(e)}
                checked={boxChecked.has('admin')}
              />
              <Form.Check
                style={{fontWeight: 'bold'}}
                type={"checkbox"}
                id={"operator"}
                label={t("operator")}
                onChange={(e) => handleChange(e)}
                checked={boxChecked.has('operator')}
              />
              <Form.Check
                style={{fontWeight: 'bold'}}
                type={"checkbox"}
                id={"read-only"}
                label={t("read-only")}
                onChange={(e) => handleChange(e)}
                checked={boxChecked.has('read-only')}
              />
            </div>
            <div style={{color: displayCheckError ? "red": "transparent", marginBottom:'10px'}}>
              {checkError}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {props.showCancel && <Button variant="dark" onClick={handleClose}>
            {t("cancel")}
          </Button>}
          <Button variant="danger" type={'submit'}>
            {t("ok")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )

}