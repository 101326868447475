import React, {useState} from 'react'
import {Button, Form, FormCheck, Modal} from "react-bootstrap";
import {addDoc, collection, GeoPoint, getFirestore} from "@firebase/firestore";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import MapForAddStation from "../components/MapForAddStation";
import LatLng = google.maps.LatLng;


type AddStationModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
  projectId: string,
  isProjectGlobal: boolean,
}

export default function AddStationModal(props:AddStationModalProps) {

  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [displayNameError, setDisplayNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [displayPositionError, setDisplayPositionError] = useState(false);
  const [positionError, setPositionError] = useState("");
  const [bikeAvailable, setBikeAvailable] = useState(false);
  const [numberOfBikes, setNumberOfBikes] = useState(0);
  const [ebikeAvailable, setEBikeAvailable] = useState(false);
  const [numberOfEBikes, setNumberOfEBikes] = useState(0);
  const [ebikeSAvailable, setEBikeSAvailable] = useState(false);
  const [numberOfEBikesS, setNumberOfEBikesS] = useState(0);
  const [ebikeMAvailable, setEBikeMAvailable] = useState(false);
  const [numberOfEBikesM, setNumberOfEBikesM] = useState(0);
  const [ebikeLAvailable, setEBikeLAvailable] = useState(false);
  const [numberOfEBikesL, setNumberOfEBikesL] = useState(0);
  const [cargoAvailable, setCargoAvailable] = useState(false);
  const [numberOfCargos, setNumberOfCargos] = useState(0);
  const [geofencing, setGeofencing] = useState(false);
  const [geofencingRadius, setGeofencingRadius] = useState(0);
  const [latLng, setLatLng] = useState<LatLng>();


  const validate = async (event : React.FormEvent<HTMLFormElement>) => {
    console.log("validate")
    event.preventDefault();
    const db = getFirestore();

    if (name === "") {
      setNameError(t("valid-name"))
      setDisplayNameError(true)
      setTimeout(() => setDisplayNameError(false), 5000)
    }
    if (!(latLng)) {
      setPositionError(t("need-to-set-position"))
      setDisplayPositionError(true)
      setTimeout(() => setDisplayPositionError(false), 5000)
    }
    if (name !== "" && latLng) {

      const timestamp = (new Date()).getTime();
      const clientId = sessionStorage.getItem("clientId");
      const projectId = props.projectId;
      const nVehicle = numberOfBikes + numberOfEBikes + numberOfCargos;
      const vehiclesTarget = new Map();
      const vehiclesCurrent = new Map();
      if (bikeAvailable) {
        vehiclesTarget.set("bike", numberOfBikes);
        vehiclesCurrent.set("bike", 0);
      }
      if (ebikeAvailable) {
        vehiclesTarget.set("e-bike", numberOfEBikes);
        vehiclesCurrent.set("e-bike", 0);
      }
      if (ebikeSAvailable) {
        vehiclesTarget.set("e-bike-S", numberOfEBikesS);
        vehiclesCurrent.set("e-bike-S", 0);
      }
      if (ebikeMAvailable) {
        vehiclesTarget.set("e-bike-M", numberOfEBikesM);
        vehiclesCurrent.set("e-bike-M", 0);
      }
      if (ebikeLAvailable) {
        vehiclesTarget.set("e-bike-L", numberOfEBikesL);
        vehiclesCurrent.set("e-bike-L", 0);
      }
      if (cargoAvailable) {
        vehiclesTarget.set("cargo", numberOfCargos);
        vehiclesCurrent.set("cargo", 0);
      }

      const station = {
        active: true,
        clientId: clientId,
        geofencing: geofencing,
        radius: geofencingRadius,
        isGlobal: props.isProjectGlobal,
        nVehiclesCurrent: 0,
        nVehiclesTarget: nVehicle,
        name: name,
        position: new GeoPoint(latLng.lat(), latLng.lng()),
        projectId: projectId,
        vehicles: [],
        vehiclesCurrent: Object.fromEntries(vehiclesCurrent.entries()),
        vehiclesTarget: Object.fromEntries(vehiclesTarget.entries())
      }

      const result = await addDoc(collection(db, "stations"), station);
      const path = "stations/" + result.id + "/edges";

      await addDoc(collection(db, path), {
        key: 1,
        position: new GeoPoint(latLng.lat(), latLng.lng())
      })

      handleConfirm()
    }
  }

  const cleanUp = () => {
    setDisplayNameError(false);
    setName("");
    setBikeAvailable(false);
    setEBikeAvailable(false);
    setEBikeSAvailable(false);
    setEBikeMAvailable(false);
    setEBikeLAvailable(false);
    setCargoAvailable(false);
    setNumberOfBikes(0);
    setNumberOfEBikes(0);
    setNumberOfEBikesS(0);
    setNumberOfEBikesM(0);
    setNumberOfEBikesL(0);
    setNumberOfCargos(0);
    setGeofencing(false);
    setGeofencingRadius(0);
    setLatLng(undefined);
  }

  const handleClose = () => {
    props.handleClose();
    cleanUp();
  }

  const handleConfirm = () => {
    props.handleConfirm();
    cleanUp();
  }

  return (
    <Modal show={props.show} onHide={handleClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={validate}>
        <Modal.Body>
          <Form.Group>
            <div>
              <Form.Label>{props.body}</Form.Label>
            </div>
            <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
              <Form.Label style={{fontWeight: 'bold'}}>{t("name")}{": "}</Form.Label>
              <Form.Control autoFocus type="name" onChange={(e) => setName(e.target.value)}
                            value={name} placeholder={t("name")} style={{width:'40%', marginLeft: '40px', marginRight: '20px'}} />
              <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px'}}>
                {nameError}
              </div>
            </div>
            <div style={{marginBottom: '10px', marginTop: '10px'}}>
              <Form.Label>{t("position-of-the-station")}</Form.Label>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
              <MapForAddStation setLatLng={setLatLng}/>
            </div>
            <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px', display:'flex',justifyContent:'center'}}>
              {positionError}
            </div>
            <div style={{marginBottom: '10px', marginTop: '20px'}}>
              <Form.Label>{t("activate-geofencing")}</Form.Label>
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('geofencing')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={geofencing} onChange={() => setGeofencing(!geofencing)}/>
                {(geofencing) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(geofencing) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('radius-in-meters')}</div>}
              {(geofencing) && <Form.Control datatype={"number"} type="number" onChange={(e) => setGeofencingRadius(parseInt(e.target.value))}
                                                value={geofencingRadius} placeholder={"100 m"}/>}
            </div>
            <div style={{marginBottom: '10px', marginTop: '10px'}}>
              <Form.Label>{t("number-of-vehicles-per-category")}</Form.Label>
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('bike')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={bikeAvailable} onChange={() => setBikeAvailable(!bikeAvailable)}/>
                {(bikeAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(bikeAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(bikeAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfBikes(parseInt(e.target.value))}
                            value={numberOfBikes} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('e-bike')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={ebikeAvailable} onChange={() => setEBikeAvailable(!ebikeAvailable)}/>
                {(ebikeAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(ebikeAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(ebikeAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfEBikes(parseInt(e.target.value))}
                                                value={numberOfEBikes} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('e-bike-S')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={ebikeSAvailable} onChange={() => setEBikeSAvailable(!ebikeSAvailable)}/>
                {(ebikeSAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(ebikeSAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(ebikeSAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfEBikesS(parseInt(e.target.value))}
                                                  value={numberOfEBikesS} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('e-bike-M')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={ebikeMAvailable} onChange={() => setEBikeMAvailable(!ebikeMAvailable)}/>
                {(ebikeMAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(ebikeMAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(ebikeMAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfEBikesM(parseInt(e.target.value))}
                                                 value={numberOfEBikesM} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('e-bike-L')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={ebikeLAvailable} onChange={() => setEBikeLAvailable(!ebikeLAvailable)}/>
                {(ebikeLAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(ebikeLAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(ebikeLAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfEBikesL(parseInt(e.target.value))}
                                                  value={numberOfEBikesL} placeholder={"#"}/>}
            </div>
            <div style={{display:'grid', gridTemplateColumns:'2fr 2fr 2fr 2fr 1fr'}}>
              <div style={{height: '40px', display: 'flex', alignItems: 'center', fontWeight:'bold'}}> {t('cargo-bike')} </div>
              <div className="switch-button-item">
                <FormCheck type={"switch"} checked={cargoAvailable} onChange={() => setCargoAvailable(!cargoAvailable)}/>
                {(cargoAvailable) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
              </div>
              {(cargoAvailable) && <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>{t('number-of-vehicle')}</div>}
              {(cargoAvailable) && <Form.Control datatype={"number"} type="number" onChange={(e) => setNumberOfCargos(parseInt(e.target.value))}
                                                value={numberOfCargos} placeholder={"#"}/>}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {props.showCancel && <Button variant="dark" onClick={handleClose}>
            {t("cancel")}
          </Button>}
          <Button variant="danger" type={'submit'}>
            {t("ok")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )

}