import React, {ChangeEvent, useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {addDoc, collection, getFirestore} from "@firebase/firestore";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";

type CreateProjectModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
}

export default function CreateProjectModal(props: CreateProjectModalProps) {

  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [displayNameError, setDisplayNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [displayCheckError, setDisplayCheckError] = useState(false);
  const [checkError, setCheckError] = useState("");
  const [boxChecked, setBoxChecked] = useState(new Set());
  const history = useHistory();

  const validate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (name == "") {
      setNameError(t("valid-name"));
      setDisplayNameError(true);
      setTimeout(() => setDisplayNameError(false), 5000)
    }

    if (boxChecked.size == 0) {
      setCheckError(t("must-select-project-type"))
      setDisplayCheckError(true)
      setTimeout(() => setDisplayCheckError(false), 5000)
    }

    if (name != "" && boxChecked.size != 0) {
      //Create Project:
      const timestamp = new Date().getTime();
      const projectType = boxChecked.keys().next().value;
      const clientId = sessionStorage.getItem("clientId");

      const result = await addDoc(collection(db, "projects"), {
        active: true,
        name: name,
        creationDate: timestamp,
        lastUpdate: timestamp,
        type: projectType,
        clientId: clientId
      });

      console.log(result.id);

      history.push("/projects/"+result.id+"/dashboard");
      window.location.reload();

      handleConfirm()
    }
  }

  const cleanUp = () => {
    setDisplayCheckError(false);
    setDisplayNameError(false);
    setName("");
    setBoxChecked(new Set());
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    const temp = new Set();
    if (e.target.checked) {
      temp.add(e.target.id)
    }
    setBoxChecked(new Set(temp));
  }


  return (
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={validate}>
          <div>
            <Form.Label>{props.body}</Form.Label>
            <Form.Control autoFocus type="name" onChange={(e) => setName(e.target.value)}
                          value={name} placeholder={t("project-placeholder")}/>
          </div>
          <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px'}}>
            {nameError}
          </div>
          <div>
            <Form.Label>{t("select-project-type")}</Form.Label>
            <Form.Check
                style={{fontWeight: 'bold'}}
                type={"checkbox"}
                id={"corporate"}
                label={t("corporate")}
                onChange={(e) => handleChange(e)}
                checked={boxChecked.has('corporate')}
            />
            <Form.Check
                style={{fontWeight: 'bold'}}
                type={"checkbox"}
                id={"tourism"}
                label={t("tourism")}
                onChange={(e) => handleChange(e)}
                checked={boxChecked.has('tourism')}
            />
            <Form.Check
                style={{fontWeight: 'bold'}}
                type={"checkbox"}
                id={"real-estate"}
                label={t("real-estate")}
                onChange={(e) => handleChange(e)}
                checked={boxChecked.has('real-estate')}
            />
          </div>
          <div style={{color: displayCheckError ? "red": "transparent", marginBottom:'10px'}}>
            {checkError}
          </div>
          <Modal.Footer>
            {props.showCancel && <Button variant="dark" onClick={props.handleClose}>
              {t("cancel")}
            </Button>}
            <Button variant="danger" type={'submit'}>
              {t("ok")}
            </Button>
          </Modal.Footer>
        </Form>
        </Modal.Body>
      </Modal>
  )

}