import {RouteComponentProps, useHistory} from "react-router-dom";
import React, {useState} from "react";
import {Navigation} from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog, faFile, faTools, faUser} from '@fortawesome/free-solid-svg-icons'
import '../App.css';
import {useTranslation} from "react-i18next";

type NavSidebarOperatorsProps = {
  name: string;
  userData: Record<string, unknown>

} & RouteComponentProps<Identifier>

export const NavSidebarOperators = (props:NavSidebarOperatorsProps) => {
  const [operatorId, setOperatorId] = useState(props.match.params.id)
  const [operatorName, setOperatorName] = useState(props.name)
  const history = useHistory();
  const { t } = useTranslation();

  const isStaff = (props.userData.role == 'staff');
  const isSuperAdmin = (props.userData.role == 'super-admin') || isStaff;
  const isAdmin = (props.userData.role == 'admin') || isSuperAdmin;

  const items = []
  if (isAdmin) {
    items.push({
      title: t('members'),
      itemId: '/operators/' + operatorId + '/users',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faUser}/>
      </div>
    })
  }
  if (isSuperAdmin) {
    items.push ({
      title: t('projects'),
      itemId: '/operators/' + operatorId + '/projects',
      // you can use your own custom Icon component as well
      // icon is optional
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faFile}/>
      </div>
    })
    items.push({
      title: t('settings'),
      itemId: '/operators/' + operatorId + '/settings',
      elemBefore: () => <div>
        <FontAwesomeIcon icon={faCog}/>
      </div>
    })
  }

  return (
    <div className="App-sidebar">
      <div className="navsidebar-title">
        <div className="navsidebar-title-icon">
          <FontAwesomeIcon icon={faTools} style={{marginRight:'15px'}}/>
          {operatorName}
        </div>
      </div>
      <div
        className={`fixed inset-y-0 left-0 z-30 w-10 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
          "ease-in -translate-x-full"
        }`}
      >
        <React.Fragment>
          <Navigation
            // you can use your own router's api to get pathname
            activeItemId={props.match.url}
            onSelect={({itemId}) => {
              if (operatorId) {
                history.push(itemId)
              }
            }}
            items={items}
          />
        </React.Fragment>
      </div>
    </div>
  )
}