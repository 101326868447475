import React, {useEffect, useState} from 'react';
import {NavSidebarProjects} from "../components/NavSidebarProjects";
import {RouteComponentProps} from "react-router-dom";
import LoadingElement from "../elements/LoadingElement";
import {collection, doc, DocumentData, getDocs, getFirestore, orderBy, query, setDoc, where} from "@firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button, FormCheck} from "react-bootstrap";
import BasicModal from "../elements/BasicModal";
import AddDiscountModal from "../elements/AddDiscountModal";

type DiscountsProps = {
  projectMap: Map<string,DocumentData>
  userData: Record<string, unknown>
} & RouteComponentProps<Identifier>

const Discounts = (props:DiscountsProps) => {

  const { t } = useTranslation();
  const initRows: DocumentData[] = [];
  const [loading, setLoading] = useState(false);
  const [showAddDiscount, setShowAddDiscount] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDiscountId, setSelectedDiscountId] = useState("");
  const [rows, setRows] = useState(initRows);

  const loadDiscounts = async () => {
    const db = getFirestore();
    const id = props.match.params.id;

    const queryDiscounts = await query(collection(db, "discounts"),
      where("projectId", "==", id),
      where("active", "==", true),
      orderBy("code", "asc"));

    const querySnapshot = await getDocs(queryDiscounts);
    const localRows : DocumentData[] = [];

    let i=0;
    for (const discount of querySnapshot.docs) {
      const discountRef = await doc(db,"discounts",discount.id);
      const queryDiscountUsers = await query(collection(discountRef, "users"));
      const numberOfUsersDocs = await getDocs(queryDiscountUsers);
      const numberOfUsers = numberOfUsersDocs.docs.length;

      i++;
      localRows.push({'id': discount.id, 'count': i, nUsers: numberOfUsers, ...discount.data()})
    }

    setRows(localRows);
  }

  const confirmDeleteDiscount = async () => {
    const db = getFirestore();
    const ref = doc(db, "discounts", selectedDiscountId);
    setShowDeleteModal(false);
    setLoading(true);

    await setDoc(ref, {active: false}, {merge:true});
    loadDiscounts()
      .finally(() => {
        setLoading(false)
      });
  }

  const switchValidity = async (discountId: string, isValid:boolean) => {
    const db = getFirestore();
    const ref = doc(db, "discounts", discountId);
    setLoading(true);

    await setDoc(ref, {valid: !isValid}, {merge:true});
    loadDiscounts()
      .finally(() => {
        setLoading(false)
      });
  }

  const confirmAddDiscount = () => {
    setShowAddDiscount(false);
    setLoading(true);
    loadDiscounts()
      .finally(() => {
        setLoading(false)
      });
  }

  const tableHead = <tr>
    <th>{t("#")}</th>
    <th>{t("code")}</th>
    <th>{t("discount")}</th>
    <th>{t("from")}</th>
    <th>{t("to")}</th>
    <th>{t("number-of-users")}</th>
    <th>{t("validity")}</th>
    <th>{t("delete")}</th>
  </tr>

  const tableBody = rows.map((value: DocumentData) => {
    const from = new Date(value.from).toLocaleDateString();
    const to = new Date(value.to).toLocaleDateString();
    const status = <div className="switch-button-item">
      <FormCheck type={"switch"} checked={value.valid} onChange={() => switchValidity(value.id, value.valid)}/>
      {(value.valid) ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> :
        <FontAwesomeIcon icon={faTimesCircle} color={"red"}/>}
    </div>
    const deleteButton = <Button variant={'secondary'} size={'sm'}
                                 onClick={() => {
                                   setShowDeleteModal(true);
                                   setSelectedDiscountId(value.id);
                                 }}>{t('delete')}</Button>
    let discountUnit;
    switch(value.discountType) {
      case "percent":
        discountUnit = "%";
        break;
      case "absolute":
        discountUnit = " CHF";
        break;
      case "hours":
        discountUnit = " " + t("hours")
        break;
      default:
        discountUnit = "%"
    }

    return (
      <tr key={value.id} style={{alignItems: 'center'}}>
        <td>{value.count}</td>
        <td><b>{value.code}</b></td>
        <td>{value.discount}{discountUnit}</td>
        <td>{from}</td>
        <td>{to}</td>
        <td>{value.nUsers}</td>
        <td>{status}</td>
        <td>{deleteButton}</td>
      </tr>
    )
  });

  useEffect(() => {
    const id = props.match.params.id;
    setLoading(true)
    loadDiscounts()
      .finally(() => {
        setLoading(false)
      });

  }, [])

  return (
      <div className="Dashboard">
        <LoadingElement loading={loading}/>
        <NavSidebarProjects name={props.projectMap.get(props.match.params.id)?.name}
                            projectType={props.projectMap.get(props.match.params.id)?.type} {...props}/>
        <div className="App-header">
          <AddDiscountModal show={showAddDiscount} projectId={props.match.params.id}
                            handleClose={() => setShowAddDiscount(false)}
                            handleConfirm={confirmAddDiscount}/>
          <BasicModal show={showDeleteModal} title={t('delete-discount-title')}
                      body={t('delete-discount-body')} handleClose={() => setShowDeleteModal(false)}
                      handleConfirm={confirmDeleteDiscount} showCancel={true}/>
          <div className="App-box">
            <div className="App-title">
              {t("discounts")}
            </div>
            <div className="App-button">
              <Button variant="danger" onClick={() => setShowAddDiscount(true)}>
                <FontAwesomeIcon icon={faPlusCircle} style={{marginRight: '10px'}}/>
                {t("new-discount")}
              </Button>
            </div>
            <div className="App-table">
              <div className="table-responsive" style={{height: '70vh'}}>
                <table className="table" id="project-table">
                  <thead>{tableHead}</thead>
                  <tbody>{tableBody}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Discounts;