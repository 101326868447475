import React, {useState} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import {addDoc, collection, getFirestore} from "@firebase/firestore";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

type CreateOperatorModalProps = {
  show: boolean,
  title: string,
  body: string,
  handleClose: () => void;
  handleConfirm: () => void;
  showCancel: boolean;
}

export default function CreateOperatorModal(props: CreateOperatorModalProps) {

  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [displayNameError, setDisplayNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const history = useHistory();

  const validate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const db = getFirestore();

    if (name == "") {
      setNameError(t("valid-name"));
      setDisplayNameError(true);
      setTimeout(() => setDisplayNameError(false), 5000)
    } else {
      //Create Project:
      const timestamp = new Date().getTime();
      const clientId = sessionStorage.getItem("clientId");

      const result = await addDoc(collection(db, "operators"), {
        active: true,
        name: name,
        creationDate: timestamp,
        lastUpdate: timestamp,
        clientId: clientId,
      });

      history.push("/operators/"+result.id+"/dashboard");
      window.location.reload();

      handleConfirm()
    }
  }

  const cleanUp = () => {
    setDisplayNameError(false);
    setName("")
  }

  const handleClose = () => {
    cleanUp()
    props.handleClose()
  }

  const handleConfirm = () => {
    cleanUp()
    props.handleConfirm()
  }


  return (
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={validate}>
            <div>
              <Form.Label>{props.body}</Form.Label>
              <Form.Control autoFocus type="name" onChange={(e) => setName(e.target.value)}
                            value={name} placeholder={t("operator-placeholder")}/>
            </div>
            <div style={{color: displayNameError ? "red": "transparent", marginBottom:'10px'}}>
              {nameError}
            </div>
            <Modal.Footer>
              {props.showCancel && <Button variant="dark" onClick={props.handleClose}>
                {t("cancel")}
              </Button>}
              <Button variant="danger" type={'submit'}>
                {t("ok")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
  )

}